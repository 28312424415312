import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { deleteActivity } from '../../data/training-log'

const ActivityList = (props) => {
  const { currentUser, db } = useAuth()

  const {
    loading,
    activities,
    editActivity,
    setOpenSnackbar,
    setSnackbarMsg,
    setEditing,
    refetchData,
    viewOnly,
  } = props

  const removeActivity = async (i) => {
    const activityKey = activities[i].id
    await deleteActivity(activityKey, currentUser.uid, db)
    refetchData()

    // Show notification
    setOpenSnackbar(true)
    setSnackbarMsg('Deleted activity')

    // stop editing
    setEditing(false)
  }

  return (
    <>
      {loading === true ? <CircularProgress /> : ''}

      {!activities || activities.length === 0 || activities === null ? (
        <p>No activities added yet.</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Duration (mins)</TableCell>
                <TableCell>With Trainer</TableCell>
                {!viewOnly && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(activities).map((activity, i) => {
                const { name, duration, trainer } = activity
                return (
                  <TableRow key={i}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{duration}</TableCell>
                    <TableCell>{trainer ? 'Yes' : 'No'}</TableCell>
                    {!viewOnly && (
                      <TableCell>
                        <>
                          <DeleteIcon onClick={(e) => removeActivity(i)} />
                          <EditIcon
                            onClick={(e) => editActivity(activity, i)}
                            style={{ marginLeft: '20px' }}
                          />
                        </>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default ActivityList
