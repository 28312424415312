/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useContext, useState } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { styled } from '@mui/material/styles'
import {
  Stack,
  Typography,
  Button,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import PersonIcon from '@mui/icons-material/Person'
import FavoriteIcon from '@mui/icons-material/Favorite'
import AssignmentIcon from '@mui/icons-material/Assignment'
import PetsIcon from '@mui/icons-material/Pets'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import DownloadIcon from '@mui/icons-material/Download'
import LoadingButton from '@mui/lab/LoadingButton'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import {
  personalDetailsRequired,
  dogDetailsRequired,
  veterinaryInfoRequired,
  sponsorRequired,
  medicalRequired,
} from '../utils/requiredFields'
import { sendSubmittedEmail } from '../data/email'
import { updateUser } from '../data/user'
import { ApplicationContext } from '../contexts/application/ApplicationContext'
import { deleteApplication, updateApplication } from '../data/application'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: '#E58A3B',
  }),
}))

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props

  const icons = {
    1: <PersonIcon />,
    2: <FavoriteIcon />,
    3: <AssignmentIcon />,
    4: <PetsIcon />,
    5: <PersonAddAlt1Icon />,
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

const ApplicationProgress = () => {
  const navigate = useNavigate()
  const { currentUser, db, functions } = useAuth()
  const { application, fetchApplication } = useContext(ApplicationContext)
  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    fetchApplication(currentUser.uid, db)
  }, [])

  const handleDeleteApplication = async () => {
    if (
      confirm(
        'Are you sure you wish to delete your application? This cannot be undone.',
      )
    ) {
      await deleteApplication(currentUser.uid, db)
      navigate('/application')
    }
  }

  const downloadFile = (type) => {
    setDownloading(true)
    const storage = getStorage()
    let fileRef = ref(
      storage,
      'gs://pads-foundation.appspot.com/forms/PADS vet form.pdf',
    )

    if (type === 'medical') {
      fileRef = ref(
        storage,
        'gs://pads-foundation.appspot.com/forms/PADS psychiatrist form.pdf',
      )
    }

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download =
            type === 'vet'
              ? 'PADs veterinary form.pdf'
              : 'PADs psychiatrist form.pdf'
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  const handleSubmitApplication = async () => {
    setLoading(true)
    if (
      confirm(
        'Once you submit your application, you will no longer be able to edit it. Are you sure you want to continue?',
      )
    ) {
      const emailSent = await sendSubmittedEmail(functions, currentUser)
      if (emailSent) {
        setLoading(false)
        await updateUser({ submittedApplication: true }, currentUser.uid, db)
        await updateApplication({ status: 'Submitted' }, currentUser.uid, db)
        setAlert({
          open: true,
          message:
            'Request sent! One of our volunteers will be in touch shortly!',
          type: 'success',
        })
        fetchApplication(currentUser.uid, db)
      }
      if (!emailSent) {
        setLoading(false)
        setAlert({
          open: true,
          message: 'Error sending request. Please try again later',
          type: 'error',
        })
      }
    } else {
      setLoading(false)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const allStepsCompleted = () => {
    const p =
      application &&
      personalDetailsRequired.every(
        (x) => application[x.field] && application[x.field] !== '',
      )
    const m =
      application &&
      medicalRequired.every(
        (x) => application[x.field] && application[x.field] !== '',
      )
    const d =
      application &&
      dogDetailsRequired.every(
        (x) => application[x.field] && application[x.field] !== '',
      )
    const v =
      application &&
      veterinaryInfoRequired.every(
        (x) => application[x.field] && application[x.field] !== '',
      )
    const s =
      application &&
      sponsorRequired.every(
        (x) => application[x.field] && application[x.field] !== '',
      )

    return p && m && d && v && s
  }

  const getApplicationStatus = () => {
    if (currentUser.role === 'member' || currentUser.role === 'admin') {
      return 'Complete'
    }

    if (allStepsCompleted() && !currentUser.submittedApplication) {
      return 'Awaiting submission'
    }

    if (allStepsCompleted() && currentUser.submittedApplication) {
      return 'Awaiting approval'
    }

    if (!allStepsCompleted() && currentUser.role !== 'member') {
      return 'Missing information'
    }
  }

  const status = (step) => {
    switch (step) {
      case 1:
        return application &&
          personalDetailsRequired.every(
            (x) => application[x.field] && application[x.field] !== '',
          )
          ? 'Complete'
          : 'Missing information'
      case 2:
        return application &&
          medicalRequired.every(
            (x) => application[x.field] && application[x.field] !== '',
          )
          ? 'Complete'
          : 'Missing information'
      case 3:
        return application &&
          dogDetailsRequired.every(
            (x) => application[x.field] && application[x.field] !== '',
          )
          ? 'Complete'
          : 'Missing information'
      case 4:
        return (application &&
          veterinaryInfoRequired.every(
            (x) => application[x.field] && application[x.field] !== '',
          )) ||
          currentUser.role === 'member'
          ? 'Complete'
          : 'Missing information'
      case 5:
        return application &&
          sponsorRequired.every(
            (x) => application[x.field] && application[x.field] !== '',
          )
          ? 'Complete'
          : 'Missing information'
    }
  }

  return (
    <>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <LoadingButton
        loading={downloading}
        variant="outlined"
        onClick={() => downloadFile('medical')}
        sx={{ textTransform: 'none', marginBottom: 2, marginRight: 3 }}
        startIcon={<DownloadIcon />}
      >
        Download Medical Form
      </LoadingButton>
      <LoadingButton
        loading={downloading}
        variant="outlined"
        onClick={() => downloadFile('vet')}
        sx={{ textTransform: 'none', marginBottom: 2 }}
        startIcon={<DownloadIcon />}
      >
        Download Veterinary Form
      </LoadingButton>
      <Typography mb={currentUser.role !== 'member' && currentUser.role !== 'admin' ? 5 : 0} mt={2} variant="body1">
        Application status: <strong> {getApplicationStatus()}</strong>
      </Typography>

      {currentUser.role !== 'member' && currentUser.role !== 'admin' && (
        <Stack sx={{ width: '100%' }} spacing={4} mb={8}>
          <Stepper alternativeLabel connector={<ColorlibConnector />}>
            <Tooltip arrow title={status(1)} placement="top">
              <Step completed={status(1) === 'Complete'}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  Personal Details
                </StepLabel>
              </Step>
            </Tooltip>
            <Tooltip arrow title={status(2)} placement="top">
              <Step completed={status(2) === 'Complete'}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  Medical Information
                </StepLabel>
              </Step>
            </Tooltip>
            <Tooltip arrow title={status(3)} placement="top">
              <Step completed={status(3) === 'Complete'}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  Dog Details
                </StepLabel>
              </Step>
            </Tooltip>
            <Tooltip arrow title={status(4)} placement="top">
              <Step completed={status(4) === 'Complete'}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  Veterinary Information
                </StepLabel>
              </Step>
            </Tooltip>
            <Tooltip arrow title={status(5)} placement="top">
              <Step completed={status(5) === 'Complete'}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  Your Sponsor
                </StepLabel>
              </Step>
            </Tooltip>
          </Stepper>
        </Stack>
      )}
      {getApplicationStatus() === 'Missing information' && (
        <>
          <Typography variant="body1">
            Your application is currently{' '}
            <span style={{ fontWeight: 'bold', color: 'red' }}>
              missing some information.
            </span>
          </Typography>
          <Typography mb={3} variant="body1">
            Please edit your application and update your details.
          </Typography>
          <Typography mb={3} mt={1} variant="body1">
            Once your application is filled out please submit your application
            for processing.
          </Typography>
        </>
      )}

      {getApplicationStatus() === 'Awaiting submission' && (
        <>
          <Typography variant="body1">
            Your application is ready for submission!
          </Typography>
          <Typography mb={3} variant="body1">
            Please click the button below to complete you application
          </Typography>
        </>
      )}

      {getApplicationStatus() === 'Awaiting approval' && (
        <Typography variant="body1">
          Your application has been submitted. We will be in touch as soon as
          your application is complete.
        </Typography>
      )}

      {getApplicationStatus() !== 'Complete' &&
        getApplicationStatus() !== 'Awaiting approval' && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => navigate('/application/create')}
            sx={{ marginTop: 5, marginBottom: 8, marginRight: 2 }}
          >
            Edit application
          </Button>
        )}

      {(getApplicationStatus() === 'Complete' ||
        getApplicationStatus() === 'Awaiting approval') && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => navigate('/application/create')}
            sx={{ marginTop: 5, marginBottom: 8, marginRight: 2 }}
          >
            View application
          </Button>
        )}

      {getApplicationStatus() !== 'Complete' &&
        getApplicationStatus() !== 'Awaiting approval' && (
          <LoadingButton
            loading={loading}
            type="button"
            variant="contained"
            color="success"
            disabled={!allStepsCompleted()}
            onClick={handleSubmitApplication}
            sx={{ marginTop: 5, marginBottom: 8, marginRight: 2 }}
          >
            Submit application
          </LoadingButton>
        )}

      {currentUser.role !== 'member' && currentUser.role !== 'admin' && (
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={handleDeleteApplication}
          sx={{ marginTop: 5, marginBottom: 8, marginRight: 2, float: 'right' }}
        >
          Delete application
        </Button>
      )}
    </>
  )
}

export default ApplicationProgress
