export default (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA':
      return {
        ...state,
        isLoading: true,
      }
    case 'APPLICATION_DATA_SUCCESS':
      return {
        ...state,
        application: action.payload,
        isLoading: false,
        errorMessage: undefined,
      }
    case 'APPLICATION_DATA_ERROR':
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
