/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page, Link } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const CanineCareGuide = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="canine-care" style={styles.title}>
      3. Canine Care
    </Text>
    <Text style={styles.underlineHeading} id="care-routines">
      Understanding Canine Care Routines
    </Text>
    <Text style={styles.text}>
      Providing a good routine will help your dog feel more secure and
      confident. Knowing what is coming next will help a dog relax as they learn
      their feeding times, toilet breaks, play and walk schedule. It also helps
      to build the human animal bond as the dog begins to associate you/their
      handler with lots of positive experiences. We control so many aspects of
      their lives it is important to ensure we provide routine.
    </Text>
    <View style={bulletPointStyles.row}>
      <div>
        <ListItem>Regular feeding times</ListItem>
        <ListItem>Regular toilet breaks</ListItem>
        <ListItem>Socialisation skills outside of the home</ListItem>
        <ListItem>Enrichment</ListItem>
        <ListItem>Training</ListItem>
        <ListItem>Exercise</ListItem>
        <ListItem>
          Vet visits – these will be included in their training plan
        </ListItem>
      </div>
      <Image
        style={{ ...styles.image, height: '150px' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(2).jpg?alt=media"
      />
    </View>
    <Text style={styles.text}>
      It is important for your dog to feel relaxed and for you to establish a
      positive relationship based on trust. Providing a routine will provide
      stability and consistency to their day. The routine does not have to be a
      specific time of day as they will adjust to the schedule you provide and
      there will eventually be more flexibility in that routine once your dog
      has managed to develop proper house training. Consistency in your
      dog&apos;s care routine will help your dog to settle and help you notice
      any changes in their behaviour (changes in behaviour can be an early
      indication of an illness).
    </Text>
    <Text style={styles.subtitleBold} id="daily-routines">
      Daily Routines
    </Text>
    <Text style={styles.text}>
      It is important to establish a routine that is easy to keep and fits in
      with your schedule. You will need to plan a schedule that is easy to
      maintain and doesn&apos;t constantly change as this will affect the dogs
      behaviour and their ability to learn toilet training/house rules
    </Text>
    <Text style={styles.text}>
      First thing to consider is what time to get up to feed your dog, allowing
      time for your dog&apos;s elimination (urination/defecation). While your
      dog is still learning to be toilet trained you may need to get up very
      early or in the night to ensure your pup gets the chance to pee outside
      (puppies don&apos;t all have the ability to hold until morning). We will
      discuss puppy training further in Puppy Development. Your dog will rely on
      your consistency and time keeping in order to eliminate outside. If you
      get up at 6am in the week it will be important to maintain this time over
      the weekends during your dog&apos;s development. Staying in bed until 10am
      will run the risk of your dog having an accident in the home. Although
      accidents will happen on occasion when the dog is very young, efforts to
      prevent this will help your dog establish proper house training. In
      addition we need to set our dog/puppy up to succeed which assists in
      building confidence.
    </Text>
    <Text style={styles.text}>
      Dogs will need a good quality diet that gives them enough energy for the
      day ahead. A supply of clean fresh water should always be available.
      Caregivers should always monitor how much the dog is drinking as this can
      be the first sign of illness. Time should always be given so that they can
      digest their food before doing exercise otherwise it can lead to
      complications such as gastric dilatation volvulus or gastric torsion as it
      is more commonly known. Many dogs need the opportunity to be taken out on
      a walk at least once a day. The level and amount of exercise will depend
      on the age and the health of the dog. Walks could vary from a run on the
      beach, a walk in the park or a stroll around the block. Dogs need time
      when out on their walk to stop and sniff where other dogs have been
      allowing them to learn about the other dogs in the neighbourhood. They
      need to socialise with other dogs in order to prevent any issues such as
      fearfulness and or aggression (If you have a puppy please refer to Puppy
      Development chapter).
    </Text>
    <Image
      style={{ ...styles.image, width: '300px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(3).jpg?alt=media"
    />
    <Text style={styles.text}>
      Daily walks are beneficial for their health and mental well being. As with
      humans, walking will help to keep your dog&apos;s weight down, prevent
      (including but not limited to) diseases associated with obesity such as
      heart conditions, high blood pressure and diabetes. Some overweight dogs
      are more prone to certain types of cancer. It will also relieve the
      pressure on the skeleton, so the risk of arthritis and ligament damage is
      reduced.{' '}
    </Text>
    <Text style={styles.text}>
      We understand that not all days go to plan and on some occasions, getting
      out to walk your dog may be a bit more challenging. It is important to
      ensure that you spend time at home providing mental stimulation for your
      dog as an alternative. This is also beneficial after a walk as it can help
      to reduce the adrenaline and cortisol levels that may still be high after
      the excitement of being outside. This will help to relax and calm a dog so
      that they can settle without any anxiety. It prevents boredom which can
      lead to destructiveness and it keeps their mind active, this is especially
      important as the dog ages. This can vary from clicker training, finding
      hidden toys or treats in the garden or giving them their dinner in puzzle
      toys such as Kongs and snuffle mats.
    </Text>
    <Text style={styles.text}>
      It is important for a dog to be able to relax in their own space
      especially if the house is a busy one with lots of people coming and
      going. This could be a bed in the living room or an open door crate in a
      quiet area of the home. They should be able to come and go as they please
      but should not be disturbed when they are there, especially when sleeping.
      Providing choice to our dogs is essential.
    </Text>
    <Image
      style={{ ...styles.image, width: '350px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(4).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="weekly-routines">
      Weekly Routines
    </Text>
    <Text style={styles.text}>
      If a care routine is started early on with your puppy it will make
      physical examinations by your vet a lot easier. Please ensure that you use
      positive reinforcement to reward your dog for allowing you to perform
      these checks (please see the training charts and speak to your trainer -
      both of which will guide you). Some dogs will not be used to or
      comfortable being examined by you or veterinarian. These will need to be
      trained and will be part of your Husbandry training checklist to work
      through with your trainer and veterinarian.{' '}
    </Text>
    <Image
      style={{ ...styles.image, width: '300px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(5).jpg?alt=media"
    />
    <Text style={styles.text}>
      The following checks are made, starting at the head
    </Text>
    <ListItem>
      <Text style={styles.textBold}>The ears:</Text> are they clean and without
      a discharge? Do they appear a healthy pink or normal colour for the dog
      concerned? Can you see any signs of soreness? Do they smell bad?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>The eyes:</Text> are they bright and clear,
      without discharge or apparent soreness?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Mouth & Teeth:</Text> is the breath fresh &
      without an unpleasant odour? Are the teeth clean?{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Nose:</Text> is the nose clear of mucus,
      other matter or is there a sign of a runny discharge? Does the dog appear
      to be able to breathe through the nose?{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Fur:</Text> is the coat clean and without
      clumps of matted fur? Any signs of parasites? E.g. fleas, mites, lice? Any
      bald patches?{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Skin:</Text> where visible, is the skin dry
      but not flaky and are there any cuts, abrasions or other doubtful signs of
      a good condition?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Body form:</Text> is this dog standing,
      sitting or lying down correctly? When passing your hands over the body can
      you feel any lumps or swellings?
    </ListItem>
    <Text style={styles.text}>&nbsp;</Text>
    <ListItem>
      <Text style={styles.textBold}>Feet:</Text> Are the nails the correct
      length and not overly long? Is the end point of their growth well away
      from the pads? Any signs of soreness on the pads?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Weight:</Text> for the individual dog, has
      he or she a healthy weight for their age and breed?{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Alertness:</Text> is the dog alert and keen
      to respond to you in their own way? Eating and drinking: is the dog eating
      well and is there clean fresh water easily available for them? Have you
      observed the dog taking a drink?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Bodily functions:</Text> is the dog passing
      urine or faeces (defecating) readily with no apparent problems?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Urethra:</Text> the urethra is the opening
      from the body that leads inside to the urinary bladder. Is this clean and
      without apparent soreness, discharge or unpleasant odour?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Vulva:</Text> the vulva is the birth opening
      in female dogs. Is the vulva clean, without any discharge or unpleasant
      odour?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Penis & Testes:</Text> these are male
      genitalia of dogs. The penis doubles as the exit point for urine as well
      as being the male&apos;s sexual organ. Is the penis (if visible that is)
      clean, without odour, discharge or soreness? Have you seen the dog licking
      that part of the body more than you would expect?
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Anus:</Text> the anus is from where faeces
      are expelled from the body. Does the anus appear clean, without faecal
      material and without soreness?{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Movement:</Text> is he or she moving about
      and apparently enjoying exercise?{' '}
    </ListItem>
    <Image
      style={{
        ...styles.image,
        width: '400px',
        margin: '0 auto',
        marginTop: 10,
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(6).jpg?alt=media"
    />
    <Text style={styles.text}>
      Grooming provides the caregiver an opportunity to get to know the
      dog&apos;s body so that any new or unusual lumps appearing on or under the
      skin will be detected early on. The condition of the coat and the skin can
      be checked for any dry or red patches as this is an indication of a
      parasite infection.
    </Text>
    <Text style={styles.text}>
      Dogs should be groomed at least once a week depending on their coat and
      the environment they are walked in. A long-haired dog that will walk in a
      muddy field will need grooming every day for example. The type of brush
      used will depend on the condition and length of their coat and their age.
    </Text>
    <Text style={styles.text}>
      The benefits from a weekly groom are numerous. It will remove dead fur and
      skin which will make the coat look healthy. The hair follicles will be
      stimulated producing natural oils making the coat soft, shiny and healthy.
    </Text>
    <Text style={styles.subtitleBold} id="monthly-routines">
      Monthly Routines
    </Text>
    <Text style={styles.text}>
      Checking the paws once a month will show up any issues with the claws and
      will help to prepare the dog if any do need clipping. It will also give an
      opportunity to check the pads for any red patches and signs of soreness.
    </Text>
    <Text style={styles.text}>
      Walking regularly on pavements and roads will naturally wear down the
      claws and this will prevent them from cracking or splitting, which can
      cause considerable pain when walking if left. If they are left to grow
      very long this can make the dog walk unevenly on their paws and cause
      issues with the posture and will lead to joint problems, especially later
      on in life.
    </Text>
    <Image
      style={{ ...styles.image, width: '400px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(7).jpg?alt=media"
    />
    <Text style={styles.text}>
      If the nails do need trimming it is best to do this slowly over a period
      of time to prevent cutting into the quick as this contains blood vessels
      and nerves. If the dog has light coloured nails it is easy to see but in
      darker nails it is not visible. The quick will grow down to the end of the
      nail so it is best to file down the ends of the claw so that the quick
      recedes without bleeding. This should be done until the claws are an
      acceptable length. There are various types of files, grinders and clippers
      on the market to do this. The veterinary surgery or grooming salon will
      also be able to perform the clipping and give advice on doing this in the
      future. Remember these behaviours need to be trained, too many dog owners
      will accidentally cut their dogs claws to the quick causing pain and
      bleeding. This will make it that much harder for your dog to trust you to
      go near their feet and claws again in the future. Dog&apos;s can often
      become aggressive if they have too many painful negative experiences
      having their claws clipped.{' '}
    </Text>
    <Image
      style={{
        ...styles.image,
        width: '450px',
        margin: '0 auto',
        marginTop: 10,
        marginBottom: 10,
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(8).jpg?alt=media"
    />
    <Text style={styles.text}>
      It can be difficult to notice changes in weight loss or gain when living
      with your dog day to day, even if you&apos;re performing regular checks.
      Keeping a log will make it easier to notice any fluctuations in their
      weight, your veterinarian will keep a record of your dog&apos;s weight and
      be able to help assess if your dog has become over or underweight.
    </Text>
    <Text style={styles.text}>
      Dog&apos;s will put on weight as they become older due to their metabolism
      slowing down, meaning that they do not need as much food as they used to.
      Their activity levels will decrease so they need less calories. Weight
      gain will put more of a strain on their muscles and joints making
      conditions such as arthritis more painful.
    </Text>
    <Text style={styles.text}>
      Health conditions such as hypothyroidism or Cushing&apos;s disease can
      make a dog gain weight and anorexia and some heart, liver and kidney
      diseases can make them lose weight very quickly so keeping an eye on them
      can give early indications of any issues that will need checking.
    </Text>
    <Text style={styles.subtitleBold} id="vet-checks">
      Annual Vet Checks
    </Text>
    <Text style={styles.text}>
      Taking your dog for annual health checks will help your vet pick up any
      potential problems. When you registered as an In Training PADs Member it
      was required that your dog have an initial health check. Once your dog is
      fully trained and passes all behaviour checks PADs Foundation will require
      your dog to have annual vet checks to qualify for Fully Trained PADs
      Membership renewal.
    </Text>
    <View style={{ ...bulletPointStyles.row, verticalAlign: 'top' }}>
      <Text style={{ ...styles.text, width: '300px' }}>
        During this, they will check their ears and eyes for signs of infection
        and the teeth for any build-up of tartar or cracks. The lungs will be
        listened to as well as the heart to check for any murmurs or
        palpitations. The coat will be looked at as well as the general
        condition and demeanour of the animal. Further checks may be warranted
        should your vet have any cause for concern of your dog’s welfare.
      </Text>
      <Image
        style={{ ...styles.image, width: '200px', height: '150px' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(9).jpg?alt=media"
      />
    </View>
    <Text style={styles.text}>
      It is important for your dog to be comfortable being checked over by
      veterinary staff and these behaviours will need to be worked on during
      your dog’s training.
    </Text>
    <Text style={styles.text}>
      The vet will also be able to give vaccinations against:
    </Text>
    <ListItem>Parvovirus</ListItem>
    <ListItem>Hepatitis</ListItem>
    <ListItem>Leptospirosis </ListItem>
    <ListItem>Distemper</ListItem>
    <ListItem>Kennel cough</ListItem>
    <Text style={styles.subtitleBold} id="fear-free-handling">
      Fear free handling
    </Text>
    <Text style={styles.text}>
      Doring et al. (2009) found that 78.5% of clinically healthy dogs visiting
      a veterinary hospital in Germany were fearful on the examination table.
      Less than half the dogs entered the practice calmly. 13.3% were dragged or
      carried in (Lloyd 2017).
    </Text>
    <Text style={styles.text}>
      These statistics outline the wide-spread problem of stress and fear in
      these clinical environments. The study found this actually had an effect
      on caregivers delaying bringing their sick and injured animals in, because
      of the stress, not only to their animal, but to themselves.
    </Text>
    <Text style={styles.text}>
      Some of this stress can be minimised via simple management techniques and
      fear free handling, as well as understanding canine body language and our
      own.
    </Text>
    <Text style={styles.text}>
      Fear-free handling has a number of goals including:
    </Text>
    <ListItem>Reduce fear and pain for the dog</ListItem>
    <ListItem>
      Improve on the professional – client – animal relationship
    </ListItem>
    <ListItem>More reliable veterinary and grooming care for the dog</ListItem>
    <ListItem>
      Improve efficacy, productivity for the professionals (less time spent on
      restraining dogs etc)
    </ListItem>
    <ListItem>Fewer injuries sustained to clients and professionals</ListItem>
    <ListItem>
      Improve diagnosis accuracy (stress can affect results of samples collected
      and pulse/respiration rates etc)
    </ListItem>
    <Text style={styles.text}>
      The concept of consent is very important in human society and it is now
      starting to become recognised as being just as important in terms of life
      with our animals. Animal behaviourist, Chirag Patel, is a leading figure
      in the concept of an animal giving consent to husbandry experiences and
      participating willingly in a fear free manner. Not just with dogs, but
      also with other pets, exotics, zoo and laboratory animals. Consent and
      cooperation are not just for pet professionals and dogs. With more and
      more dogs developing handling issues as our expectations of dogs inflate,
      caregivers are being encouraged by behaviourists to give their dog’s
      choices with regards to whether they want to be petted or not.
    </Text>
    <Text style={styles.text}>
      Part of the training you do with your dog will be around Cooperative Care
      / Husbandry. You will have seen these training sheets and Training Key in
      your downloadable documents for your trainer. We recommend that you and
      your trainer implement Chirag Patel’s Bucket Game (see the link below)
    </Text>
    <Link
      style={styles.text}
      href="https://www.youtube.com/watch?v=GJSs9eqi2r8"
    >
      https://www.youtube.com/watch?v=GJSs9eqi2r8
    </Link>
    <Text style={styles.subtitleBold}>Three second consent game</Text>
    <ListItem>Pet the dog</ListItem>
    <ListItem>Count 3 seconds in your head</ListItem>
    <ListItem>Pull your hands away and wait</ListItem>
    <ListItem>
      Does the dog nuzzle you for more? Lean into you? She is consenting for
      more
    </ListItem>
    <ListItem>
      Does the dog walk away? Turn his head? Sniff the floor? She is not
      consenting for more
    </ListItem>
    <Text style={styles.text}>
      A dog who is consenting is feeling comfortable and seeking engagement. A
      dog who isn’t consenting is seeking disengagement, in a way that doesn’t
      involve resorting to behaviours we term as “aggressive” such as growling,
      snarling or biting. Using force and restraint in the vets or groomers, or
      even in the home is likely to cause behaviour problems.
    </Text>
    <Text style={styles.text}>
      It is a mistake to believe or accept that dogs being afraid in veterinary
      or grooming procedures is normal! Going to the vet or groomer and feeling
      OK about it is a life skill that needs to be developed and maintained from
      puppyhood.
    </Text>
    <Text style={styles.text}>
      Another mistake is to think that a puppy will “grow out of it” in these
      scenarios. Research has been undertaken that provides evidence that
      puppies who did not exhibit exploratory behaviour in the clinics, did not
      engage with the professionals and did not enjoy being handled or touched
      were still this way at 18 months of age.
    </Text>
    <Text style={styles.text}>
      There are a lot of instinctive behaviours within the canis familiaris
      species that a dog naturally exhibits – digging, barking, sniffing,
      scavenging etc. It is not a natural behaviour for a dog to be vet checked
      or groomed by a human, while standing on a table off the ground.
      Prevention is always better than cure and putting everything in place to
      enable a puppy to feel safe and build positive associations is key and we
      should set our dogs up for success.
    </Text>
    <Image
      style={{ ...styles.image }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(14).jpg?alt=media"
    />
    <Text style={styles.text}>
      Using the table above you can pair each of these behaviours with positive
      reinforcement. Sessions should be short and positive 5-10 minutes in
      duration. Please see your Vet Check & Husbandry Behaviour Progression
      sheets and work through these with your trainer.{' '}
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Choice</Text> – choice is a big part of
      force free handling. A dog who is given the option to choose to get onto a
      table will be less stressed than a dog who is picked up. With correct
      training the vets or groomers tables need not be a place of fear for your
      dog.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Touch</Text> – touch can be appetitive for
      some dogs as it provides social contact. It can affect the parasympathetic
      nervous system, lowering blood pressure and heart/breathing rate. Touch
      can mediate oxytocin, that in turn mediates pleasure, and can reduce
      irritability and moderate or prevent cortisol responses to acute stressors
      such as novel surroundings. For dogs that seek touch, slow steady pressure
      can have a calming effect. One hand on the dog as a constant light
      pressure as you move around the body can also help.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Harnesses</Text> – the use of harnesses
      instead of collars can be encouraged. Collars can do physiological damage
      as well as cause unnecessary stress from the pain and tension in that
      area. While in training we recommend the use of a harness to help your dog
      understand when it is needing to be in “work mode”. A harness will be
      removed once in the home or if out for an enrichment walk.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Gradient touch</Text> – taking time with a
      procedure will reduce stress for the dog. Fast and sudden movements will
      make any procedure more overwhelming. With gradient touch, we slowly
      introduce the dog to each procedure before going ahead with the whole
      procedure. For example, a dog needing a vaccination injection the vet
      should rub down the neck on one side while we give a small food treat, rub
      down the other side without taking her hand off and then lift up the skin
      on the neck at the injection site (but not actually inserting the needle).
      This can be repeated several times until the dog is confident and relaxed.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Co-operation</Text> – after every check or
      procedure, giving the dog a small morsel of food is a great way to build
      and maintain positive associations. - assuming the dog managed without
      showing signs of fear and aggression.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Dog-led examinations</Text> – instead of
      following a strict routine with grooming or vet exams, allow the dog to
      lead. This will minimise stress. Instead of starting with the head for
      example, perhaps start with the body parts the dog offers. This is easy
      enough to do at home while doing your weekly/monthly physical checks.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Breaks</Text> – if you notice a dog showing
      stress signals, take a short break and encourage sniffing behaviours,
      perhaps using a snuffle mat or help them to do a treat search.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Use the floor</Text> – some dogs may have a
      previous history of associating being on a table with pain or fear. Ask
      the vet or groomer to carry out their work with the dog on the floor.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Use the car</Text> – some dogs may have such
      a long history of associating the vets with pain and fear they may
      struggle to even walk in the door. Ask the vet to examine the dog in the
      caregiver’s car. Ensure that you work with your trainer to help make vet
      visits less stressful for your dog.
    </Text>
    <Text style={styles.text}>
      When <Text style={styles.textBold}>NOT</Text> to use food - Do not use
      food to entice a nervous dog towards you. Luring a dog towards something
      they are not comfortable with is counterproductive.
    </Text>
    <Text style={styles.text}>
      It will likely cement their anxieties as they will struggle to choose
      between food or safety. If the dog does manage to grab the food, he may
      panic and run away or even snap when the food has gone, and he is close to
      the perceived “scary” person or object.
    </Text>
    <Text style={styles.text}>
      Do <Text style={styles.textBold}>NOT</Text> use food to distract a dog
      from nipping or biting. Distracting a dog from an uncomfortable procedure
      is fine though as long as they are calm e.g. offering some liver paste or
      squeezy cheese while the dog’s temperature is taken often works well.
    </Text>
    <Image
      style={{ ...styles.image, width: '425px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(10).jpg?alt=media"
    />
    <Text id="care-routines" style={styles.underlineHeading}>
      The Impact of Care Routines on Behaviour & Training
    </Text>
    <Text style={styles.text}>
      Caring for a dog and building up of the routines to take care of their
      emotional, physical and psychological needs are beneficial for both the
      human and animal as it promotes the wellbeing and health of both.
    </Text>
    <Text style={styles.text}>
      Several studies have shown that dog owners have lower blood pressure than
      non-owners — probably because their pets have a calming effect on them and
      because dog owners tend to get more exercise. The power of touch also
      appears to be an important part of this &quot;pet effect.&quot; Several
      studies show that blood pressure goes down when a person pets a dog.
      Owners are generally healthier due to exercise, more social as they will
      talk to people on the walk and less likely to suffer from feelings of
      loneliness and depression.
    </Text>
    <Text style={styles.text}>
      A dog that has a strong bond with their humans is calmer and relaxed. They
      can read their owners body language and are able to react and give comfort
      or become excited if needed. A dog that has this bond is more likely to
      repeat behaviour that is pleasing and acceptable making them easier to
      train and be with. This is the{' '}
      <Text style={styles.textBold}>Human Animal Bond.</Text>
    </Text>
    <Text style={styles.text}>
      This bond begins early on by gaining the dog’s trust. They will need to
      feel safe and secure, knowing that they will have food, shelter, rest,
      play time/socialisation and walks. Although they will spend a lot of their
      time with their handler they will also need to learn to be left alone for
      periods of time. A dog that has not been trained to be separated from
      their handler can suffer from separation anxiety. This training starts
      early in the dog’s life.
    </Text>
    <Text style={styles.text}>
      The handler will begin to feel needed, especially at meal and walk times.
      They will be greeted with unconditional love when they come back to the
      home after being away from the dog and will have someone to be with in
      times of loneliness.
    </Text>
    <Image
      style={{ ...styles.image, width: '425px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(11).jpg?alt=media"
    />
    <Text style={styles.text}>
      This mutual feeling of being loved and needed is fuelled by the release of
      the hormone oxytocin. This is produced in the hypothalamus part of the
      brain and is released by the pituitary gland. It is also known as the
      ‘love or cuddle hormone’ and is produced with mothers and new-born babies.
      Studies have recently shown that when a dog and human show typical bonding
      behaviour such as touching, eye contact, licking and sniffing, elevated
      amounts of this hormone are recorded in the blood stream of both. This
      increases their bond which increases the production of oxytocin and the
      cycle continues.
    </Text>
    <Text style={styles.text}>
      Dopamine is also released during these times of petting. This is a
      neurotransmitter and is responsible for feelings of happiness and
      pleasure. It helps the animal form long term memories and makes it feel
      good so it will continue to bond with the owner, releasing more dopamine
      into the system.
    </Text>
    <Text style={styles.text}>
      This chemical also helps to focus attention and helps with behaviour. It
      is also responsible for the regulation of movement and learning. Dogs love
      to engage with/train with their owners, especially if they get rewarded
      with food, affection and/or praise. This action releases dopamine to the
      ‘reward’ centre of their brain boosting their feelings of happiness and
      satisfaction. They remember this feeling and therefore are more likely to
      repeat the behaviour to boost this feeling again.
    </Text>
    <Text style={styles.text}>
      A dog that has high levels of oxytocin and dopamine is more likely to
      focus on the task and stay motivated, making them easier to train. Once
      the task is learnt they are more likely to remember to perform this again
      as it makes them feel good.
    </Text>
    <Link
      style={styles.link}
      src="https://www.health.harvard.edu/staying-healthy/having-a-dog-can-help-your-heart--literally"
    >
      https://www.health.harvard.edu/staying-healthy/having-a-dog-can-help-your-heart--literally
    </Link>
    <Text id="handling-methods" style={styles.underlineHeading}>
      Learn Appropriate Canine Handling Methods
    </Text>
    <Text style={styles.text}>
      It is important for you to understand a dog’s body language, not just for
      your own dog, but for other dogs you will encounter when outside. Learning
      to read a dog’s body language will help to keep you and your dog safe from
      harm and unnecessary stress.
    </Text>
    <Text style={styles.text}>Please ensure you are familiar with:</Text>
    <ListItem>Calm & Relaxed body language</ListItem>
    <ListItem>Physical Signs of Stress</ListItem>
    <ListItem>Calming Signals</ListItem>
    <ListItem>Body Posture</ListItem>
    <ListItem>Cut Off Signals</ListItem>
    <ListItem>Displacement Behaviours</ListItem>
    <Text style={styles.subtitleBold} id="equipment">
      Equipment
    </Text>
    <Text style={styles.text}>
      Getting the right equipment that is comfortable for both handler and dog
      is important. The type of equipment used should not cause any harm or
      distress to the dog.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Collars</Text> - These should be flat
      collars that fit around the neck securely. They should not be too tight
      nor too loose. You should be able to run a finger all the way around the
      neck to ensure it is on correctly. It is a legal requirement that dogs
      wear an ID tag with the owner&apos;s surname, postcode and ideally phone
      number in case they get lost. All dogs will be microchipped by law so make
      sure that the microchip is registered and details are up to date. Do not
      rely on your vet doing this for you!
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Leads</Text> - Leads should be comfortable
      for the handler to use. By law the dog should always be on lead and under
      control when on a public highway. For exercising, the best lead is a 5 to
      10-metre-long training line rather than an extending lead. Extendable
      leads can cause damage to the dog’s spine if they are allowed to run to
      the end of the lead and it jerks their head back. A training line will
      also be used while your dog is learning recall. For those suffering from
      dissociations a lead that attaches to a waistband is better suited for
      road safety mitigating behaviours.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Harness</Text> - A harness takes the
      pressure off the neck and gives the handler better control. It is more
      comfortable for the dog as most harnesses have padding to protect the
      pressure points of the body. It is important that it is fitted correctly
      as if it is too loose and they could slip out of it, too tight and it will
      restrict their movement and breathing. PADs recommend that once your dog
      is trained and comfortable wearing a harness that a harness is to be used
      while in training sessions and while “working”.
    </Text>
    <Image
      style={{ ...styles.image, width: '350px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(12).jpg?alt=media"
    />
    <Text style={styles.textBold}>Avoid Aversive Training Tools</Text>
    <Text style={styles.text}>Never use:</Text>
    <ListItem>Half chokes</ListItem>
    <ListItem>Full chokes</ListItem>
    <ListItem>Martingale collar</ListItem>
    <ListItem>Citronella collar or Spray collar</ListItem>
    <ListItem>Prong collar</ListItem>
    <ListItem>
      Shock collar (use of these is illegal in parts of the UK for over a
      decade)!
    </ListItem>
    <Text style={styles.text}>
      The first 3 listed are collars designed to constrict and restrict the
      windpipe. The dog will struggle to take a breath and will start to pant.
      The amount of oxygen will be reduced, this will affect the brain function
      and dogs will start not to listen to their handlers. They can also inflict
      damage to the vertebrae of the spine and the neck, especially in younger
      dogs.
    </Text>
    <Text style={styles.text}>
      Shock & Citronella collars have been used by some in an attempt to prevent
      their dog from barking. A remote-control device allows the handler to
      deliver an electronic pulse to a dog’s neck via 2 metal probes inside the
      collar. This will range from 400 - 6000 volts. Some on the market spray
      citronella into your dog’s face when it detects your dog barking. These
      types of collars were banned in Scotland and Wales and in 2018 an act of
      parliament was passed to ban them in the UK but has been delayed due to a
      legal challenge. These collars can cause harm and suffering to your dog.
      They elicit fear that could end up as redirected aggression to other
      animals or even people. Your dog may even become reluctant to be walked.
      Remember barking is part of a dog’s natural behaviour. If your dog is
      barking often it is important to understand what stimulus is triggering
      this behaviour in order to begin to train the dog to remain calm and
      quieter in the future. Please speak to your qualified trainer to resolve
      any undesirable behaviours. PADs Foundation only supports force free
      training methods.
    </Text>
    <Text style={styles.text}>
      Prong collars are designed with spikes or prongs inside the collar so that
      when the dog pulls the collar tightens and the prongs inflict pain, in an
      attempt to make the dog less likely to pull again. There are often
      petitions to ban the use of Prong collars in the UK, in fact, several
      countries have banned the use of this type of collar. PADs Foundation only
      supports force free training tools and methods.
    </Text>
    <Text style={styles.italics}>
      “Prong collars are painful and unethical training collars. Owners use this
      on their dogs in an attempt to stop them pulling on the lead. They can
      cause serious harm to dogs and are an ineffective training method. Prong
      collars can be perceived to be a quick fix to control dogs. They’re often
      marketed by sellers as safe and gentle. Sadly, this isn’t the case.”{' '}
      <Text style={{ fontStyle: 'normal' }}>RSPCA</Text>
    </Text>
    <Text id="good-canine-care" style={styles.underlineHeading}>
      Understand What Contributes to Good Canine Welfare
    </Text>
    <Text style={styles.text}>
      Animal welfare has come a long way from the 1964 publication ‘Animal
      Machines’ by Ruth Harrison. This book described the intensive poultry and
      livestock methods used by farmers at that time, including calves living in
      crates, caged hens and pigs as well as speaking up for the welfare of the
      animals. Her book became the catalyst for the development of Animal
      Welfare legislation.
    </Text>
    <Text style={styles.text}>
      The Five Freedoms were initially proposed by Professor Roger Brambell.
      These freedoms were adopted by organisations such as the RSPCA, ASPCA and
      the World Organisation for Animal Health who started to adhere to them.
      This concept looks at the emotional state of the animal, allowing for
      positive experiences to encourage them to experience satisfaction,
      satiation and anticipation.
    </Text>
    <Text style={styles.text}>
      In 1994 the Five Freedoms became the Five Domains:
    </Text>
    <ListItem>Nutrition</ListItem>
    <ListItem>Environment</ListItem>
    <ListItem>Health</ListItem>
    <ListItem>Behaviour</ListItem>
    <ListItem>Mental State</ListItem>
    <Text style={styles.text}>
      The UK government introduced the Animal Welfare Act 2006. The purpose of
      the Act was to promote animal welfare by including the five domains to
      ensure that any person caring for an animal, either domestic or working,
      takes all reasonable steps to ensure that the needs of the animal are met.
      The act also allows for prevention by making it a criminal offence to
      cause unnecessary suffering and harm. This means that organisations like
      the RSPCA have more proactive powers to enter a property where abuse and
      suffering are likely to happen or is already happening. The act states
      that it is a criminal offence to, or have the knowledge that the animal is
      being given poisons or harmful substances such as drugs. There is a copy
      of the Animal Welfare Act 2006 in your resources folder.
    </Text>
    <Text style={styles.subtitleBold} id="nutrition">
      Nutrition
    </Text>
    <Text style={styles.text}>
      In order to stay healthy, dogs must eat a well-balanced diet. As all dogs
      are individuals this must be tailored to their individual needs including
      exercise levels, age and health. A dog’s requirement for food will change
      throughout their lifetime. Puppies will need food with added calcium to
      help their bones grow strong, working dogs will require a diet high in
      calories to give them energy and older dogs may need food with added
      supplements such as fish oil to help with their joints.
    </Text>
    <Text style={styles.text}>
      They should be fed the correct amount according to their needs to keep
      their weight consistent. An overweight dog can develop diseases such as
      diabetes and heart defects. It will put a strain on the skeleton so
      ligament injuries can occur, the dog can develop hip or elbow dysplasia or
      arthritis.
    </Text>
    <Text style={styles.textBold}>Specialised diet types</Text>
    <Text style={styles.text}>
      Dogs need to be fed a certain diet depending on their age, health and the
      job that they are doing. Every dog is an individual.
    </Text>
    <Image
      style={{ ...styles.image, width: '350px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(13).jpg?alt=media"
    />
    <Text style={styles.subtitleBold}>Puppy diet</Text>
    <Text style={styles.text}>
      Puppies are started to be weaned off their mothers milk at around three
      weeks of age and completely by six weeks. During this time they are
      introduced to wet puppy food and are fed up to six times a day. This is
      reduced as the pup ages and at six months they should go from three to two
      times a day.
    </Text>
    <Text style={styles.text}>
      As the puppy grows and develops the skeleton, muscles and the immune
      system they will need twice as much protein and fat as they will do when
      they become adults. They will need the correct amount of vitamins and
      minerals so that they can grow strong and function properly, so it is
      important to feed them a food that is labelled complete or balanced. Over
      feeding a puppy can lead to them developing too fast and that can lead to
      bone disease and becoming overweight. Underfeeding them can lead to
      mineral deficiency which can cause liver disease, osteoporosis, skin
      infections and a weak immune system, so it is important that they are fed
      the right amount for their age, breed,activity level and development
      stage.
    </Text>
    <Text style={styles.subtitleBold}>Adult diet</Text>
    <Text style={styles.text}>
      Once the dog has finished growing and becomes an adult they will need to
      switch to a diet to maintain their health and weight. Adult food or a
      ‘maintenance diet’ is lower in calories, protein and fat. These should be
      fed according to their age, health, activity level and breed requirements.
      Feeding guidelines should be shown on the packaging but these are only
      guidelines, and the dog should be observed to ensure it does not become
      either over or underweight. If this happens the amount can be adjusted by
      up to 10% either way. Their coat and general health should be monitored to
      ensure they are getting the correct nutrients required. It is best not to
      change foods constantly as this can cause a digestive imbalance resulting
      in diarrhoea.
    </Text>
    <Text style={styles.subtitleBold}>Senior diet</Text>
    <Text style={styles.text}>
      The rate of metabolism in dogs declines as they age, meaning that if they
      are fed the same amount in their senior years, they will gain weight. This
      can cause many health issues such as hip and elbow dysplasia, heart
      disease and some types of cancer. Their internal organs begin to slow down
      and do not function as efficiently as they did when they were younger, so
      it becomes more difficult to extract nutrients from their diet and can
      cause a build-up of waste that their kidneys and liver cannot get rid of,
      causing organ failure.
    </Text>
    <Text style={styles.text}>
      Some dogs suffer from arthritis in their senior years requiring some
      supplements to help make the joints less painful. Their requirement for
      vitamins and minerals will change as they will need more Vitamin A, C and
      E and the mineral Zinc to maintain their eyesight, heart function and
      immune system. Added antioxidants will also aid mental function and
      improve memory.
    </Text>
    <Text style={styles.text}>
      A senior diet will provide less calories, have added omega three oils and
      be more easily digestible to ensure they have everything they need to
      function in their senior years.
    </Text>
    <Text style={styles.subtitleBold}>Working diet</Text>
    <Text style={styles.text}>
      Dogs that work for a living such as herding, guarding or police dogs
      require, on average, twice the number of calories than a normal domestic
      dog, even more if they are working in extreme cold weather. They should be
      fed a diet that is high in protein and fat. A good source of protein
      should be first on the ingredients list. Fat is the nutrient that is most
      quickly and easily digested and turned into glucose to give energy to the
      muscles. Performance diets will provide the correct balance for what they
      need but should only be fed to working dogs. The amount that they are fed
      will depend on the level of activity. They should be fed a small amount
      before working and a larger amount about an hour after to prevent gastric
      torsion or diarrhoea.
    </Text>
    <Text style={styles.subtitleBold}>Assistance dog diet</Text>
    <Text style={styles.text}>
      Assistance dogs are working dogs, however they do not need as many
      calories as a working dog that is, for example, out in the field herding
      sheep all day. These dogs need to be alert, but also to remain calm at all
      times. They need to be very healthy in order to work every day assisting
      their handlers. Feeding them a balanced diet that is high in antioxidants
      will help improve and maintain their immune system. The food may benefit
      from having some calming ingredients added to it such as salmon oil, as
      this is high in omega-3 fatty acids which help to reduce the production of
      adrenaline. Added ingredients such as chamomile and brewers yeast can also
      help to keep the dog calm.
    </Text>
    <Text style={styles.textError}>
      *Please ensure you speak to your veterinarian for advice and guidance
      relating to your dog’s dietary requirements.
    </Text>
    <Text style={styles.subtitleBold}>Intolerances to food types</Text>
    <Text style={styles.text}>
      Dogs can be born with an intolerance to a certain food type, or they may
      develop it at some stage of their life. The digestive system may reject a
      certain food by vomiting or the dog may have diarrhoea. If the dog is
      allergic to a food they may develop a skin infection. The skin becomes
      inflamed, red and itchy. If left untreated it can become black, hard and
      raised. This is very uncomfortable and painful for the dog. They will lick
      or nibble at the skin to calm the itchiness, however they only re-infect
      it, making it worse.
    </Text>
    <Text style={styles.text}>
      An elimination diet is the best way to find out what they are allergic to.
      This is done by taking out certain elements of their diet to see if they
      improve or not, starting with treats and then working on the food types
      included within the food. Common allergies are poultry, beef, dairy or
      wheat. There are many hypoallergenic diets on the market which will cater
      for these intolerances. These are made up of natural ingredients and do
      not include additives such as colourants, preservatives or artificial
      flavourings.
    </Text>
    <Text style={styles.text}>
      All manufacturers must state if the food in the pack is complete or
      complementary. The packaging must show what type or age of dog it is
      targeted at; puppy, adult or senior for example. There must be a guideline
      of the amount to feed a dog of a certain weight and age. The best before
      date should also be shown, this is generally down to the shelf life of the
      fat- soluble vitamins. The weight of the package and the name of the
      company that manufactured it must also be shown. There should also be a
      bar code shown in case any product is recalled. The composition and the
      analytical constituents must be printed on the label.
      <Link src="https://www.food.gov.uk/business-guidance/pet-food">
        https://www.food.gov.uk/business-guidance/pet-food
      </Link>
    </Text>
    <Text style={styles.subtitleBold}>Raw Diet</Text>
    <Text style={styles.text}>
      Due to the growing evidence against raw meat-based diets, it everyone’s
      responsibility to protect our clients and the dogs that work with us, from
      zoonotic disease transmission including Salmonella, Listeria, Escherichia
      coli, Campylobacter, Enterobacteriaceae, Clostridia and Botulism. Exposure
      can cause serious infection or death, and even have legal implications
      (Davies, 2015). Pregnant women, children under the age of 5, older adults
      and individuals who are immunosuppressed are at the highest risk of
      contracting zoonotic disease from pathogenic bacteria found in raw diets.
      For these reasons a raw food diet is not permitted for Assistance Dogs as
      they have greater access to members of the public and areas where pet dogs
      are not permitted.
    </Text>
    <Text style={styles.text}>
      The following is a list of foods that are considered raw:
    </Text>
    <ListItem>
      Raw protein from any wild/domesticated animal that has not undergone a
      cooking process (eg dry or wet heat applied until protein reaches 165°F)
    </ListItem>
    <ListItem>
      Commercial or homemade raw meat diets, whether frozen, freeze-dried,
      dehydrated, or kibble with raw coating
    </ListItem>
    <ListItem>Freeze-dried treats</ListItem>
    <ListItem>Raw eggs </ListItem>
    <ListItem>
      Animal parts treats (e.g., bully sticks, pig ears, tendons, hooves,
      tracheas, lungs, bones, etc.) Note: Bacteria can survive some heat/thaw
      cycles as well as high pressure, so actual cooking is required. If you are
      unsure whether the food is raw or not, it is best to stay away. Cooking,
      irradiation or pasteurisation are reliable methods for killing bacteria
      (Davies, 2015).
    </ListItem>
    <Text style={styles.text}>
      There is an increasing pool of evidence indicating raw diets being a risk
      for infectious diseases. In a recent study by Van Bree, et al. (2018),
      they discovered the presence of zoonotic bacteria and parasites such as E.
      Coli, Salmonella and Listeria while studying 35 samples of raw meat-based
      diets. Schlesinger & Joffe (2011) conducted a critical review that cited
      42 articles and provided further evidence that raw food diets pose an
      infectious disease risk to humans and animals. The studies in the critical
      review found that Salmonella was detected in raw food samples (Strohmeyer,
      et al., 2006), dogs shed Salmonellae after just one raw food diet meal
      (Finley, et al., 2007), multi-drug resistant strains of Salmonella were
      present (Finley, et al., 2008), and there was evidence that Salmonella
      shed by animals caused illness in humans (Wright, et al., 2005). Thus,
      human contact with animal saliva, faeces, etc. poses a great health risk
      when the animal consumes a raw diet.
    </Text>
    <Text style={styles.text}>
      To protect the public against zoonotic disease transmission, AAII suggests
      that members feed commercially prepared or home-cooked food that is clean,
      fresh, and provides all essential nutrients and practise proper hygiene
      procedures after handling food, treats and animal dishes (American
      Veterinary Medical Association, 2012). Animal-source protein must be
      cooked to at least 165°F to eliminate pathogens before being fed to
      animals.
    </Text>
    <Text style={styles.text}>
      Currently, other organisations such as the U.S. Food and Drug
      Administration (FDA), U.S. Centers for Disease Control and Prevention
      (CDC), Pet Partners, American Veterinary Medical Association (AVMA),
      American College of Veterinary Nutritionists (ACVN) and American Animal
      Hospital Association (AAHA) have policies or declarations regarding raw
      diets for animals. Please ensure the diet you are feeding your dog is not
      considered raw.{' '}
    </Text>
    <Text style={styles.subtitleBold}>Foods Toxic to Dogs</Text>
    <Text style={styles.text}>
      Many dogs are often given the leftovers from their owner’s meals. There
      are lots of human safe foods that are toxic to dogs. Please do your own
      extensive research and consult your vet for a comprehensive list. If your
      dog has consumed items from the lists below please contact your
      veterinarian immediately for guidance.
    </Text>
    <ListItem>Alcohol</ListItem>
    <ListItem>Apricots</ListItem>
    <ListItem>Avocados</ListItem>
    <ListItem>Bread Dough</ListItem>
    <ListItem>Caffeine </ListItem>
    <ListItem>Cherries</ListItem>
    <ListItem>
      Chocolate (the higher the Cacao content the more dangerous for your dog!)
    </ListItem>
    <ListItem>Currants/Raisins</ListItem>
    <ListItem>Grapes</ListItem>
    <ListItem>Garlic</ListItem>
    <ListItem>Hops</ListItem>
    <ListItem>Macadamia nuts</ListItem>
    <ListItem>Moldy Food (mycotoxins)</ListItem>
    <ListItem>Mushrooms</ListItem>
    <ListItem>Onions</ListItem>
    <ListItem>Peach pits</ListItem>
    <ListItem>Rhubarb</ListItem>
    <ListItem>Salt</ListItem>
    <ListItem>Star Fruit</ListItem>
    <ListItem>Walnuts</ListItem>
    <ListItem>
      Xylitol (Foods containing this artificial sweetener can be deadly for
      dogs, always read the ingredients!)
    </ListItem>
    <Text style={styles.subtitleBold} id="environment">
      Environment
    </Text>
    <Text style={styles.text}>
      Dogs are naturally inquisitive so the environment that they explore must
      be kept safe and free from items such as:
    </Text>
    <ListItem>Rat poison</ListItem>
    <ListItem>Fuel</ListItem>
    <ListItem>Antifreeze</ListItem>
    <ListItem>
      Garden and Household chemicals including Washing Tablets
    </ListItem>
    <ListItem>Medications</ListItem>
    <ListItem>
      Some plants, such as daffodils and lilies, bulbs, are poisonous, so if you
      have a garden you will need to give some thought as to where these are
      located.
    </ListItem>
    <Text style={styles.subtitleBold}>Comfort</Text>
    <ListItem>
      Your dog will need a comfortable bed suitable for their age and condition.
      Older dogs ideally should have a bed that is raised off the floor as it is
      easier for them to step on and off. The bed should be in a quiet, draft
      free area that they feel confident to sleep in undisturbed.
    </ListItem>
    <ListItem>They should have access to fresh air and light.</ListItem>
    <ListItem>
      The area that they are to live in should be big enough for them to move
      around freely and to stretch their legs.
    </ListItem>
    <ListItem>
      They should be given daily mental stimulation and enrichment such as toys
      or food puzzles.
    </ListItem>
    <ListItem>
      They will need access to go to the toilet somewhere away from their bed as
      they are naturally clean animals and will not soil where they lie. If you
      do not have a dog flap with access to a garden this, for many, will mean
      regular toilet breaks through the day. Young puppies and older dogs will
      need to be given the opportunity to go more frequently.
    </ListItem>
    <ListItem>
      They need to be safe and protected in the house so no windows should be
      left open where they can climb out and steep stairs should be shut off
      with the aid of a dog gate. Care should be taken when cooking with hot
      pans and cutlery should be kept away in a drawer.
    </ListItem>
    <ListItem>
      If the dog becomes frightened by something, for example fireworks or a
      thunderstorm, they should have somewhere to go where they will feel safe,
      this could be a covered crate or quiet area. It will be important to work
      on noise desensitisation with your trainer as part of your dog’s
      development.
    </ListItem>
    <Text style={styles.text}>
      The environment extends to the car as we all know it doesn’t take long for
      a car to heat up on a hot day. Do <Text style={styles.textBold}>NOT</Text>
      leave dogs unattended in your vehicle during the summer months as they can
      overheat and die. Dogs need to be safe when travelling and should either
      be in a crate, behind a dog guard or clipped into the seat belt via a
      harness. Clean water should be provided, and the journey should be planned
      so that they can stop and eliminate every couple of hours. The car should
      be big enough for the dog to stand up and turn around so they can stretch
      their limbs.
    </Text>
    <Text style={styles.text}>
      Dogs that are loose in the car can cause an accident and the insurance
      will be invalid, the driver can receive a fine of £2,500 and up to nine
      points on their licence!
    </Text>
    <Text style={styles.subtitleBold}>Health</Text>
    <Text style={styles.text}>
      By following the daily, weekly, monthly and annual care routines you will
      help to maintain your dog’s good physical health.
    </Text>
    <Text style={styles.subtitleBold}>Behaviour</Text>
    <Text style={styles.text}>
      Your dog will also need the opportunity to behave naturally and interact
      with other dogs. This can be done with social walks or off lead time in
      the park playing, running around and enjoying themselves. Time must be
      given to them so that they can sniff and investigate other dogs and smells
      when they are not working. This time will help them to understand the
      difference between work and play so that they become more focused when
      needed.
    </Text>
    <Text style={styles.subtitleBold}>Mental State</Text>
    <Text style={styles.text}>
      By ensuring their nutrition, environment, health and behaviour needs are
      met you will be providing your dog with opportunity to engage in
      voluntary, self-generated and goal-directed behaviours increasing
      opportunities for positive affective experiences.
    </Text>
    <Text style={styles.subtitleBold} id="enrichment">
      Canine Enrichment
    </Text>
    <Text style={styles.text}>
      Enrichment: Provision of activities that allow for an animal to
      demonstrate species-typical behaviours including physical, cognitive,
      sensory or emotional stimulation designed to increase an animal’s overall
      health, wellbeing and quality of life.
    </Text>
    <Text style={styles.text}>Bender and Strong (2019) state:</Text>
    <Text style={styles.text}>
      “In the world of dogs, there is now more awareness than ever of the need
      to provide enrichment…”
    </Text>
    <Text style={styles.text}>
      The Oxford dictionary (2022) defines enrichment as “the act of improving
      the quality of something, often by adding something to it”.
    </Text>
    <Text style={styles.text}>
      So, what does Canine enrichment mean and what does it do? Canine
      enrichment means engaging your dog so that he or she voluntarily
      interacts/ takes part in activities that stimulate their mind, improve
      their mental state, keep them physically fit, builds their confidence and
      increases your bond. A dog in good psychological and physiological health
      is often easier to care for and will have better welfare (Weiss et al.,
      2015). We provide this through a wide variety of activities that are
      designed to challenge and exercise their brains using their innate
      behaviours including (but not limited to) foraging, sniffing, scavenging
      and playing..{' '}
    </Text>
    <Text style={styles.text}>
      Enrichment can also assist in preventing mouthing in young dogs and
      puppies. Mouthing is a normal part of development, but they do need to
      learn to bite and mouth appropriate things; enrichment can help teach them
      this. It is also handy when you need to keep your dog or puppy busy, like
      when you are watching a movie or eating your own dinner!
    </Text>
    <Text style={styles.text}>
      Please remember enrichment only remains beneficial when used for limited
      periods of time, otherwise the dog can become habituated to it and it
      becomes unfulfilling and doesn’t serve its purpose (in other words the
      stimulus becomes neutral).
    </Text>
    <Text style={styles.subtitleBold}>Types of Enrichment</Text>
    <Text style={styles.text}>
      There are several types of enrichment (Grigg and Donaldson, 2017; Dogs
      Trust 2022). *Please see table below.
    </Text>
    <Image
      style={{ ...styles.image, margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(50).jpg?alt=media"
    />
    <Text style={styles.text}>
      The above activities can be interspersed or combined. For example,
      enrichment feeding can be provided for through certain toys that would
      incorporate feeding, play, sensory (sniffing) and cognitive. An obstacle
      course on the ground with their food scattered on it would combine
      sensory, cognitive, physical, feeding and even play if you placed toys
      within the course.
    </Text>
    <Text style={styles.subtitleBold}>Enrichment Walks</Text>
    <Text style={styles.text}>
      Although you will be working on loose lead walking (for when your dog is
      in work mode / with work harness) it is important that your dog also gets
      their own enrichment walks that allow them freedom of choice. We don’t
      want them to be pulling our arms off during this walk but we do want them
      to have time to stop and smell their environment. Please watch the video
      below that discusses how to give an enrichment walk and other useful tips
      from Chirag Patel:
    </Text>
    <Link href="https://www.youtube.com/watch?v=9zM8GzJy_kY">
      https://www.youtube.com/watch?v=9zM8GzJy_kY
    </Link>
    <Text style={styles.subtitleBold}>Choosing Enrichment Activities</Text>
    <Text style={styles.text}>
      When planning and choosing the enrichment activities, it is vital they
      are:
    </Text>
    <ListItem>
      Safe (non- hazardous and non-toxic) and appropriate for the age and stage
      of your dog.
    </ListItem>
    <ListItem>
      Appropriate to the ability of your dog (consider age/stage, size, health
      and dietary requirements)
    </ListItem>
    <ListItem>Catered to your dog’s likes and dislikes</ListItem>
    <ListItem>
      Observe their interaction with the enrichment and ask yourself: Are they
      engaging? Are they enjoying themselves? Are they confused
      /frustrated/stressed? Is it too difficult? If so - how can I make it
      easier? How can I change this or do it better next time?
    </ListItem>
    <Text style={styles.text}>Some suggestions for enrichment equipment:</Text>
    <ListItem>
      Rolled up rugs taped with masking tape or similar will provide obstacles
      (age appropriate)
    </ListItem>
    <ListItem>Soft toys</ListItem>
    <ListItem>Raised surfaces (age appropriate)</ListItem>
    <ListItem>Items to step over, go under and over</ListItem>
    <ListItem>Different textures</ListItem>
    <ListItem>
      Hanging items that puppies can choose to interact with – some that make
      noises
    </ListItem>
    <ListItem>Different smells</ListItem>
    <ListItem>Astro turf</ListItem>
    <ListItem>Pools</ListItem>
    <ListItem>Soft beds with raised sides</ListItem>
    <ListItem>Plastic pipes</ListItem>
    <ListItem>Rubbish bin with bottom cut out</ListItem>
    <ListItem>Plastic sleds</ListItem>
    <ListItem>Garden gnomes and other novel items</ListItem>
    <ListItem>Mirrors</ListItem>
    <Text style={styles.text}>
      Use a diverse range of items, move them around/ add novel items and safe
      items should be made accessible 24/7. With puppies, as soon as they wake
      up, they are ready to learn! Novelty is key to development.
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(43).jpg?alt=media"
    />
    <Text style={styles.text}>
      Dogs and puppies must be supervised whilst participating in enrichment.
      Any toys that are old and/or damaged must be replaced as they will become
      a hazard. Please advise your trainer if any aggressive behaviour manifests
      during enrichment, as they will be able to advise you of the best course
      of action. You should be able to remove any items from your dog without
      any issues (eg. the “drop it” command).
    </Text>
    <Text style={styles.text}>
      Please see our bibliography section for Enrichment which will provide
      further information and ideas for you to incorporate into your enrichment
      plan. In addition to that, you can search for books and articles on amazon
      and online. Please ensure these are written by reward based trainers and
      organisations ONLY. Ensure any toys you use are specifically designed for
      dogs and are size appropriate!
    </Text>
    <Text style={styles.text}>
      <Link src="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5575572/figure/animals-07-00060-f001/">
        https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5575572/figure/animals-07-00060-f001/
      </Link>
    </Text>
    <Text style={styles.subtitleBold} id="dog-handler">
      The Dog/Handler Partnership
    </Text>
    <Text style={styles.text}>
      The relationship between an assistance dog and their handler is more
      complex than a domestic pet and their owner. It is based on trust and
      interdependence. This is necessary in order that the dogs can assist their
      handlers in their daily lives and feel that they are being cared for and
      looked after.
    </Text>
    <Text style={styles.text}>
      Time must be given to form a close relationship so that the two can work
      as a team. This will give them both a better understanding of each other
      to ensure that they can read each other’s body language so that they can
      react and respond quicker and more efficiently.
    </Text>
    <Text style={styles.text}>
      This is achieved by spending time together, learning to communicate,
      training via positive reinforcement methods, relaxing and playing. The
      more time they can spend together doing this will result in the dog
      knowing the person&apos;s needs more easily and quickly. They will relax
      and become confident with each other. This will result in the handler
      being able to become more independent and enjoy their life.
    </Text>
    <Text style={styles.text}>
      It is important that the dog has some ‘down time’ with their handler so
      that they can relax. During this time, they can continue to form
      attachments by doing activities such as grooming, mental stimulation and
      relaxing. This will help to strengthen the bond of trust that the handler
      is consistent and will provide the dog with all the basic and complex
      needs.
    </Text>
    <Text style={styles.text}>
      Ensuring that the animal has a high level of welfare and care will keep
      them motivated to work for the handler to the best of their ability,
      improving the quality of life for both parties. A dog that does not have
      that connection and bond, or is not looked after properly, will lose the
      motivation to work making things more difficult. A human-animal bond needs
      to be mutually beneficial, positively influencing the health and wellbeing
      of both members.
    </Text>
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

CanineCareGuide.propTypes = {
  styles: PropTypes.object,
}

export default CanineCareGuide
