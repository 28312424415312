import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'

import { useAuth } from '../contexts/AuthContext'
import logo from '../images/pads-logo-250.png'
import { FormHelperText } from '@mui/material'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { login } = useAuth()

  const onSubmit = async (data) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await login(data.email, data.password)
    } catch (error) {
      setLoading(false)
      console.error(error)
      const errorMessage = 'Invalid email or password'
      setErrorMessage(errorMessage)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" />
        <Box mt={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address *"
              name="email"
              autoComplete="email"
              autoFocus
              {...register('email', { required: true })}
            />
            {errors?.email?.type === 'required' && (
              <Typography variant="caption" color="error">
                Please enter your email address
              </Typography>
            )}
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password *"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register('password', { required: true })}
            />
            {errors?.password?.type === 'required' && (
              <Typography variant="caption" color="error">
                Please enter your password
              </Typography>
            )}
            {!!errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
          </form>
          <Grid container sx={{ marginBottom: 6 }}>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                Don&apos;t have an account? Sign Up
              </Link>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <FormHelperText>
                For additional security purposes, we recommend logging out after
                finishing your session in order to maintain your data privacy.
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default Login
