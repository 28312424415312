import React, { useState, useEffect } from 'react'
import {
  Alert,
  Container,
  Typography,
  FormControlLabel,
  Stack,
  Checkbox,
} from '@mui/material'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import LinearProgress from '@mui/material/LinearProgress'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import { sendRenewalEmail } from '../data/email'
import { listApplications, updateApplication } from '../data/application'
import { listRenewals, updateRenewal } from '../data/renewals'
import { getUser } from '../data/user'
import { MailOutline, ViewList } from '@mui/icons-material'
import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const ApplicationRenewalTable = () => {
  const { currentUser, db, functions } = useAuth()
  const [loadingList, setLoadingList] = useState(true)
  const navigate = useNavigate()
  const [applicationList, setApplicationList] = useState([])

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    getApplicationList()
  }, [])

  const approveRenewal = async (appId, userId) => {
    const renewalDueDate = dayjs().add(12, 'month').toDate()
    const updateApp = await updateRenewal(
      { status: 'Complete', renewalDueDate: renewalDueDate },
      appId,
      db,
    )
    if (updateApp) {
      const selectedUser = await getUser(userId, db)
      const emailSent = await sendRenewalEmail(functions, selectedUser)
      if (emailSent) {
        setAlert({
          open: true,
          message: 'Renewal approved! Email sent to applicant',
          type: 'success',
        })
      }
      getApplicationList()
    }

    if (!updateApp) {
      setAlert({
        open: true,
        message: `Error approving renewal, please try again`,
        type: 'error',
      })
    }
  }

  const declineApplication = async (appId) => {
    const updateApp = await updateRenewal(
      { status: 'Awaiting submission', renewalDueDate: null },
      appId,
      db,
    )
    if (updateApp) {
      setAlert({
        open: true,
        message: 'Renewal declined successfully',
        type: 'success',
      })
      getApplicationList()
    }

    if (!updateApp) {
      setAlert({
        open: true,
        message: `Error declining, please try again`,
        type: 'error',
      })
    }
  }

  const updateCheckbox = async (appId, field, event) => {
    const updateApp = await updateRenewal(
      { [field]: event.target.checked },
      appId,
      db,
    )

    if (updateApp) getApplicationList()

    if (!updateApp) {
      setAlert({
        open: true,
        message: `Error setting checkbox, please try again`,
        type: 'error',
      })
    }
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: 'createdOn',
      headerName: 'Dog Age',
      flex: 0.5,
      renderCell: (params) => {
        const date = params.row.dogAge || ''
        return date
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      flex: 0.5,
      renderCell: (params) => {
        const status = params.row.renewal.status
        switch (status) {
          case 'Awaiting submission':
            return <Typography sx={{ color: '#22a54a' }}>In Progress</Typography>
          case 'Awaiting approval':
            return <Typography sx={{ color: 'red' }}>Awaiting Approval</Typography>
          case 'Complete':
            return <Typography sx={{ color: '#bbb' }}>{status}</Typography>
          case 'Submitted':
            return <Typography sx={{ color: '#7f23e0' }}>{status}</Typography>
          default:
            return <Typography sx={{ color: '#bbb' }}>In Training</Typography>
        }
      },
    },
    {
      field: 'renewalDueDate',
      headerName: 'Renewal Due Date',
      flex: 0.5,
      renderCell: (params) => {
        let dueColor = '#000'
        if (params.row.renewal.renewalDueDate) {
          const dateDiff = dayjs(params.row.renewal.renewalDueDate, 'DD/MM/YYYY').diff(dayjs(), 'month')
          if (dateDiff > 0 && dateDiff <= 2) dueColor = '#FF7145'
          if (dateDiff <= 0) dueColor = 'red'
          if (dateDiff > 2) dueColor = 'green'
        }
        if (!params.row.renewal.renewalDueDate) return <Typography color="#bbb">Not due</Typography>
        const date = <Typography color={dueColor}>{params.row.renewal.renewalDueDate}</Typography>
        return date
      },
    },
    {
      field: 'none',
      width: 200,
      headerName: 'Form Review',
      sortable: false,
      renderCell: (params) => {
        const currentRow = params.row
        return (
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!currentRow.renewal.behaviourReview || false}
                  onChange={(event) =>
                    updateCheckbox(params.row.renewal.id, 'behaviourReview', event)
                  }
                />
              }
              label="Behaviour"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!currentRow.renewal.vetReview || false}
                  onChange={(event) =>
                    updateCheckbox(params.row.renewal.id, 'vetReview', event)
                  }
                />
              }
              label="Vet"
            />
          </Stack>
        )
      },
    },
    {
      field: 'action',
      width: 100,
      type: 'actions',
      headerName: 'Action',
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => navigate(`/application/renewals/${params.row.id}`)}
          icon={<ViewList />}
          label="View renewal forms"
          key={2}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => approveRenewal(params.row.renewal.id, params.row.id)}
          icon={<ThumbUpOffAltIcon />}
          label="Approve"
          key={1}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => declineApplication(params.row.renewal.id, params.row.id)}
          icon={<ThumbDownOffAltIcon />}
          label="Decline"
          key={2}
          showInMenu
        />,
      ],
    },
  ]

  const getApplicationList = async () => {
    setLoadingList(true)
    const applicationList = await listApplications(db)
    const list = applicationList.filter((app) => app.status === 'Complete')

    if (list) {
      for (const app of list) {
        // fetch any renewals
        const renewals = await listRenewals(db, app.id)
        const index = list.findIndex((l) => l.id == app.id)
        list[index].renewal = renewals[0] || {}

        if (renewals[0]) {
          list[index].renewal.renewalDueDate = renewals[0].renewalDueDate ? dayjs(renewals[0].renewalDueDate.seconds * 1000).format('DD/MM/YYYY') : null
        }

        // fetch user
        const user = await getUser(app.id, db)
        list[index].dogAge = user.dogDob ? dayjs(user.dogDob.seconds * 1000).fromNow(true) : ''
      }
      setApplicationList(list)
    }
    setLoadingList(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px', maxWidth: '100%' }}>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={alert.open}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Manage Full Applications
          </Typography>
          <Link to="/admin/dashboard">{'< Back to Admin Dashboard'}</Link>

          <Container component="main" sx={{ marginTop: 5, marginBottom: 5 }}>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                checkboxSelection={false}
                loading={loadingList}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                rows={applicationList}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
              />
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ApplicationRenewalTable
