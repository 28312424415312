import React, { useState } from 'react'
import {
  Alert,
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Box,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import QuizIcon from '@mui/icons-material/Quiz'
import Snackbar from '@mui/material/Snackbar'
import { useNavigate } from 'react-router-dom'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { useAuth } from '../contexts/AuthContext'

const AdminDashboard = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px' }}>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={alert.open}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Admin Dashboard
          </Typography>

          <Container component="main" maxWidth="md" mb={3}>
            <Grid justifyContent="left" container spacing={5} mb={8}>
              <Grid item md={4} sm={8} xs={12}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0px 5px 10px grey',
                    },
                  }}
                  onClick={() => navigate('/admin/manage-users')}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                    title={<span>Manage<br />Users</span>}
                    titleTypographyProps={{
                      align: 'center',
                      fontSize: '25px',
                      fontFamily: 'oswald',
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <AccountCircleIcon
                        color="primary"
                        sx={{ fontSize: 60 }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={8} xs={12}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0px 5px 10px grey',
                    },
                  }}
                  onClick={() => navigate('/admin/manage-applications')}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                    title="Manage In Training Applications"
                    titleTypographyProps={{
                      align: 'center',
                      fontSize: '25px',
                      fontFamily: 'oswald',
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <DescriptionIcon color="primary" sx={{ fontSize: 60 }} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={8} xs={12}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0px 5px 10px grey',
                    },
                  }}
                  onClick={() => navigate('/admin/manage-renewals')}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                    title={<span>Manage Full<br /> Applications</span>}
                    titleTypographyProps={{
                      align: 'center',
                      fontSize: '25px',
                      fontFamily: 'oswald',
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <DescriptionIcon color="primary" sx={{ fontSize: 60 }} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={8} xs={12}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0px 5px 10px grey',
                    },
                  }}
                  onClick={() => navigate('/admin/member-quiz')}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                    title={<span>Member<br />Quiz</span>}
                    titleTypographyProps={{
                      align: 'center',
                      fontSize: '25px',
                      fontFamily: 'oswald',
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <QuizIcon color="primary" sx={{ fontSize: 60 }} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={8} xs={12}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0px 5px 10px grey',
                    },
                  }}
                  onClick={() => navigate('/admin/manage-forms')}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                    title={<span>Manage<br />Forms</span>}
                    titleTypographyProps={{
                      align: 'center',
                      fontSize: '25px',
                      fontFamily: 'oswald',
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <DriveFolderUploadIcon
                        color="primary"
                        sx={{ fontSize: 60 }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default AdminDashboard
