/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useReducer } from 'react'
import { PropTypes } from 'prop-types'
import RenewalReducer from './RenewalReducer'
import { listRenewals } from '../../data/renewals'

const initialContextValue = {
  renewals: [],
  isLoading: false,
  errorMessage: '',
  fetchRenewals: () => Promise.resolve(),
}

export const RenewalContext = createContext(initialContextValue)

const RenewalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RenewalReducer, initialContextValue)

  const fetchRenewals = async (userId, db) => {
    try {
      dispatch({ type: 'FETCH_DATA' })
      const data = await listRenewals(db, userId)
      dispatch({ type: 'RENEWAL_DATA_SUCCESS', payload: data })
    } catch (e) {
      dispatch({ type: 'RENEWAL_DATA_ERROR', payload: e.message })
    }
  }

  const contextValue = {
    ...state,
    fetchRenewals,
  }

  return (
    <RenewalContext.Provider value={contextValue}>
      {children}
    </RenewalContext.Provider>
  )
}

RenewalProvider.propTypes = {
  children: PropTypes.node,
}

export default RenewalProvider
