import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'

import { useAuth } from '../contexts/AuthContext'
import logo from '../images/pads-logo-250.png'

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const { sendPasswordReset } = useAuth()

  const onSubmit = async (data) => {
    setLoading(true)
    setErrorMessage('')

    try {
      const result = await sendPasswordReset(data.email)
      if (result) {
        setLoading(false)
        setSuccessMessage(
          `If an account exists with this address a
           password reset request has been sent`,
        )
      }
    } catch (error) {
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" />
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!!successMessage && (
              <Alert sx={{ marginTop: 2 }} severity="success">
                {successMessage}
              </Alert>
            )}
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address *"
              name="email"
              autoComplete="email"
              autoFocus
              {...register('email', { required: true })}
            />
            {errors?.email?.type === 'required' && (
              <Typography variant="caption" color="error">
                Please enter your email address
              </Typography>
            )}
            {!!errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </LoadingButton>
          </form>
          <Grid container>
            <Grid item>
              <Link href="/signup" variant="body2">
                Don&apos;t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default ForgotPassword
