import React, { useEffect, useState, useMemo, useContext } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller, useForm } from 'react-hook-form'

import dayjs from 'dayjs'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import {
  TextField,
  Button,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Typography,
  Box,
  Alert,
  Snackbar,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CircleIcon from '@mui/icons-material/Circle'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { LoadingButton } from '@mui/lab'
import { firestoreAutoId } from '../../utils/general'

import { useAuth } from '../../contexts/AuthContext'
import { veterinaryRenewalRequired } from '../../utils/requiredFields'
import { createRenewal, updateRenewal } from '../../data/renewals'
import { RenewalContext } from '../../contexts/renewal/RenewalContext'
import { updateUser } from '../../data/user'

const VetRenewal = ({ currentRenewal }) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues,
    handleSubmit,
    reset,
  } = useFormContext()

  const { currentUser, fetchUser, db } = useAuth()
  const { renewals, fetchRenewals } = useContext(RenewalContext)

  const [vetForm, setVetForm] = useState('')
  const [formDetails, setFormDetails] = useState(currentRenewal)
  const [percent, setPercent] = useState(0)
  const [startedForm, setStartedForm] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const [docId, setDocId] = useState(currentRenewal?.id || firestoreAutoId())
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  useEffect(() => {
    setFormDetails(currentRenewal)
    const s = veterinaryRenewalRequired.every(
      (x) =>
        currentRenewal && (currentRenewal[x.field] === '' || !currentRenewal[x.field]),
    )

    const fields = []
    veterinaryRenewalRequired.forEach((input) => {
      if (
        currentRenewal && Object.keys(currentRenewal).length > 0 &&
        input.field &&
        (currentRenewal[input.field] === '' || !currentRenewal[input.field])
      ) {
        fields.push(input.label)
      }
    })


    if (currentRenewal && Object.keys(currentRenewal).length > 0) {
      setMissingFields(fields)
      setDocId(currentRenewal.id)
    }
    reset(currentRenewal)
    setStartedForm(!s)
  }, [currentRenewal])

  const storage = getStorage()

  const onSubmit = async (data) => {
    data.status = 'Awaiting approval'
    data.userId = currentUser.uid

    let updated = false

    if (!currentRenewal || (currentRenewal && Object.keys(currentRenewal).length === 0)) {
      updated = await createRenewal(data, docId, db, currentUser.uid)

      if (updated === 'app-in-progress') {
        setAlert({
          open: true,
          message: 'There is already a renewal in progress',
          type: 'error',
        })
        return
      }

      fetchRenewals(currentUser.uid, db)
      await updateUser({ dogDob: dayjs(data.dogDob).toISOString() }, currentUser.uid, db)
      fetchUser()
    } else {
      updated = await updateRenewal(data, docId, db)
      fetchRenewals(currentUser.uid, db)
    }

    if (updated) {
      setAlert({
        open: true,
        message: 'Succesfully submitted form',
        type: 'success',
      })
    } else {
      setAlert({
        open: true,
        message: 'Error submitting form. Please try again later',
        type: 'error',
      })
    }
  }

  const downloadFile = (fileName, uid) => {
    setDownloading(true)
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${currentUser.uid}/${docId}/${fileName}`,
    )

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = `${fileName}-${docId}`
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  const uploadToFirebase = (file, fileName) => {
    const storageRef = ref(storage, `/files/${currentUser.uid}/${docId}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setPercent(currentPercent)
      },
      (err) => console.error(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setVetForm(url)
          setValue('vetForm', url)
        })
      },
    )
  }

  const updateCurrentRenewal = async () => {
    setLoading(true)
    const data = getValues()
    data.status = 'Awaiting submission'
    data.userId = currentUser.uid
    let updated = false

    if (!currentRenewal || (currentRenewal && Object.keys(currentRenewal).length === 0)) {
      updated = await createRenewal(data, docId, db, currentUser.uid)

      if (updated === 'app-in-progress') {
        setAlert({
          open: true,
          message: 'There is already a renewal in progress',
          type: 'error',
        })
        setLoading(false)
        return
      }

      fetchRenewals(currentUser.uid, db)
      if (data.dogDob != '') await updateUser({ dogDob: dayjs(data.dogDob, 'DD-MM-YYYY').toDate() }, currentUser.uid, db)

      fetchUser()
      setLoading(false)
    } else {
      updated = await updateRenewal(data, docId, db)
      if (data.dogDob != '') await updateUser({ dogDob: dayjs(data.dogDob, 'DD-MM-YYYY').toDate() }, currentUser.uid, db)

      fetchRenewals(currentUser.uid, db)
      setLoading(false)
    }

    if (updated) {
      setAlert({
        open: true,
        message: 'Succesfully updated',
        type: 'success',
      })
    } else {
      setAlert({
        open: true,
        message: 'Error updating form. Please try again later',
        type: 'error',
      })
    }
  }

  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={4}>
        Complete Application & Renewals
      </Typography>

      <Typography variant="h4" mb={2} fontSize={28}>
        Previous Forms
      </Typography>
      {renewals?.length === 0 && (
        <Typography mb={4}>No previous forms</Typography>
      )}

      {renewals?.length > 0 && (
        <Grid mb={3}>
          <List component="nav">
            {renewals.map((app) => (
              <ListItemButton key={app.id}>
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <CircleIcon sx={{ width: '10px' }} />
                </ListItemIcon>
                <ListItemText
                  primary={<span><strong>{app.createdOn || app.updatedOn}</strong> - {app.status}</span>}
                  secondary={<Typography color='red'>{app.status === 'Awaiting submission' ? 'Please finish completing the form below' : ''}</Typography>}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      )}

      <Typography variant="h4" mb={2} fontSize={28}>
        Annual Veterinary Form
      </Typography>
      {!!startedForm && missingFields.length > 0 && (
        <Alert severity="warning" sx={{ marginBottom: 4 }}>
          <Grid onClick={handleClick} sx={{ cursor: 'pointer' }}>
            There are missing fields - click here to show more
            {open ? <ExpandLess /> : <ExpandMore />}
          </Grid>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ul>
              {missingFields.map((label) => (
                <li key={label}>{label}</li>
              ))}
            </ul>
          </Collapse>
        </Alert>
      )}
      <Typography variant="body1" mt={3}>
        <strong>Please upload the most recent copy of your annual vetinary form:</strong>
      </Typography>
      <FormGroup>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            marginTop: '10px',
            textTransform: 'none',
            width: '200px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*,file/*,application/pdf"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'annualVetForm')}
          />
          <input
            {...register('vetForm')}
            type="text"
            hidden
            defaultValue={formDetails?.vetForm || ''}
          />
        </Button>
        {percent > 0 && percent < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!vetForm || !!formDetails?.vetForm) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '210px',
              margin: 0,
              marginTop: '15px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('annualVetForm', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}
        <Typography variant="body1" mt={5}>
          <strong>About you (handler) and your dog</strong>
        </Typography>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.handlerName}
              id="handlerName"
              label="Handler First Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="handlerName"
          defaultValue={formDetails?.handlerName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.handlerLastName}
              id="handlerLastName"
              label="Handler Last Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="handlerLastName"
          defaultValue={formDetails?.handlerLastName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.dogName}
              id="dogName"
              label="Dog Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="dogName"
          defaultValue={formDetails?.dogName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.dogBreed}
              id="dogBreed"
              label="Breed"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="dogBreed"
          defaultValue={formDetails?.dogBreed || ''}
        />

        <Box mt={3}>
          <FormLabel id="dogDob">Dog Date of Birth</FormLabel>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register('dogDob')}
              format="DD-MM-YYYY"
              defaultValue={
                formDetails?.dogDob
                  ? dayjs(formDetails?.dogDob)
                  : ''
              }
              onChange={(value) =>
                setValue('dogDob', dayjs(value).format('DD-MM-YYYY'))
              }
            />
          </LocalizationProvider>
        </Box>

        <FormLabel sx={{ marginTop: 3 }} id="sex">
          Sex of Dog
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="sex" name="sex">
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="female" control={<Radio />} label="Female" />
            </RadioGroup>
          )}
          control={control}
          name="sex"
          defaultValue={formDetails?.sex || ''}
        />

        <Typography variant="body1" mt={5}>
          <strong>Vet details</strong>
        </Typography>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.vetName}
              id="vetName"
              label="Vet Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="vetName"
          defaultValue={formDetails?.vetName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceName}
              id="practiceName"
              label="Practice Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceName"
          defaultValue={formDetails?.practiceName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceAddress}
              id="practiceAddress"
              label="Pratice Address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceAddress"
          defaultValue={formDetails?.practiceAddress || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceCity}
              id="practiceCity"
              label="City"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceCity"
          defaultValue={formDetails?.practiceCity || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceCounty}
              id="practiceCounty"
              label="County"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceCounty"
          defaultValue={formDetails?.practiceCounty || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practicePostcode}
              id="practicePostcode"
              label="Post code"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="practicePostcode"
          defaultValue={formDetails?.practicePostcode || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceEmail}
              id="practiceEmail"
              label="Email address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceEmail"
          defaultValue={formDetails?.practiceEmail || ''}
        />

        <Typography variant="body1" mt={5}>
          <strong>Dog medical information</strong>
        </Typography>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.microchipNumber}
              id="microchipNumber"
              label="15 digit Microchip number"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="microchipNumber"
          defaultValue={formDetails?.microchipNumber || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.dogWeight}
              id="dogWeight"
              label="Weight (kg)"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="dogWeight"
          defaultValue={formDetails?.dogWeight || ''}
        />

        <Box mt={3}>
          <FormLabel id="vaccinationDate">Date of last vaccination</FormLabel>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register('vaccinationDate')}
              format="DD-MM-YYYY"
              defaultValue={
                formDetails?.vaccinationDate
                  ? dayjs(formDetails?.vaccinationDate)
                  : ''
              }
              onChange={(value) =>
                setValue('vaccinationDate', dayjs(value).format('DD-MM-YYYY'))
              }
            />
          </LocalizationProvider>
        </Box>

        <Box mt={3}>
          <FormLabel id="lastHealthCheckDate">
            Date of last health check, dewormers, fleas, ticks etc.
          </FormLabel>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register('lastHealthCheckDate')}
              format="DD-MM-YYYY"
              defaultValue={
                formDetails?.lastHealthCheckDate
                  ? dayjs(formDetails?.lastHealthCheckDate)
                  : ''
              }
              onChange={(value) =>
                setValue(
                  'lastHealthCheckDate',
                  dayjs(value).format('DD-MM-YYYY'),
                )
              }
            />
          </LocalizationProvider>
        </Box>

        <FormLabel sx={{ marginTop: 3 }} id="bodyScore">
          Body condition score:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="bodyScore"
              name="bodyScore"
            >
              <FormControlLabel value="1to3" control={<Radio />} label="1-3" />
              <FormControlLabel
                value="4or5"
                control={<Radio />}
                label="4 or 5"
              />
              <FormControlLabel value="6" control={<Radio />} label="6" />
              <FormControlLabel value="7to9" control={<Radio />} label="7-9" />
            </RadioGroup>
          )}
          control={control}
          name="bodyScore"
          defaultValue={formDetails?.concerns || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="neutered">
          Is the dog spayed/neutered?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="neutered"
              name="neutered"
            >
              <FormControlLabel value="na" control={<Radio />} label="N/A" />
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="neutered"
          defaultValue={formDetails?.neutered || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="boas">
          Please read page 3 before answering this section.
        </FormLabel>
        <FormLabel sx={{ marginTop: 0 }} id="boas">
          Is this breed classified as a Brachycephalic breed? (Please check
          online):
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="boas" name="boas">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel
                value="undetermined"
                control={<Radio />}
                label="Undetermined"
              />
            </RadioGroup>
          )}
          control={control}
          name="boas"
          defaultValue={formDetails?.boas || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="senility">
          Checking for senility (for dogs 7 years or older). If yes, please detail:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="senility"
              name="senility"
            >
              <FormControlLabel value="no" control={<Radio />} label="No signs" />
              <FormControlLabel value="yes" control={<Radio />} label="Yes, there are signs" />
            </RadioGroup>
          )}
          control={control}
          name="senility"
          defaultValue={formDetails?.senility || ''}
        />
        {watch('senility') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.senilityInfo}
                id="senilityInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="senilityInfo"
            defaultValue={formDetails?.senilityInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="sight">
          Eye and sight check:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="sight"
              name="sight"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="sight"
          defaultValue={formDetails?.sight || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="hearing">
          Ear and hearing check:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="hearing"
              name="hearing"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="hearing"
          defaultValue={formDetails?.hearing || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="joints">
          Joint and mobility check:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="joints"
              name="joints"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="joints"
          defaultValue={formDetails?.joints || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="dental">
          Dental/oral check:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="dental"
              name="dental"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="dental"
          defaultValue={formDetails?.dental || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="pain">
          Pain check:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="pain"
              name="pain"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="pain"
          defaultValue={formDetails?.pain || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="behaviour">
          Behaviour check during assessment:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="behaviour"
              name="behaviour"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="behaviour"
          defaultValue={formDetails?.behaviour || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="skin">
          Skin check - there is no atopy (allergy) development:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="skin"
              name="skin"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="skin"
          defaultValue={formDetails?.skin || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="heart">
          Heart check - abnormal rhythms or sounds detected:
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="heart"
              name="heart"
            >
              <FormControlLabel value="pass" control={<Radio />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio />} label="Fail" />
            </RadioGroup>
          )}
          control={control}
          name="heart"
          defaultValue={formDetails?.heart || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.comments}
              id="comments"
              label="Comments"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="comments"
          defaultValue={formDetails?.comments || ''}
        />


      </FormGroup>

      <LoadingButton
        type="button"
        onClick={updateCurrentRenewal}
        variant="contained"
        color="primary"
        loading={loading}
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Save
      </LoadingButton>
      <LoadingButton
        type="submit"
        variant="contained"
        color="success"
        disabled={missingFields.length > 0 || !startedForm}
        loading={loading}
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Submit
      </LoadingButton>
    </form>
  )
}

VetRenewal.propTypes = {
  renewal: PropTypes.object,
}

export default VetRenewal
