import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  deleteDoc,
} from 'firebase/firestore'
import dayjs from 'dayjs'

import { updateUser } from './user'

const createApplication = async (data, uid, db) => {
  try {
    await setDoc(doc(db, 'applications', uid), {
      ...data,
      createdOn: new Date(),
      status: 'In progress',
    })

    return data
  } catch (error) {
    return false
  }
}

const updateApplication = async (data, uid, db) => {
  try {
    const docRef = doc(db, 'applications', uid)
    await updateDoc(docRef, { ...data, updatedOn: new Date() })
    return data
  } catch (error) {
    console.error(error)
    return false
  }
}

const getApplication = async (uid, db) => {
  try {
    const docRef = doc(db, 'applications', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      data.createdOn = dayjs(docSnap.data().createdOn.seconds * 1000).format(
        'DD/MM/YYYY',
      )

      let updatedOn = new Date()
      if (docSnap.data().updatedOn && docSnap.data().updatedOn.seconds) {
        updatedOn = docSnap.data().updatedOn.seconds * 1000
      }
      data.updatedOn = dayjs(updatedOn).format('DD/MM/YYYY HH:ss')

      return data
    } else {
      console.log('no application found')
    }

    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

const listApplications = async (db) => {
  try {
    const querySnap = await getDocs(collection(db, 'applications'))
    const applications = querySnap.docs.map((doc) => {
      let updated = ''
      if (doc.data().updatedOn?.seconds) {
        updated = dayjs(doc.data().updatedOn.seconds * 1000).format(
          'DD/MM/YYYY HH:mm',
        )
      }
      let created = ''
      if (doc.data().createdOn?.seconds) {
        created = dayjs(doc.data().createdOn.seconds * 1000).format(
          'DD/MM/YYYY HH:mm',
        )
      }

      return {
        ...doc.data(),
        createdOn: created,
        updatedOn: updated,
        id: doc.id,
      }
    })

    return applications
  } catch (error) {
    console.error(error)
    return []
  }
}

const deleteApplication = async (uid, db) => {
  try {
    await deleteDoc(doc(db, 'applications', uid))
    await updateUser({ hasApplication: true }, uid, db)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export {
  createApplication,
  updateApplication,
  getApplication,
  listApplications,
  deleteApplication,
}
