/* eslint-disable quotes */
const module1 = {
  quizTitle: 'Canine Learning & Theory Quiz',
  quizSynopsis: "Let's go over what we learned in Canine Learning & Theory",
  questions: [
    {
      question: 'What is a neutral stimulus?',
      questionType: 'text',
      answers: [
        'a stimulus which at first elicits no response',
        'a stimulus that leads to an automatic response',
        'an automatic response to a stimulus',
        'None of the above.',
      ],
      correctAnswer: '1',
      point: 1,
      explanation:
        'A neutral stimulus is a stimulus which at first elicits no response. Pavlov introduced the ringing of the bell as a neutral stimulus',
    },
    {
      question: 'What are the consequences of operant behaviour?',
      questionType: 'text',
      answers: [
        'Good (reinforcing) - Behaviour likely to occur again',
        'Bad (aversive) - Can create avoidance, aggression or disengagement',
        'Neutral (extinction) - Over time occurrence of behaviour is reduced or is extinguished',
        'All of the above',
      ],
      correctAnswer: '4',
      point: 1,
      explanation:
        'Operant conditioning is a type of learning in which organisms associate their own actions with consequences; behaviour is strengthened if followed by a reinforcer or diminished if followed by a punisher.  Operant behaviour could have all 3 of the above consequences',
    },
    {
      question: 'What is Counter Conditioning?',
      questionType: 'text',
      answers: [
        'An effective therapy to alleviate anxieties or phobias',
        'A way to improve temperament',
        'A process to help maintain engagement',
        'A reinforcement technique',
      ],
      correctAnswer: '1',
      point: 1,
      explanation:
        'Counter conditioning is an effective therapy to alleviate anxieties or phobias by pairing the trigger stimulus with a response that is contrary to fear. Dogs with various emotional behaviour responses (aggression, fear, separation anxiety) are often overly and abnormally sensitive to a stimulus (person, dog, noise). This over sensitivity can cause reactivity where the dog reacts inappropriately with excessive fear or aggression toward the stimulus in question.',
    },
    {
      question: 'What are Adverse Training Methods?',
      questionType: 'text',
      answers: [
        'Use of balls, toys and treats',
        'Use of shock and prong collars, sprays, water bottles, rattles or air horns',
        'Using a clicker as behaviour modification',
        'Teaching your dog to obey commands with praise and rewards',
      ],
      correctAnswer: '2',
      point: 1,
      explanation:
        'Punishment methods are a concern for the welfare of the animal. They are used to cause harm, shock and fear by using punishment and bullying for unwanted behaviour. The dog will remember being punished and will not repeat the behaviour',
    },
    {
      question: 'What are Force free training methods?',
      answers: [
        'An audial bridge',
        'Marker or clicker training',
        'Negative punishment i.e. removing our attention from the dog',
        'All of the above',
      ],
      questionType: 'text',
      correctAnswer: '4',
      point: 1,
      explanation:
        'If the condition is true, the code block is executed again; if not, we exit the loop and continue with the code following the closing curly brace of the while loop.',
    },
  ],
}

const module2 = {
  quizTitle: 'Puppy Development Quiz',
  quizSynopsis:
    "Quiz going over what we've covered on Puppy Development so far",
  questions: [
    {
      question:
        'Why is getting a puppy not always better than a rescue or adult dog?',
      questionType: 'text',
      answers: [
        'Puppies are too playful',
        'A rescue or adult dog is too old',
        'Puppies are a "clean slate"',
        'None of the above',
      ],
      correctAnswer: '4',
      point: 1,
      explanation:
        'Nature and nurture have a massive impact. A puppy inherits genes from his or her parents and these genes have an impact on the puppys genetic potential to behave in a certain way. In other words the puppy may be predisposed to certain behaviours for example, if one or both parents were fearful, their offspring may be predisposed genetically towards being fearful. Exposure to negative or traumatic experiences early in life will affect the way that dogs (and all animals) anticipate and process sensory input',
    },
    {
      question: 'At what stage does play become more sophisticated?',
      questionType: 'text',
      answers: [
        'Awareness (or Identification) (21 – 28 days)',
        'Second Awareness (Identification) (28 – 35 days)',
        'Neonatal (1 – 14 days)',
        'Socialisation (5 – 16 weeks)',
      ],
      correctAnswer: '2',
      point: 1,
      explanation:
        'At Second Awareness (Identification) (28 – 35 days) play develops and becomes more sophisticated. Weaning is impending and pups are able to ingest real food rather than milk. This is the beginning of the socialisation period and pups require mental and physical stimulation for healthy development both emotionally and physically.',
    },
  ],
}

const module3 = {
  quizTitle: 'Canine Care Quiz',
  quizSynopsis: "Quiz going over what we've covered on Canine Care so far",
  questions: [
    {
      questionType: 'text',
      question: 'Why is a daily routine important?',
      answers: [
        'It gives peace of mind',
        'It prevents destructive or unwanted behaviour',
        'It helps dogs behaviour and their ability to learn toilet training/house rules',
        'It means I can have a lie-in',
      ],
      correctAnswer: '3',
      point: 1,
      explanation:
        "It is important to establish a routine that is easy to keep and fits in with your schedule. You will need to plan a schedule that is easy to maintain and doesn't constantly change as this will affect the dogs behaviour and their ability to learn toilet training/house rules",
    },
  ],
}

export { module1, module2, module3 }
