import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useAuth } from '../../contexts/AuthContext'
import { Grid, Paper, Snackbar, Alert, Typography } from '@mui/material'

import CalendarBody from './CalendarBody'
import CalendarHead from './CalendarHeader'

import AddActivity from '../AddActivity'
import EditActivity from '../EditActivity'
import ActivityList from '../ActivityList'
import { listActivities } from '../../data/training-log'

const localeData = require('dayjs/plugin/localeData')
dayjs.extend(localeData)

const TrainingLog = (props) => {
  const { selectedUser, viewOnly } = props
  const { currentUser, db } = useAuth()

  const user = selectedUser || currentUser.uid

  const defaultSelectedDay = {
    day: dayjs().format('D'),
    month: dayjs().month(),
  }

  const [dateObject, setdateObject] = useState(dayjs())
  const [showMonthTable, setShowMonthTable] = useState(false)
  const [showYearTable, setShowYearTable] = useState(false)
  const [selectedDay, setSelected] = useState(defaultSelectedDay)
  // Later add hook for active days from database

  const allMonths = dayjs.months()
  const currentMonth = () => dateObject.format('MMMM')
  const currentYear = () => dateObject.format('YYYY')

  const setMonth = (month) => {
    const monthNo = allMonths.indexOf(month)
    let newDateObject = Object.assign({}, dateObject)
    newDateObject = dayjs(dateObject).set('month', monthNo)
    setdateObject(newDateObject)
    setShowMonthTable(false)
  }

  const setYear = (year) => {
    let newDateObject = Object.assign({}, dateObject)
    newDateObject = dayjs(dateObject).set('year', year)
    setdateObject(newDateObject)
    setShowYearTable(false)
    retrieveData(year)
  }

  const toggleMonthSelect = () => {
    setShowMonthTable(!showMonthTable)
    setShowYearTable(false)
  }
  const toggleYearSelect = () => {
    setShowYearTable(!showYearTable)
    setShowMonthTable(false)
  }

  const setSelectedDay = (day) => {
    setSelected({
      day,
      month: currentMonthNum(),
      year: currentYear(),
    })
    // Later refresh data
  }

  const currentMonthNum = () => dateObject.month() + 1
  const daysInMonth = () => dateObject.daysInMonth()
  const currentDay = () => dateObject.format('D')
  const actualMonth = () => dayjs().format('MMMM')

  const firstDayOfMonth = () => dayjs(dateObject).startOf('month').format('d')

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState(null)

  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState([])
  const [activeDays, setActiveDays] = useState([])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }

  const monthValue = selectedDay.month
  const zeroMonthValue = monthValue.toString().padStart(2, '0')
  const dateFormatted = dayjs(
    `${currentYear()}-${zeroMonthValue}-${selectedDay.day}`,
  ).format('DD MMM')

  const retrieveData = async (year) => {
    const selectyear = year || currentYear()
    const zeroDayValue = selectedDay.day.toString().padStart(2, '0')
    const queryDate = `${selectyear}-${zeroMonthValue}-${zeroDayValue}`

    const data = await listActivities(user, db, queryDate)

    setActivities(data?.activities || [])
    setLoading(false)

    // Update active days
    retrieveActiveDays()
  }

  const retrieveActiveDays = async () => {
    const data = await listActivities(user, db)
    if (data && data.activities) {
      const values = Object.values(data.activities)

      // Store all active day/month combinations in array for calendar
      const arr = values.map((obj) => {
        return {
          date: obj.date,
          trainer: obj.trainer,
          duration: obj.duration,
        }
      })

      setActiveDays(arr)
    }
  }

  useEffect(() => {
    retrieveData()
  }, [selectedDay])

  useEffect(() => {
    const day = dayjs().date()
    setSelectedDay(day)
  }, [])

  const [editing, setEditing] = useState(false)
  const [activity, setActivity] = useState(null)
  const [activityKey, setActivityKey] = useState(null)

  const editActivity = (activity, i) => {
    setActivityKey(Object.keys(activities)[i])
    setEditing(true)
    setActivity(activity)
  }

  const totalMonthsTraining = () => {
    let total = 0
    let totalTrainer = 0
    let overallTotal = 0

    activeDays.forEach((act) => {
      if (
        dayjs(act.date).isSame(
          `${currentYear()}-${dateObject.format('MM')}-${selectedDay.day}`,
          'month',
        )
      ) {
        if (!act.trainer) total += act.duration
        if (act.trainer) totalTrainer += act.duration
      }
      overallTotal += act.duration
    })

    return {
      total,
      trainer: totalTrainer,
      overall: overallTotal,
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <CalendarHead
          allMonths={allMonths}
          currentMonth={currentMonth}
          currentYear={currentYear}
          setMonth={setMonth}
          setYear={setYear}
          showMonthTable={showMonthTable}
          showYearTable={showYearTable}
          toggleMonthSelect={toggleMonthSelect}
          toggleYearSelect={toggleYearSelect}
        />
        <CalendarBody
          firstDayOfMonth={firstDayOfMonth}
          daysInMonth={daysInMonth}
          currentDay={currentDay}
          currentMonth={currentMonth}
          currentMonthNum={currentMonthNum}
          actualMonth={actualMonth}
          actualYear={dayjs().format('YYYY')}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          weekdays={dayjs.weekdays()}
          activeDays={activeDays}
          currentYear={currentYear}
          activities={activities}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <>
          {!!viewOnly && (
            <Paper className="paper" sx={{ pt: 2 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Totals for {dateObject.format('MMMM')}
              </Typography>
              <Typography>
                Individual Training: {totalMonthsTraining().total} mins
              </Typography>
              <Typography>
                With Trainer: {totalMonthsTraining().trainer} mins
              </Typography>

              <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                Overall Total
              </Typography>
              <Typography>{totalMonthsTraining().overall} mins</Typography>
            </Paper>
          )}
          {!viewOnly && (
            <Paper className="paper" sx={{ pt: 2 }}>
              {editing ? (
                <>
                  <h5>Edit activity on {dateFormatted} </h5>
                  <EditActivity
                    activity={activity}
                    activityKey={activityKey}
                    selectedDay={selectedDay}
                    setEditing={setEditing}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarMsg={setSnackbarMsg}
                  />
                </>
              ) : (
                <>
                  <h5>Add activity on {dateFormatted} </h5>
                  <AddActivity
                    selectedDay={selectedDay}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarMsg={setSnackbarMsg}
                    refetchData={retrieveData}
                    currentYear={currentYear}
                  />
                </>
              )}
            </Paper>
          )}
        </>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper className="paper">
          <h5>Activities on {dateFormatted}</h5>
          <ActivityList
            viewOnly={viewOnly}
            loading={loading}
            activities={activities}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMsg={setSnackbarMsg}
            editActivity={editActivity}
            setEditing={setEditing}
            refetchData={retrieveData}
          />
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openSnackbar}
      >
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default TrainingLog
