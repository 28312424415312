import { httpsCallable } from 'firebase/functions'

const sendEmail = async (functions, user) => {
  const sendEmailToAdmin = httpsCallable(functions, 'sendAdminEmail')

  const emailData = {
    title: 'Registered Interest',
    message: `${user.firstName} ${user.lastName} has registered interest 
    in applying for an Assistance Dog. Email address: ${user.email}`,
    subject: 'Registered Interest',
  }

  const emailSent = await sendEmailToAdmin(emailData)

  return emailSent.data === 'success'
}

const sendSubmittedEmail = async (functions, user) => {
  const sendEmailToAdmin = httpsCallable(functions, 'sendAdminEmail')

  const emailData = {
    title: 'Application Submitted for approval',
    message: `${user.firstName} ${user.lastName} has completed an 
    application. Please log in to view their details`,
    subject: 'Registered Interest',
  }

  const emailSent = await sendEmailToAdmin(emailData)

  return emailSent.data === 'success'
}

const sendApprovedEmail = async (functions, user) => {
  const sendEmailToAdmin = httpsCallable(functions, 'sendEmail')

  const emailData = {
    title: 'Your In Training Application with PADs has been approved!',
    message: '',
    subject: 'Your In Training Application with PADs has been approved!',
    email: user.email,
  }

  const emailSent = await sendEmailToAdmin(emailData)

  return emailSent.data === 'success'
}

const sendRenewalEmail = async (functions, user) => {
  const sendEmailToAdmin = httpsCallable(functions, 'sendRenewalEmail')

  const emailData = {
    title: 'Your In Training Renewal with PADs has been approved!',
    message: '',
    subject: 'Your In Training Renewal with PADs has been approved!',
    email: user.email,
  }

  const emailSent = await sendEmailToAdmin(emailData)

  return emailSent.data === 'success'
}

export { sendEmail, sendSubmittedEmail, sendApprovedEmail, sendRenewalEmail }
