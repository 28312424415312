/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page, Link } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const DogLawUK = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="puppy-development" style={styles.title}>
      7. UK Dog Law
    </Text>
    <Text style={styles.text}>
      We all know that owning a dog is a big responsibility. A dog/handler
      relationship can be immensely rewarding, provided it has been built with
      love, care and respect.
    </Text>
    <Text style={styles.text}>
      All dog owners in the UK have a legal duty to make sure they are providing
      for their pup’s welfare needs.
    </Text>
    <Text style={styles.text}>
      It’s important to be aware of your obligations when taking on the
      responsibility of an animal, but with so many different acts, legislations
      and codes of conduct in place, staying up-to-date with specific laws can
      get confusing. The laws cited here are as of 2022. We shall endeavour to
      update this chapter when any changes in the law occurs but as a dog
      owner/handler it is your responsibility to keep up to date with UK dog
      law. Should you be interested, Cooper & Co Solicitors provide a variety of
      Dog Law webinars that can be attended from the comfort of your own home.
    </Text>
    <Link
      style={styles.text}
      href="https://doglaw.co.uk/seminars/doglaw-seminars/"
    >
      https://doglaw.co.uk/seminars/doglaw-seminars/
    </Link>
    <Text style={styles.subtitleBold} id="duty-of-care">
      An owner&apos;s duty of care
    </Text>
    <Text style={styles.text}>
      The Animal Welfare Act 2006 was set up to ensure pet owners meet the five
      welfare needs when looking after their animals.{' '}
    </Text>
    <Text style={styles.text}>
      Under this act, if a dog’s needs aren’t met, dog owners could be
      prosecuted for animal cruelty.
    </Text>
    <Text style={styles.text}>
      The term ‘cruelty’, refers to the act of causing an animal unnecessary
      suffering.
    </Text>
    <Text style={styles.text}>
      As well as covering deliberate infliction of pain or hurt onto an animal,
      cruelty includes neglect and failing to meet a dog’s needs to live a
      healthy and fulfilled life.
    </Text>
    <Text style={styles.text}>
      In the UK, all domestic animals, including dogs, have the legal right to:
    </Text>
    <ListItem>
      <Text style={styles.textBold}>Environment</Text>: Live in a suitable
      environment.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Diet</Text>: Eat a suitable diet.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Behaviour</Text>: Exhibit normal behaviour
      patterns.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Companionship</Text>: Be housed with, or
      apart from, other animals.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Health</Text>: Be protected from pain,
      suffering, injury and disease.{' '}
    </ListItem>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Top Tip</Text>: The Code of Practice for the
      Welfare of Dogs provides more information to help pet owners understand
      and meet these five needs.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Under section 32 of the Animal Welfare Act 2006
      </Text>
      , dog owners who are found to be failing to care for their dogs could
      face:
    </Text>
    <ListItem>
      Going to court and facing a prison sentence of up to 51 weeks{' '}
    </ListItem>
    <ListItem>A fine of up to £20,000</ListItem>
    <ListItem>Their dog being taken away</ListItem>
    <ListItem>Being banned from owning pets in the future</ListItem>
    <Text style={styles.subtitleBold} id="finns-law">
      Finn&apos;s Law
    </Text>
    <Text style={styles.text}>
      In 2019, legislation was introduced under the Animal Welfare Act to make
      it harder for people who harm Assistance Animals to claim that they were
      doing so in self defence.
    </Text>
    <Text style={styles.text}>
      Referred to as ‘Finn’s Law’, this amendment followed the stabbing of a
      police dog named Finn.
    </Text>
    <Text style={styles.text}>
      Finn sadly sustained stab wounds to the chest and his head, but at the
      time, the only charges brought against his attacker were criminal damage.
    </Text>
    <Text style={styles.text}>
      As of June 2021, Finn’s Law Part 2 has brought a change in the law which
      has{' '}
      <Text style={styles.textBold}>
        increased the maximum prison sentence for such animal cruelty from six
        months to five years
      </Text>{' '}
      with an unlimited fine.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(86).jpg?alt=media"
    />
    <Text style={styles.underlineHeading} id="dogs-road">
      Laws on driving with dogs and road accidents{' '}
    </Text>
    <Text style={styles.subtitleBold} id="highway-code">
      The Highway Code
    </Text>
    <Text style={styles.text}>
      Under the Highway Code, published in 2015 and updated in March 2022,
      certain rules regarding driving with dogs and walking with dogs are in
      place.
    </Text>
    <Text style={styles.text}>Dog owners should be aware of.</Text>
    <ListItem>
      <Text style={styles.textBold}>Rule 56</Text>: Do not let a dog out on the
      road on its own. Keep it on a short lead when walking on the pavement,
      road or path shared with cyclists or horse riders.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Rule 57</Text>: When in a vehicle, make sure
      dogs or other animals are suitably restrained so they cannot distract you
      while you are driving or injure you, or themselves, if you stop quickly. A
      seat belt harness, pet carrier, dog cage or dog guard are ways of
      restraining animals in cars.
    </ListItem>
    <Text style={styles.text}>
      Breaking the Highway Code is not currently an offence in the UK. However,
      the act of allowing a dog to distract or injure you while driving could be
      taken into account in the event of an accident.
    </Text>
    <Text style={styles.subtitleBold} id="traffic-act">
      Road Traffic Act
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Section 170 of the Road Traffic Act 1988
      </Text>{' '}
      states that any driver who injures a dog while driving a car, motorbike or
      other vehicle type must
      <Text style={styles.textBold}>
        provide their name and address to the dog owner
      </Text>{' '}
      or person in charge of the dog at the time.
    </Text>
    <Text style={styles.text}>
      In the event that no person is accompanying the dog during the time of the
      accident, drivers are required to{' '}
      <Text style={styles.textBold}>report the incident to the police</Text>
      within 24 hours.
    </Text>
    <Text style={styles.text}>
      Under this act, failure to stop or report is an offence and could result
      in the following:{' '}
    </Text>
    <ListItem>
      <Text style={styles.textBold}>Penalty points</Text> on your driving
      licence from 5 - 10
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>A fine</Text> of up to £5,000
    </ListItem>
    <Text style={styles.underlineHeading} id="dogs-public">
      Laws on walking with your dog in public places{' '}
    </Text>
    <Text style={styles.subtitleBold} id="pspo">
      Public Spaces Protection Orders (PSPOs)
    </Text>
    <Text style={styles.text}>
      Previously referred to as Dog Control Orders (DCOs), Public Spaces
      Protection Orders are in place in certain public areas in England and
      Wales.
    </Text>
    <Text style={styles.text}>
      It’s important to{' '}
      <Text style={styles.textBold}>
        refer to the PSPOs that exist in your local area
      </Text>
      .
    </Text>
    <Text style={styles.text}>
      Each local council is required to provide public access to these rules via
      their website.
    </Text>
    <Text style={styles.text}>
      While each specific PSPO can differ from place to place, the orders may
      require dog owners to:
    </Text>
    <ListItem>
      Clear up after their dogs (keeping pick up bags on hand during dog walks)
    </ListItem>
    <ListItem>
      Limit the number of dogs being walked at one time (regardless of personal
      or professional dog walking status){' '}
    </ListItem>
    <ListItem>
      Keep dogs on leads, and follow instructions to put dogs on leads if told
      to by police officers, support officers or council members{' '}
    </ListItem>
    <ListItem>
      Prevent dogs from entering specific places like parks, children’s play
      areas and farmlands
    </ListItem>
    <Text style={styles.text}>
      Under these regulations, dog owners found to be breaking orders can be
      fined. These fines include:{' '}
    </Text>
    <ListItem>
      <Text style={styles.textBold}>Fixed Penalty Notice</Text>: Fined £100 on
      the spot
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>A higher fine</Text> up to £1,000 if the
      charge goes to court
    </ListItem>
    <Text style={styles.subtitleBold} id="traffic-act">
      Road Traffic Act
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Section 27</Text> of this act outlines the
      <Text style={styles.textBold}>rules for walking dogs on roads</Text>.
    </Text>
    <Text style={styles.text}>
      Under this act, a person who is found to be{' '}
      <Text style={styles.textBold}>
        walking their dog without a lead on a designated road could be guilty of
        an offence.
      </Text>
    </Text>
    <Text style={styles.text}>
      A ‘designated road’ in this context means a length of road specified by an
      order on behalf of the local authority in whose area the length of road is
      situated.
    </Text>
    <Text style={styles.text}>
      For this reason, it’s important to check the roads in your local area, as
      local councils have the power to issue specific PSPOs as mentioned.
    </Text>
    <Text style={styles.subtitleBold} id="country-code">
      The Countryside Code
    </Text>
    <Text style={styles.text}>
      This code outlines advice for enjoying UK parks, waterways, coastal and
      countryside areas safely.
    </Text>
    <Text style={styles.text}>
      When it comes to walking your dog in these areas, it’s important to{' '}
      <Text style={styles.textBold}>keep your dog under control</Text> to ensure
      it stays away from wildlife, livestock, horses and other people.
    </Text>
    <Text style={styles.text}>To do so, the code advises: </Text>
    <ListItem>Keeping a dog on lead or in sight</ListItem>
    <ListItem>Being confident in a dog’s recall abilities</ListItem>
    <ListItem>
      Making sure a dog doesn’t stray from the path or area where right of
      access applies
    </ListItem>
    <Text style={styles.text}>
      As per the PSPOs mentioned above, it’s crucial to check local signage
      provided by the council to ensure you are following local restrictions.
    </Text>
    <Text style={styles.subtitleBold} id="livestock">
      Livestock Protection
    </Text>
    <Text style={styles.text}>
      The code highlights the importance of keeping a dog on a lead when walking
      close to livestock.{' '}
    </Text>
    <Text style={styles.text}>
      It is a legal requirement to keep your dog on a lead when walking on Open
      Access land(even if there is no livestock on the land at the time of your
      walk).
    </Text>
    <Text style={styles.text}>
      The most crucial piece of information here is that under section 9 of the
      Animals Act 1971 and Protection of Livestock Act 1953, ‘a farmer can shoot
      a dog that is attacking or chasing livestock. They may not be liable to
      compensate the dog’s owner.’{' '}
    </Text>
    <Text style={styles.text}>
      Find out more about{' '}
      <Link
        href="https://www.gov.uk/government/publications/the-countryside-code/the-countryside-code-advice-for-countryside-visitors"
        target="_blank"
      >
        The Countryside Code.
      </Link>
    </Text>
    <Text style={styles.textBold}>A summary of UK dog walking laws: </Text>
    <ListItem>
      <Text style={styles.textBold}>Dog Fouling</Text>: Not cleaning up after
      your dog can result in an on-the-spot fine up to £100 depending on your
      local council’s PSPO.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Carrying Pick-up Bags</Text>: Some councils
      have made it mandatory to carry a poop scoop and disposable bag when
      walking dogs in public places.{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Walking On Agricultural Land</Text>: Under
      certain circumstances, farmers retain the right to stop and / or shoot
      dogs that are chasing or attacking their livestock.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Walking On Designated Roads</Text>: It is a
      criminal offence for dogs to be walked on these roads without being held
      on a lead.{' '}
    </ListItem>
    <Image
      style={{
        margin: '0 auto',
        width: '400px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(87).jpg?alt=media"
    />
    <Text style={styles.underlineHeading} id="dogs-others">
      Laws on keeping others safe around your dog
    </Text>
    <Text style={styles.text}>
      It is a legal requirement to keep your dog under control in both private
      and public places, and failure to do so could result in you being fined,
      sent to prison for up to six months, or both.
    </Text>
    <Text style={styles.text}>
      It is against the law to allow your dog to be dangerously out of control
      and injure, or threaten to injure another person or animal. It is a
      criminal offence if your dog makes someone{' '}
      <Text style={styles.textBold}>reasonably fear injury</Text>. If this can
      be proven beyond a reasonable doubt the dog will be considered to have
      been dangerously out of control under{' '}
      <Text style={styles.textBold}>
        Section 3 of the Dangerous Dogs Act 1991.
      </Text>
    </Text>
    <Text style={styles.text}>
      The DDA recognises the potential every dog has of displaying dangerous
      behaviour and makes a provision for it in section 3.
    </Text>
    <Text style={styles.text}>
      It is an offence if a dog (of any breed or type):
    </Text>
    <ListItem>Is dangerously out of control in a public place</ListItem>
    <ListItem>Is dangerously out of control in a private place</ListItem>
    <ListItem>
      Is dangerously out of control in a private place where he is not allowed
      to be
    </ListItem>
    <Text style={styles.text}>A dog is dangerously out of control if:</Text>
    <ListItem>Dog injures a person</ListItem>
    <ListItem>
      Dog behaves in such a way that makes a person worry that he may injure
      them
    </ListItem>
    <Text style={styles.text}>
      Both the dog’s owner and the person in charge of the dog at the time are
      liable.
    </Text>
    <Text style={styles.text}>
      If a dog is owned or under the care of a person under 16, the head of the
      household is deemed responsible if the dog is dangerously out of control.
    </Text>
    <Text style={styles.text}>
      Section 3 of the Act applies to every single dog owner in England and
      Wales. Under this section, it is a criminal offence for the person in
      charge of the dog to allow it to be ‘dangerously out of control’ in a
      public place.
    </Text>
    <Text style={styles.text}>
      A dog does <Text style={styles.textBold}>not</Text> have to bite to be
      deemed dangerous in the eyes of the law.
    </Text>
    <Text style={styles.text}>
      Generally, if a dog bites a person, he will be presumed to have been
      ‘dangerously out of control’.
    </Text>
    <Text style={styles.text}>
      However, even if the dog does not bite, but gives the person grounds to
      <Text style={styles.textBold}>feel</Text> that the dog may injure them,
      the law still applies.
    </Text>
    <Text style={styles.text}>Some dog owners remain unaware of this.</Text>
    <Text style={styles.text}>
      The penalties in place for this law include:
    </Text>
    <ListItem>
      <Text style={styles.textBold}>Up to five years imprisonment</Text> and /
      or a fine if you fail to stop your dog from injuring someone
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>A ‘malicious wounding’ charge</Text> if you
      deliberately use your dog to injure someone
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Up to fifteen years imprisonment</Text> and
      / or an unlimited fine if you allow your dog to kill someone{' '}
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Up to three years imprisonment</Text> and or
      a fine if you don’t prevent your dog from injuring an Assistance Dog{' '}
    </ListItem>
    <Text style={styles.text}>
      The DDA was originally introduced to outlaw attacks on people and was
      rushed through with no thought to other potential victims. A number of
      groups have been campaigning over the years to change the law in order to
      protect assistance dogs and their owners. The number of attacks on
      assistance dogs by other dogs has steadily increased. Between March 2011
      and February 2013 there were 240 attacks on Guide Dogs alone. When an
      assistance dog is attacked by another dog it is traumatic (and sometimes
      fatal) for the dog, but also traumatic for the client too. Assistance dogs
      rarely respond when they are attacked as they have been deliberately
      chosen for their temperament. Attacks can lead to the withdrawal of the
      dog from the service, further impacting the client’s mental and physical
      capacity, as well as their safety and security. Additionally, the
      financial cost of training an assistance dog often means that those whose
      dogs are retired face a long wait for another dog, thus further limiting
      their ability to live independently.
    </Text>
    <Text style={styles.text}>
      The role of an “assistance dog” is now part of the DDA. The meaning of
      this is specified by section 173(1) of the Equality Act 2010:
    </Text>
    <ListItem>a dog which has been trained to guide a blind person</ListItem>
    <ListItem>a dog which has been trained to assist a deaf person</ListItem>
    <ListItem>
      a dog which has been trained by a prescribed charity to assist a disabled
      person who has a disability that consists of epilepsy or otherwise affects
      the person’s mobility, manual dexterity, physical co ordination or ability
      to lift, carry or otherwise move everyday objects
    </ListItem>
    <ListItem>
      a dog of a prescribed category which has been trained to assist a disabled
      person who has a disability (other than one falling within category c) of
      a prescribed kind.
    </ListItem>
    <Text style={styles.text}>
      The DDA has been extended so that section 3 applies to assistance dogs.
      Where it discusses the risk of injuring a person it has been extended.
      This means that a dog will be dangerously out of control where there are
      grounds for reasonable apprehension that it will injure any assistance
      dog,{' '}
      <Text style={styles.textBold}>whether or not it actually does so</Text>.
    </Text>
    <Text style={styles.text}>
      Previously, an offence under the DDA was restricted to an injury to any
      person. This has now been amended to include “or assistance dog”.
      <Text style={styles.textBold}>
        Therefore, neither a person nor an assistance dog needs to be the victim
        of an actual bite for an offence to be committed
      </Text>
      . Growling, snarling or even bared teeth resulting in no injury to a
      person or dog still means an offence has been committed. If the assistance
      dog is actually injured it then becomes an{' '}
      <Text style={styles.textBold}>aggravated offence</Text>. The owner of the
      dangerously out of control dog faces a maximum sentence of 3 years
      imprisonment and the dog could be euthanized under a court order.
    </Text>
    <Text style={styles.text}>
      The British Veterinary Association (BVA) and the RSPCA, along with others
      campaigned to change the law to protect all dogs that were victims of
      attack by another dog, but the amendment did not include all dogs.
    </Text>
    <Text style={styles.text}>
      In May 2014, The Scottish Parliament introduced a measure which is
      equivalent to the Anti-Social Behaviour, Crime and Policing Act. The
      Control of Dogs (Scotland) Act was already in place which empowered dog
      wardens to be able to issue Dog Control Notices to try and curb
      anti-social behaviour from dogs. The impact of these laws is currently
      being monitored.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '350px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(88).jpg?alt=media"
    />
    <Text style={styles.underlineHeading} id="stray">
      Laws on stray dogs, breeding and buying puppies{' '}
    </Text>
    <Text style={styles.text}>
      There are several acts and legislation in place to protect dogs and
      puppies when it comes to breeding, buying and selling canines.{' '}
    </Text>
    <Text style={styles.text}>
      In England and Wales, it is{' '}
      <Text style={styles.textBold}>
        illegal to sell a puppy under the age of eight weeks old.
      </Text>{' '}
    </Text>
    <Text style={styles.subtitleBold}>Lucy’s Law</Text>
    <Text style={styles.text}>
      Following a petition against poor puppy farming conditions, Lucy’s Law
      came into effect in April 2020.{' '}
    </Text>
    <Text style={styles.text}>
      Lucy was a Cavalier King Charles Spaniel rescued from a Welsh puppy farm,
      where she had been found to spend five years in a cage, used to
      continuously breed litters upon litters of puppies.
    </Text>
    <Text style={styles.text}>
      Under Lucy&apos;s Law, it is{' '}
      <Text style={styles.textBold}>
        illegal to sell puppies and kittens under the age of six months old
        unless the seller has bred the puppy or kitten themselves
      </Text>
      , or is a legitimate rescue centre.
    </Text>
    <Text style={styles.subtitleBold}>Environmental Protection Act 1990 </Text>
    <Text style={styles.text}>
      Under this act, any person who finds a stray dog has an obligation to make
      an attempt to:
    </Text>
    <ListItem>Reunite them with their owner </ListItem>
    <ListItem>Report them to a dog warden </ListItem>
    <Text style={styles.text}>
      In England and Wales, the police are not responsible for stray dogs.{' '}
    </Text>
    <Text style={styles.text}>
      However, if you find a stray dog and do not contact a dog warden, you
      could be accused of theft.{' '}
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '350px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(89).jpg?alt=media"
    />
    <Text style={styles.underlineHeading} id="microchip">
      Microchipping and dog tags, UK law
    </Text>
    <Text style={styles.textBold}>
      It is a legal requirement for dogs in England to be microchipped, and for
      their details to be registered on an authorised database.
    </Text>
    <Text style={styles.text}>
      Dog owners must also ensure that the information provided on the database
      is kept up-to-date.{' '}
    </Text>
    <Text style={styles.text}>
      Under the new microchipping laws - which have been in place since 6 April
      2016 - dog owners found by police not to have microchipped their dogs will
      be given a short period to comply.{' '}
    </Text>
    <Text style={styles.text}>
      After this period, a fine of up to £500 could be issued.
    </Text>
    <Text style={styles.subtitleBold}>The Control of Dogs Order 1992</Text>
    <Text style={styles.text}>
      In addition to microchipping, there are also rules when it comes to the
      use of collars and dog tags.
    </Text>
    <Text style={styles.textBold}>
      UK law requires pups to wear dog collars with identification tags in
      public places.
    </Text>
    <Text style={styles.text}>These dog tags must display: </Text>
    <ListItem>The name of the dog owner (initials and surname)</ListItem>
    <ListItem>
      An up-to-date address of the owner (house number and postcode)
    </ListItem>
    <Text style={styles.text}>
      Despite not being a legal requirement, it is also a good idea to include
      your phone number on the tag too.
    </Text>
    <Text style={styles.underlineHeading}>Public Access Rights</Text>
    <Text style={styles.subtitleBold}>Equality Act 2010</Text>
    <Link
      style={styles.text}
      href="http://www.legislation.gov.uk/ukpga/2010/15"
      target="_blank"
    >
      http://www.legislation.gov.uk/ukpga/2010/15
    </Link>
    <Text style={styles.text}>
      It is against the law for service providers to treat people with
      disabilities less favourably because of their disability, or because they
      have a guide or assistance dog with them.
    </Text>
    <Text style={styles.text}>
      Making “reasonable adjustments” might mean giving extra help, such as
      guiding someone to a restaurant table, or making some changes to the way
      you provide your services to make it easier for those with assistance dogs
      to use them. It certainly includes allowing guide dogs and assistance dogs
      into all public places with their owners.
    </Text>
    <Text style={styles.text}>
      Guide dog and assistance dog owners have important rights under the
      Equality Act 2010. The EA provides for disabled people to have the same
      right to services supplied by shops, banks, hotels, libraries, pubs, taxis
      and restaurants as everyone else.
    </Text>
    <Text style={styles.text}>
      According to ADI the definition of an Assistance Dog is as follows:
    </Text>
    <Text style={styles.text}>
      A generic term for a guide, hearing or service dog specifically trained to
      do more than one task to mitigate an individual’s disabilities. The
      presence of a dog for protection, personal defence, or comfort does not
      qualify that dog as an assistance dog.
    </Text>
    <Text style={styles.text}>
      Please visit https://pads.foundation/paws-for-access/ for more
      information.
    </Text>

    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

DogLawUK.propTypes = {
  styles: PropTypes.object,
}

export default DogLawUK
