/* eslint-disable react/forbid-component-props */
import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  PDFViewer,
  Page,
  Text,
  Document,
  StyleSheet,
  Link,
  Font,
  View,
} from '@react-pdf/renderer'
import { Container, Typography, Box, LinearProgress } from '@mui/material'
import { Link as NavigateLink } from 'react-router-dom'

import DogLawUK from './Pages/DogLawUK'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    fontFamily: 'Open Sans',
  },
  text: {
    fontSize: 14,
    fontFamily: 'Open Sans',
  },
})

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf',
      fontStyle: 'italic',
    },
  ],
})

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    lineHeight: 1.4,
  },
  title: {
    marginBottom: 10,
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  subtitleIndent: {
    fontSize: 18,
    margin: 12,
    marginLeft: 20,
  },
  text: {
    margin: 10,
    fontSize: 14,
    fontFamily: 'Open Sans',
  },
  link: {
    margin: 10,
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Open Sans',
  },
  italics: {
    margin: 10,
    fontSize: 14,
    fontFamily: 'Open Sans',
    fontStyle: 'italic',
  },
  textSuccess: {
    margin: 10,
    fontSize: 14,
    fontFamily: 'Open Sans',
    color: 'green',
  },
  textError: {
    margin: 10,
    fontSize: 14,
    fontFamily: 'Open Sans',
    color: 'red',
  },
  textBold: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  subtitleBold: {
    margin: 10,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  underlineHeading: {
    margin: 12,
    fontSize: 18,
    textDecoration: 'underline',
  },
  image: {
    marginVertical: 15,
  },
  imageWithBorder: {
    marginVertical: 15,
    border: '5px solid black',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  subheading: {
    marginTop: 5,
    fontSize: 12,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

const DogLaw = () => {
  const [loading, setLoading] = useState(true)

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Typography mb={6} variant="h4">
          Training Guide
        </Typography>
        <NavigateLink to="/members">{'< Back to Members Area'}</NavigateLink>
        <Box sx={{ marginTop: 5 }} height="1200px">
          {!!loading && <LinearProgress color="primary" />}
          <PDFViewer width="100%" height="100%" showToolbar={false}>
            <Document onRender={() => setLoading(false)}>
              <Page style={styles.body}>
                <Text style={styles.header} fixed>
                  PADs In Training Guide
                </Text>
                <Text style={styles.title}>Table of Contents</Text>
                <Link src="#learning-theory" style={styles.subtitle}>
                  7. UK Dog Law
                </Link>
                <Link src="#dogs-road" style={styles.subtitleIndent}>
                  Laws on driving with dogs and road accidents
                </Link>
                <Link src="#dogs-public" style={styles.subtitleIndent}>
                  Laws on walking with your dog in public places
                </Link>
                <Link src="#dogs-others" style={styles.subtitleIndent}>
                  Laws on keeping others safe around your dog
                </Link>
                <Link src="#stray" style={styles.subtitleIndent}>
                  Laws on stray dogs, breeding and buying puppies
                </Link>
                <Link src="#microchip" style={styles.subtitleIndent}>
                  Microchipping and dog tags, UK law
                </Link>
                <Link src="#access-rights" style={styles.subtitleIndent}>
                  Public Access Rights
                </Link>
              </Page>
              <DogLawUK styles={styles} />
            </Document>
          </PDFViewer>
        </Box>
      </Container>
    </section>
  )
}

export default DogLaw
