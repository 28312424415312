/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react'
import {
  Alert,
  Container,
  Typography,
  Snackbar,
  Drawer,
  Box,
  Toolbar,
  IconButton,
  Button,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { pdfjs } from 'react-pdf'

import { useAuth } from '../contexts/AuthContext'
import { ApplicationContext } from '../contexts/application/ApplicationContext'
import { updateUser } from '../data/user'
import { updateApplication } from '../data/application'
import { sendApprovedEmail } from '../data/email'
import { getStorage, ref, getDownloadURL, getMetadata } from 'firebase/storage'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

const ViewApplication = () => {
  const { currentUser, db, functions } = useAuth()
  const { application, fetchApplication } = useContext(ApplicationContext)
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState('')
  const [downloadUrl, setDownloadUrl] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const { id } = useParams()

  useEffect(() => {
    fetchApplication(id, db)
  }, [])

  const handleApproveApplication = async () => {
    setLoading(true)
    const update = await updateUser({ role: 'member' }, id, db)
    const updateApp = await updateApplication({ status: 'Complete' }, id, db)

    if (update && updateApp) {
      const emailSent = await sendApprovedEmail(functions, currentUser)
      if (emailSent) {
        setLoading(false)
        setAlert({
          open: true,
          message: 'Application approved! Email sent to applicant',
          type: 'success',
        })
      }
    } else {
      setAlert({
        open: true,
        message: `Error approving application, please try again`,
        type: 'error',
      })
    }
    setLoading(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const downloadFile = async (fileName, uid) => {
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${uid}/${fileName}`,
    )

    const metadata = await getMetadata(fileRef)
    setFileType(metadata.contentType)

    getDownloadURL(fileRef)
      .then((url) => {
        setDownloadUrl(url)
        setDrawerOpen(true)
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
      })
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.type}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(false)}
              edge="start"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          {fileType.includes('image') && (
            <Box
              component="img"
              sx={{
                width: '100%',
              }}
              src={downloadUrl}
            />
          )}
          {fileType.includes('pdf') && (
            <Box
              sx={{
                width: '95vw',
              }}
            >
              <iframe
                width="100%"
                height="900px"
                src={downloadUrl}
                title="W3Schools Free Online Web Tutorials"
              />
            </Box>
          )}
        </Drawer>
        <Container fixed sx={{ marginTop: '50px' }}>
          <Typography mb={6} variant="h4">
            View Application
          </Typography>
          <Link to="/admin/manage-applications">
            {'< Back to Manage Applications'}
          </Link>

          <Container
            component="main"
            maxWidth="md"
            sx={{ marginTop: 5, marginBottom: 5 }}
          >
            <div style={{ width: '100%' }}>
              <Typography variant="h4" mb={3}>
                Personal details
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Name:</strong> {application?.firstName}{' '}
                {application?.lastName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Email:</strong> {application?.email}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Phone:</strong> {application?.phone}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Address:</strong> {application?.address},{' '}
                {application?.address2}, {application?.city},{' '}
                {application?.county}, {application?.postcode}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>UK Citizen:</strong> {application?.citizen}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Photo (passport style):</strong>{' '}
                {!!application?.passportPhoto && (
                  <Button
                    onClick={() => downloadFile('passport', id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!application?.passportPhoto && 'Not uploaded'}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Proof of address:</strong>{' '}
                {!!application?.proofOfAddress && (
                  <Button
                    onClick={() => downloadFile('address', id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!application?.proofOfAddress && 'Not uploaded'}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Identity Doc:</strong>{' '}
                {!!application?.identityDoc && (
                  <Button
                    onClick={() => downloadFile('identity', id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!application?.identityDoc && 'Not uploaded'}
              </Typography>
              <Typography variant="h4" mb={3} mt={5}>
                Dog details
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Dog photo:</strong>{' '}
                {!!application?.dogPhoto && (
                  <Button
                    onClick={() => downloadFile('dogPhoto', id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!application?.dogPhoto && 'Not uploaded'}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Name:</strong> {application?.dogName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Age:</strong> {application?.dogAge}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Breed:</strong> {application?.dogBreed}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Weight:</strong> {application?.dogWeight}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Insurance company:</strong>{' '}
                {application?.microchipNumber}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Insurance company:</strong>{' '}
                {application?.insuranceCompany}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Insurance policy number:</strong>{' '}
                {application?.insurancePolicyNumber}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Insurance start date:</strong>{' '}
                {application?.insuranceStartDate}
              </Typography>

              <Typography variant="h4" mb={3} mt={5}>
                Veterinary Information
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Vet form:</strong>{' '}
                {!!application?.vetForm && (
                  <Button
                    onClick={() => downloadFile('vetForm', id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!application?.vetForm && 'Not uploaded'}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Name:</strong> {application?.dogName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Email:</strong> {application?.dogAge}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice Name:</strong> {application?.practiceName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice Address:</strong>{' '}
                {application?.practiceAddress}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice City:</strong> {application?.practiceCity}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice County:</strong> {application?.practiceCounty}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice Email:</strong> {application?.practiceEmail}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice Phone:</strong> {application?.practicePhone}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Vaccination date:</strong>{' '}
                {application?.vaccinationDate}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Last health check:</strong>{' '}
                {application?.lastHealthCheckDate}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Body score:</strong> {application?.bodyScore}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Is the dog spayed/neutered:</strong>{' '}
                {application?.neutered}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Is the dog pregnant:</strong>{' '}
                {application?.pregnant}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Is the dog brachycephalic or has the potential to suffer with
                  BOAS?
                </strong>{' '}
                {application?.boas}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Is the dog a toy breed or considered a toy breed?
                </strong>{' '}
                {application?.toyBreed}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Does this breed display extreme phenotypical conformation? For
                  example Shar Pei or Dachshund
                </strong>{' '}
                {application?.phenotypical}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Is the dog a banned breed or considered a banned breed?
                </strong>{' '}
                {application?.bannedBreed}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Is the dog free from signs of genetic or physical illness that would impact their ability to perform their job?
                </strong>{' '}
                {application?.illness}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  If no, is this a temporary problem or life long condition?
                </strong>{' '}
                {application?.illnessInfo}
              </Typography>

              <Typography variant="h4" mb={3} mt={5}>
                Medical Information
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Medical form:</strong>{' '}
                {!!application?.medicalForm && (
                  <Button
                    onClick={() => downloadFile('medicalForm', id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!application?.medicalForm && 'Not uploaded'}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Do you have any concerns with regard to you patients ability
                  to care for a dog in this way?
                </strong>{' '}
                {application?.concerns}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Is your patient able to groom themselves regularly, such as
                  daily showers and dental care?
                </strong>{' '}
                {application?.groom}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Does your patient become confused or &apos;lost&apos; in
                  public?
                </strong>{' '}
                {application?.public}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Does your patient forget to take medication? If so, what would
                  prevent them from remembering to feed or care for their dog?
                </strong>{' '}
                {application?.forget}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Does your patient experience severe changes in mood that may
                  prevent them from properly caring for a dog in a public
                  setting or at home?
                </strong>{' '}
                {application?.mood}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Has your patient ever been violent towards animals or people?
                </strong>{' '}
                {application?.violent}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Has your patient attempted suicide? If so, please explain
                </strong>{' '}
                {application?.suicide}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Please describe your patients current treatment plan and your
                  involvement in that plan
                </strong>{' '}
                {application?.treatmentPlan}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Do you believe that an assistance dog would assist your
                  patient, and if so, how?
                </strong>{' '}
                {application?.dogHelp}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Does your patient suffer from Dissociations? If your patient
                  suffers from Dissociative episodes they will need to have a
                  dog that is large/strong enough to prevent them from crossing
                  a road during a dissociation.
                </strong>{' '}
                {application?.dogSize}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>
                  Has your patient had their psychiatric diagnosis for more than
                  12 months?
                </strong>{' '}
                {application?.longDiagnosis}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Is your patient over 18 years old?</strong>{' '}
                {application?.overEighteen}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice/Healthcare Center name:</strong>{' '}
                {application?.medicalPracticeName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Doctor or Psychiatrist name:</strong>{' '}
                {application?.doctorName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Address:</strong> {application?.medicalPracticeAddress},{' '}
                {application?.medicalPracticeAddress},{' '}
                {application?.medicalPracticeCity},{' '}
                {application?.medicalPracticeCounty},{' '}
                {application?.medicalPracticePostcode}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Phone:</strong> {application?.medicalPracticePhone}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Email:</strong> {application?.medicalPracticeEmail}
              </Typography>

              <Typography variant="h4" mb={5}>
                Sponsor details
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Name:</strong> {application?.sponsorFirstName}{' '}
                {application?.sponsorLastName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Email:</strong> {application?.sponsorEmail}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Phone:</strong> {application?.sponsorPhone}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Address:</strong> {application?.sponsorAddress},{' '}
                {application?.sponsorAddress2}, {application?.sponsorCity},{' '}
                {application?.sponsorCounty}, {application?.sponsorPostcode}
              </Typography>

              <Typography variant="h4" mb={5}>
                Trainer Details
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Name:</strong> {application?.trainerName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Website:</strong> {application?.trainerBusinessName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Phone:</strong> {application?.trainerPhone}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Email:</strong> {application?.trainerEmail}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Location/City:</strong> {application?.trainerCity}
              </Typography>

              <LoadingButton
                loading={loading}
                type="button"
                variant="contained"
                color="success"
                disabled={application?.status !== 'Submitted'}
                onClick={handleApproveApplication}
                sx={{ marginTop: 5, marginBottom: 8, marginRight: 2 }}
              >
                Approve application
              </LoadingButton>
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ViewApplication
