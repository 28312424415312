import { createTheme } from '@mui/material/styles'
import '@fontsource/oswald/500.css'

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: 'Oswald',
      color: '#444444',
    },
    body1: {
      fontFamily: 'Muli,sans-serif',
      color: '#444444',
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#E58A3B',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#fff',
    },
    info: {
      // light: will be calculated from palette.primary.main,
      main: '#CCC',
    },
  },
})

export default theme
