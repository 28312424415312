import React from 'react'
import nextId from 'react-id-generator'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'

const CalendarBody = (props) => {
  const {
    firstDayOfMonth,
    daysInMonth,
    currentDay,
    currentMonth,
    currentMonthNum,
    selectedDay,
    activeDays,
    setSelectedDay,
    actualMonth,
    weekdays,
    currentYear,
    actualYear,
  } = props

  const blanks = []
  for (let i = 0; i < firstDayOfMonth(); i++) {
    blanks.push(<TableCell key={nextId()} />)
  }

  const monthDays = []
  for (let d = 1; d <= daysInMonth(); d++) {
    let currDay
    let selectDay
    let activeDay
    let trainer

    // Check if day is today
    if (
      currentDay() == d &&
      currentMonth() == actualMonth() &&
      currentYear() == actualYear
    ) {
      currDay = 'today'
    }

    // Check if day is selected day
    const zeroMonthValue = currentMonthNum().toString().padStart(2, '0')
    const zeroDayValue = d.toString().padStart(2, '0')
    if (selectedDay.day == d && currentMonthNum() == selectedDay.month) {
      selectDay = 'selected-day'
    }

    // Check if day found in this month active days
    const formattedDate = `${currentYear()}-${zeroMonthValue}-${zeroDayValue}`
    const active = activeDays.find((day) => day.date === formattedDate)
    if (active) {
      activeDay = 'active'
      if (
        activeDays.find(
          (day) => day.date === formattedDate && day.trainer === true,
        )
      ) {
        trainer = 'has-trainer'
      }
    }

    monthDays.push(
      <TableCell
        sx={{ minHeight: '100px' }}
        key={d}
        className={`week-day ${currDay} ${selectDay}`}
        onClick={() => setSelectedDay(d)}
      >
        <span
          style={{ minHeight: '30px', display: 'inline-block' }}
          className={`${activeDay} ${trainer}`}
        >
          {d}
        </span>
      </TableCell>,
    )
  }

  const totalSlots = [...blanks, ...monthDays]
  const rows = []
  let cells = []

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row)
    } else {
      rows.push(cells)
      cells = []
      cells.push(row)
    }
    if (i === totalSlots.length - 1) {
      rows.push(cells)
    }
  })

  return (
    <TableContainer component={Paper}>
      <Table className="calendar">
        <TableHead sx={{ display: { xs: 'none', sm: 'table-header-group' } }}>
          <TableRow>
            {weekdays.map((day, i) => (
              <TableCell key={i}>{day}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((day, i) => (
            <TableRow key={i}>{day}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CalendarBody
