import React from 'react'
import './Landing.css'
import { Button, Grid, Typography } from '@mui/material'

const Landing = () => {
  return (
    <div>
      <Grid
        className="landing"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="h3" sx={{ p: 3 }}>
          Test your knowledge!
        </Typography>
        <Typography variant="h4" sx={{ color: '#FFF', pb: 3 }}>
          Check out these review quizzes!
        </Typography>
        <nav className="nav-bar">
          <a href="/admin/member-quiz/module1">
            <Button variant="contained" sx={{ mr: 2 }}>
              Module 1 Quiz
            </Button>
          </a>
          <a href="/admin/member-quiz/module2">
            <Button variant="contained" sx={{ mr: 2 }}>
              Module 2 Quiz
            </Button>
          </a>
          <a href="/admin/member-quiz/module3">
            <Button variant="contained">Module 3 Quiz</Button>
          </a>
        </nav>
      </Grid>
    </div>
  )
}

export default Landing
