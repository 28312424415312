/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  updateEmail,
  sendEmailVerification,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { createUser, getUser } from '../data/user'

const firebaseConfig = {
  apiKey: 'AIzaSyBo7vCk0v9XnuXun5lXWaxQqnQfTMsMAKU',
  authDomain: 'pads-foundation.firebaseapp.com',
  projectId: 'pads-foundation',
  storageBucket: 'pads-foundation.appspot.com',
  messagingSenderId: '58420198680',
  appId: '1:58420198680:web:0bb04036c2ec162afa326d',
  measurementId: 'G-LN4HJ6ZDMP',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const functions = getFunctions(app, 'europe-west2')

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const loggedInUser = await getUser(user.uid, db)
        setCurrentUser({
          ...loggedInUser,
          ...user,
        })
      }
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const login = async (email, password) => {
    const result = await signInWithEmailAndPassword(auth, email, password)
    if (result.user) window.location.replace('/application')
  }

  const logOut = async () => {
    await signOut(auth)
    window.location.replace('/login')
  }

  const signUp = async (data) => {
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      )
      const user = res.user

      if (user) {
        const newUser = await createUser(data, user.uid, db)

        const actionCodeSettings = {
          url: encodeURI(`${window.location.origin}/login`),
          handleCodeInApp: true,
        }

        await sendEmailVerification(user, actionCodeSettings)
        if (newUser) window.location.replace('/verify-email')
      }

      if (!user) throw Error('Error creating user. Please try again later')
    } catch (err) {
      console.error(err)
      if (err.message.includes('email-already-in-use')) {
        throw Error(
          'Error creating account. This email address is already in use',
        )
      } else {
        throw Error('Error creating account. Please try again later')
      }
    }
  }

  const sendVerificationEmail = async (data) => {
    try {
      await sendEmailVerification(currentUser)
    } catch (err) {
      console.error(err)
    }
  }

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email)
      return true
    } catch (err) {
      console.error(err)
      throw Error('Error resetting password - please try again')
    }
  }

  const updateAuthPassword = async (password) => {
    try {
      await updatePassword(auth, password)
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  const updateAuthEmail = async (email) => {
    try {
      await updateEmail(auth, email)
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  const fetchUser = async () => {
    const loggedInUser = await getUser(currentUser.uid, db)
    setCurrentUser({ ...currentUser, ...loggedInUser, uid: currentUser.uid })
  }

  const value = {
    currentUser,
    getUser,
    login,
    logOut,
    signUp,
    sendPasswordReset,
    functions,
    db,
    updateAuthPassword,
    updateAuthEmail,
    fetchUser,
    sendVerificationEmail,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
