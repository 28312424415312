import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import {
  TextField,
  Button,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Typography,
  Box,
  Alert,
  Snackbar,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { LoadingButton } from '@mui/lab'

import { useAuth } from '../../contexts/AuthContext'
import { veterinaryInfoRequired } from '../../utils/requiredFields'

const Vet = ({ application, goBack }) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useFormContext()
  const { currentUser } = useAuth()

  const [vetForm, setVetForm] = useState('')
  const [vetDetails, setVetDetails] = useState(application)
  const [percent, setPercent] = useState(0)
  const [startedForm, setStartedForm] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    setVetDetails(application)
    const s = veterinaryInfoRequired.every(
      (x) =>
        application && (application[x.field] === '' || !application[x.field]),
    )

    const fields = []
    veterinaryInfoRequired.forEach((input) => {
      if (
        application &&
        input.field &&
        (application[input.field] === '' || !application[input.field])
      ) {
        fields.push(input.label)
      }
    })
    setMissingFields(fields)
    setStartedForm(!s)
  }, [application])

  const storage = getStorage()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const downloadFile = (fileName, uid) => {
    setDownloading(true)
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${uid}/${fileName}`,
    )

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = `${fileName}-${uid}`
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  const uploadToFirebase = (file, fileName) => {
    const storageRef = ref(storage, `/files/${currentUser.uid}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setPercent(currentPercent)
      },
      (err) => console.error(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setVetForm(url)
          setValue('vetForm', url)
        })
      },
    )
  }

  return (
    <>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Veterinary Information
      </Typography>
      {!!startedForm && missingFields.length > 0 && (
        <Alert severity="warning" sx={{ marginBottom: 4 }}>
          The following fields have yet to be completed:
          {missingFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Alert>
      )}
      <Typography variant="body1" mt={5}>
        <strong>Please upload a copy of your vetinary form:</strong>
      </Typography>
      <FormGroup>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            marginTop: '10px',
            textTransform: 'none',
            width: '150px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*,file/*,application/pdf"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'vetForm')}
          />
          <input
            {...register('vetForm')}
            type="text"
            hidden
            defaultValue={vetDetails?.vetForm || ''}
          />
        </Button>
        {percent > 0 && percent < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!vetForm || !!vetDetails?.vetForm) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '200px',
              margin: 0,
              marginTop: '5px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('vetForm', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}
        <Typography variant="body1" mt={5}>
          <strong>Please provide name and contact details of your vet</strong>
        </Typography>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.vetName}
              id="vetName"
              label="Vet Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="vetName"
          defaultValue={vetDetails?.vetName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceName}
              id="practiceName"
              label="Practice Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceName"
          defaultValue={vetDetails?.practiceName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceAddress}
              id="practiceAddress"
              label="Pratice Address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceAddress"
          defaultValue={vetDetails?.practiceAddress || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceCity}
              id="practiceCity"
              label="City"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceCity"
          defaultValue={vetDetails?.practiceCity || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceCounty}
              id="practiceCounty"
              label="County"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceCounty"
          defaultValue={vetDetails?.practiceCounty || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practicePostcode}
              id="practicePostcode"
              label="Post code"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="practicePostcode"
          defaultValue={vetDetails?.practicePostcode || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practiceEmail}
              id="practiceEmail"
              label="Email address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="practiceEmail"
          defaultValue={vetDetails?.practiceEmail || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.practicePhone}
              id="practicePhone"
              label="Phone"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="practicePhone"
          defaultValue={vetDetails?.practicePhone || ''}
        />

        <Typography variant="body1" mt={5}>
          <strong>Dog medical information</strong>
        </Typography>

        <Box mt={3}>
          <FormLabel id="vaccinationDate">Date of last vaccination</FormLabel>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register('vaccinationDate')}
              format="DD-MM-YYYY"
              defaultValue={
                vetDetails?.vaccinationDate
                  ? dayjs(vetDetails?.vaccinationDate)
                  : ''
              }
              onChange={(value) =>
                setValue('vaccinationDate', dayjs(value).format('DD-MM-YYYY'))
              }
            />
          </LocalizationProvider>
        </Box>

        <Box mt={3}>
          <FormLabel id="lastHealthCheckDate">
            Date of last health check
          </FormLabel>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register('lastHealthCheckDate')}
              format="DD-MM-YYYY"
              defaultValue={
                vetDetails?.lastHealthCheckDate
                  ? dayjs(vetDetails?.lastHealthCheckDate)
                  : ''
              }
              onChange={(value) =>
                setValue(
                  'lastHealthCheckDate',
                  dayjs(value).format('DD-MM-YYYY'),
                )
              }
            />
          </LocalizationProvider>
        </Box>

        <FormLabel sx={{ marginTop: 3 }} id="bodyScore">
          Body condition score
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="bodyScore"
              name="bodyScore"
            >
              <FormControlLabel value="1to3" control={<Radio />} label="1-3" />
              <FormControlLabel
                value="4or5"
                control={<Radio />}
                label="4 or 5"
              />
              <FormControlLabel value="6" control={<Radio />} label="6" />
              <FormControlLabel value="7to9" control={<Radio />} label="7-9" />
            </RadioGroup>
          )}
          control={control}
          name="bodyScore"
          defaultValue={vetDetails?.concerns || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="neutered">
          Is the dog spayed/neutered (if over 1 year old)
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="neutered"
              name="neutered"
            >
              <FormControlLabel value="na" control={<Radio />} label="N/A" />
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="neutered"
          defaultValue={vetDetails?.neutered || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="pregnant">
          Is the dog pregnant?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="pregnant"
              name="pregnant"
            >
              <FormControlLabel value="na" control={<Radio />} label="N/A" />
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="pregnant"
          defaultValue={vetDetails?.pregnant || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="boas">
          Please read page 3 before answering this section.
        </FormLabel>
        <FormLabel sx={{ marginTop: 0 }} id="boas">
          Is this breed classified as a Brachycephalic breed? (Please check
          online)
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="boas" name="boas">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel
                value="undetermined"
                control={<Radio />}
                label="Undetermined"
              />
            </RadioGroup>
          )}
          control={control}
          name="boas"
          defaultValue={vetDetails?.boas || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="toyBreed">
          Is the dog a toy breed or considered a toy breed? (Please check
          online)
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="toyBreed"
              name="toyBreed"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="toyBreed"
          defaultValue={vetDetails?.toyBreed || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="phenotypical">
          Does this breed display extreme phenotypical conformation? For example
          Shar Pei or Dachshund?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="phenotypical"
              name="phenotypical"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="phenotypical"
          defaultValue={vetDetails?.phenotypical || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="bannedBreed">
          Is the dog a banned breed or considered a banned breed?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="bannedBreed"
              name="bannedBreed"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="bannedBreed"
          defaultValue={vetDetails?.bannedBreed || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="illness">
          Is the dog free from signs of genetic or physical illness that would
          impact their ability to perform their job? If no, is this a temporary
          problem or life long condition?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="illness" name="illness">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="illness"
          defaultValue={vetDetails?.illness || ''}
        />
        {watch('illness') === 'no' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.illnessInfo}
                id="illnessInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="illnessInfo"
            defaultValue={vetDetails?.illnessInfo || ''}
          />
        )}
      </FormGroup>

      <Button
        variant="contained"
        color="info"
        type="button"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
        onClick={() => goBack()}
      >
        Back
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Save and continue
      </Button>
      <span>Next: Your Sponsor</span>
    </>
  )
}

Vet.propTypes = {
  application: PropTypes.object,
  goBack: PropTypes.func,
}

export default Vet
