/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react'
import {
  Alert,
  Container,
  Typography,
  Snackbar,
  Drawer,
  Box,
  Toolbar,
  IconButton,
  Button,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { pdfjs } from 'react-pdf'

import { useAuth } from '../contexts/AuthContext'
import { ApplicationContext } from '../contexts/application/ApplicationContext'
import { updateUser } from '../data/user'
import { updateApplication } from '../data/application'
import { sendApprovedEmail } from '../data/email'
import { getStorage, ref, getDownloadURL, getMetadata } from 'firebase/storage'
import { getRenewalById } from '../data/renewals'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

const ViewRenewal = () => {
  const { currentUser, db, functions } = useAuth()

  const [renewal, setRenewal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState('')
  const [downloadUrl, setDownloadUrl] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const { id } = useParams()

  useEffect(() => {
    getRenewal()
  }, [])

  const getRenewal = async () => {
    const renewal = await getRenewalById(db, id)
    setRenewal(renewal)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const downloadFile = async (fileName, userId, uid) => {
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${userId}/${uid}/${fileName}`,
    )

    const metadata = await getMetadata(fileRef)
    setFileType(metadata.contentType)

    getDownloadURL(fileRef)
      .then((url) => {
        setDownloadUrl(url)
        setDrawerOpen(true)
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
      })
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.type}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(false)}
              edge="start"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          {fileType.includes('image') && (
            <Box
              component="img"
              sx={{
                width: '100%',
              }}
              src={downloadUrl}
            />
          )}
          {fileType.includes('pdf') && (
            <Box
              sx={{
                width: '95vw',
              }}
            >
              <iframe
                width="100%"
                height="900px"
                src={downloadUrl}
                title="W3Schools Free Online Web Tutorials"
              />
            </Box>
          )}
        </Drawer>
        <Container fixed sx={{ marginTop: '50px' }}>
          <Typography mb={6} variant="h4">
            View Renewal
          </Typography>
          <Link to={`/application/renewals/${renewal?.userId}`}>
            {'< Back to Renewals List'}
          </Link>

          <Container
            component="main"
            maxWidth="md"
            sx={{ marginTop: 5, marginBottom: 5 }}
          >
            <div style={{ width: '100%' }}>
              <Typography variant="body1" mb={2}>
                <strong>Annual vet form:</strong>{' '}
                {!!renewal?.vetForm && (
                  <Button
                    onClick={() => downloadFile('annualVetForm', renewal?.userId, renewal?.id)}
                    type="button"
                  >
                    View here
                  </Button>
                )}
                {!renewal?.vetForm && 'Not uploaded'}
              </Typography>
              <Typography variant="h4" mb={3}>
                About you (handler) and your dog
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Handler name:</strong> {renewal?.handlerName}{' '}
                {renewal?.handlerLastName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Dog name:</strong> {renewal?.dogName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Breed:</strong> {renewal?.dogBreed}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Dog date of birth:</strong> {renewal?.dogDob}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Sex of dog:</strong> {renewal?.sex}
              </Typography>

              <Typography variant="h4" mb={3}>
                Vet details
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Vet name:</strong> {renewal?.vetName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice name:</strong> {renewal?.practiceName}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Practice address:</strong>  {renewal?.practiceAddress},{' '}
                {renewal?.practiceCity},{' '}
                {renewal?.practiceCounty}, {renewal?.practicePostcode}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Email:</strong> {renewal?.practiceEmail}
              </Typography>

              <Typography variant="h4" mb={3}>
                Dog medical information
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Microchip number:</strong> {renewal?.microchipNumber}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Weight:</strong> {renewal?.dogWeight}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Date of last vaccination:</strong>  {renewal?.vaccinationDate}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Date of last health check:</strong> {renewal?.lastHealthCheckDate}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Body condition score:</strong> {renewal?.bodyScore}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Is the dog spayed/neutered:</strong> {renewal?.neutered}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Is this breed classified as a Brachycephalic breed?</strong> {renewal?.boas}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Checking for senility (for dogs 7 years or older)</strong> {renewal?.senility}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>If yes, details:</strong> {renewal?.senilityInfo}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Eye and sight check:</strong> {renewal?.sight}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Ear and hearing check:</strong> {renewal?.hearing}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Joint and mobility check:</strong> {renewal?.joints}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Dental/oral check:</strong> {renewal?.dental}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Pain check:</strong> {renewal?.pain}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Behaviour check during assessment:</strong> {renewal?.behaviour}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Skin check - there is no atopy (allergy) development:</strong> {renewal?.skin}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Heart check - abnormal rhythms or sounds detected:</strong> {renewal?.heart}
              </Typography>
              <Typography variant="body1" mb={2}>
                <strong>Comments:</strong> {renewal?.comments}
              </Typography>
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ViewRenewal
