export default (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA':
      return {
        ...state,
        isLoading: true,
      }
    case 'RENEWAL_DATA_SUCCESS':
      return {
        ...state,
        renewals: action.payload,
        isLoading: false,
        errorMessage: undefined,
      }
    case 'RENEWAL_DATA_ERROR':
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
