/* eslint-disable react/forbid-component-props */
import React from 'react'
import { Box } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

const Footer = () => {
  return (
    <Box
      className="footer"
      component="footer"
      sx={{
        width: '100%',
      }}
    >
      <div id="footer">
        <div id="footer_box" className="darkbox">
          <div className="container footer-cols">
            <div className="row">
              <div className="col-lg-4 col-sm-6 footer1">
                <aside id="block-3" className="widget widget_block">
                  <h2>Contact</h2>
                </aside>
                <aside id="block-5" className="widget widget_block widget_text">
                  <p>Email: info@pads.foundation</p>
                </aside>
                <aside
                  id="block-18"
                  className="widget widget_block widget_text"
                >
                  <p>
                    Telephone: 07852212320 <br />
                    (Mon - Fri 11:00 - 16:00)
                  </p>
                </aside>
                <aside
                  id="block-17"
                  className="widget widget_block widget_text"
                >
                  <p />
                </aside>
              </div>
              <Box
                className="col-lg-4 col-sm-6 footer3"
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                <aside id="block-9" className="widget widget_block">
                  <h2>Social Media</h2>
                </aside>
                <aside id="block-10" className="widget widget_block">
                  <ul className="wp-block-social-links alignleft">
                    <li
                      className="wp-social-link wp-social-link-facebook
                    wp-block-social-link"
                    >
                      <a
                        href="https://www.facebook.com/padsfoundation/"
                        aria-label="Facebook: https://www.facebook.com/padsfoundation/"
                        className="wp-block-social-link-anchor"
                      >
                        <FacebookIcon />
                      </a>
                    </li>

                    <li
                      className="wp-social-link wp-social-link-instagram
                    wp-block-social-link"
                    >
                      <a
                        href="https://www.instagram.com/padsfoundation/"
                        aria-label="Instagram: https://www.instagram.com/padsfoundation/"
                        className="wp-block-social-link-anchor"
                      >
                        <InstagramIcon />
                      </a>
                    </li>
                  </ul>
                </aside>
              </Box>
              <div className="col-lg-4 col-sm-6">
                <aside id="block-11" className="widget widget_block">
                  <h2>Info</h2>
                </aside>
                <aside
                  id="block-13"
                  className="widget widget_block widget_text"
                >
                  <p>Registered Charity Number: 1173512</p>
                </aside>
                <aside
                  id="block-14"
                  className="widget widget_block widget_text"
                >
                  <p>
                    <a
                      className="font-orange"
                      href="https://www.kualo.com/free-charity-hosting"
                    >
                      Free Charity Hosting by Kualo
                    </a>
                  </p>
                </aside>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="row copy-text">
                <div className="col-lg-12 col-md-12">
                  <p>
                    Copyright © 2021 Psychiatric Assistance Dogs Foundation. All
                    rights reserved.{' '}
                    <span className="credit_link">
                      Design &amp; Developed by{' '}
                      <a
                        href="https://www.themagnifico.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Themagnifico
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default Footer
