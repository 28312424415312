import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm } from 'react-hook-form'

import logo from '../images/pads-logo-250.png'
import { useAuth } from '../contexts/AuthContext'

const label = (
  <span>
    I have read and agree to Psychiatric Assistance Dogs Foundation&apos;s{' '}
    <a
      href="https://pads.foundation/service-user-terms-conditions/"
      rel="noreferrer"
      target="_blank"
      style={{ zIndex: 5 }}
    >
      Terms and Conditions
    </a>
  </span>
)

const Signup = () => {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm()

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { signUp } = useAuth()

  const onSubmit = async (data) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await signUp(data)
    } catch (error) {
      setLoading(false)
      const errorMessage = error.message
      setErrorMessage(errorMessage)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" />
        <Box sx={{ mt: 3 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="First Name *"
                  autoFocus
                  {...register('firstName', { required: true })}
                />
                {errors?.firstName?.type === 'required' && (
                  <Typography variant="caption" color="error">
                    Please enter your first name
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name *"
                  name="lastName"
                  autoComplete="family-name"
                  {...register('lastName', { required: true })}
                />
                {errors?.lastName?.type === 'required' && (
                  <Typography variant="caption" color="error">
                    Please enter your last name
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address *"
                  name="email"
                  autoComplete="email"
                  {...register('email', {
                    required: true,
                    pattern:
                      // eslint-disable-next-line max-len
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                {errors?.email?.type === 'required' && (
                  <Typography variant="caption" color="error">
                    Please enter your email address
                  </Typography>
                )}
                {errors?.email?.type === 'pattern' && (
                  <Typography variant="caption" color="error">
                    Please enter a valid email address
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password *"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  {...register('password', {
                    required: true,
                    pattern:
                      // eslint-disable-next-line max-len
                      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{16,}$/,
                  })}
                />
                {errors?.password?.type === 'required' && (
                  <Typography variant="caption" color="error">
                    Please enter a password
                  </Typography>
                )}
                {errors?.password?.type === 'pattern' && (
                  <Typography variant="caption" color="error">
                    The password must be at least 16 characters and contain at
                    least one lowercase character, one uppercase character, one
                    number, and one special character
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password *"
                  type="password"
                  id="confirm-password"
                  autoComplete="new-password"
                  {...register('confirmPassword', {
                    required: true,
                    validate: (val) => watch('password') === val,
                  })}
                />
                {watch('confirmPassword') !== watch('password') &&
                  getValues('confirmPassword') && (
                    <Typography variant="caption" color="error">
                      Your passwords do not match
                    </Typography>
                  )}
                {errors?.confirmPassword?.type === 'required' && (
                  <Typography variant="caption" color="error">
                    Please confirm your password
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={label}
                  {...register('terms', {
                    required: true,
                  })}
                />
                {errors?.terms?.type === 'required' && (
                  <Typography variant="caption" color="error">
                    You must accept the Terms & Conditions
                  </Typography>
                )}
              </Grid>
            </Grid>
            {!!errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </LoadingButton>
          </form>
          <Grid mb={5} container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default Signup
