import React, { useState, useEffect } from 'react'
import { Alert, Container, Typography, Stack } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { LoadingButton } from '@mui/lab'
import { DataGrid } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import { Link } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import { listUsers, deleteUser } from '../data/user'

const UserTable = () => {
  const { currentUser, db } = useAuth()
  const [loadingList, setLoadingList] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [userList, setUserList] = useState([])

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    getUserList()
  })

  const columns = [
    { field: 'firstName', headerName: 'First name', flex: 0.5 },
    { field: 'lastName', headerName: 'Last name', flex: 0.5 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'action',
      width: 100,
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {
        const currentRow = params.row
        return (
          <Stack direction="row" spacing={2}>
            <LoadingButton
              loading={deleting}
              variant="contained"
              color="error"
              size="small"
              onClick={() => removeUser(currentRow.id)}
            >
              Delete
            </LoadingButton>
          </Stack>
        )
      },
    },
  ]

  const removeUser = async (userId) => {
    setDeleting(true)
    if (
      window.confirm(
        'Are you sure you want to delete this user? Deleting this ' +
          'user will also delete this users application and data',
      )
    ) {
      const deleted = await deleteUser(userId, db)
      if (deleted) {
        setDeleting(false)
        setAlert({
          open: true,
          message: `Successfully deleted user`,
          type: 'success',
        })
        getUserList()
      }

      if (!deleted) {
        setDeleting(false)
        setAlert({
          open: true,
          message: `Error deleting user, please try again`,
          type: 'error',
        })
      }
    }
    setDeleting(false)
  }

  const getUserList = async () => {
    const userList = await listUsers(db)
    if (userList) setUserList(userList)
    setLoadingList(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px' }}>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={alert.open}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Manage Users
          </Typography>
          <Link to="/admin/dashboard">{'< Back to Admin Dashboard'}</Link>

          <Container
            component="main"
            maxWidth="md"
            sx={{ marginTop: 5, marginBottom: 5 }}
          >
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                checkboxSelection={false}
                loading={loadingList}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                rows={userList}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
              />
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default UserTable
