import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import {
  TextField,
  Button,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Typography,
  Alert,
  Snackbar,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { medicalRequired } from '../../utils/requiredFields'
import { useAuth } from '../../contexts/AuthContext'

const Medical = ({ application, goBack }) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useFormContext()
  const { currentUser } = useAuth()

  const [medicalForm, setMedicalForm] = useState('')
  const [medicalDetails, setMedicalDetails] = useState(application)
  const [percent, setPercent] = useState(0)
  const [startedForm, setStartedForm] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    setMedicalDetails(application)
    const s = medicalRequired.every(
      (x) =>
        application && (application[x.field] === '' || !application[x.field]),
    )

    const fields = []
    medicalRequired.forEach((input) => {
      if (
        application &&
        input.field &&
        (application[input.field] === '' || !application[input.field])
      ) {
        fields.push(input.label)
      }
    })
    setMissingFields(fields)
    setStartedForm(!s)
  }, [application])

  const storage = getStorage()

  const uploadToFirebase = (file, fileName) => {
    const storageRef = ref(storage, `/files/${currentUser.uid}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setPercent(currentPercent)
      },
      (err) => console.error(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setMedicalForm(url)
          setValue('medicalForm', url)
        })
      },
    )
  }

  const downloadFile = (fileName, uid) => {
    setDownloading(true)
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${uid}/${fileName}`,
    )

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = `${fileName}-${uid}`
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  return (
    <>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Medical Information
      </Typography>
      {!!startedForm && missingFields.length > 0 && (
        <Alert severity="warning" sx={{ marginBottom: 4 }}>
          The following fields have yet to be completed:
          {missingFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Alert>
      )}
      <Typography variant="body1" mt={5}>
        <strong>Please upload a copy of your medical form:</strong>
      </Typography>
      <Typography variant="body1">
        THIS FORM IS TO BE COMPLETED BY YOUR DOCTOR. THE INFORMATION ON THIS
        FORM MUST MATCH WITH THE DATA YOU PROVIDE BELOW
      </Typography>
      <FormGroup>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            marginTop: '10px',
            textTransform: 'none',
            width: '150px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*,file/*,application/pdf"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'medicalForm')}
          />
          <input
            {...register('medicalForm')}
            type="text"
            hidden
            defaultValue={medicalDetails?.medicalForm || ''}
          />
        </Button>
        {percent > 0 && percent < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!medicalForm || !!medicalDetails?.medicalForm) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '200px',
              margin: 0,
              marginTop: '5px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('medical', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}
        <Typography variant="body1" mt={5} sx={{ textDecoration: 'underline' }}>
          <strong>Recommendation for a Psychiatric Assistance Dog</strong>
        </Typography>
        <Typography variant="body1" mt={2}>
          <strong>
            Your patient has stated that you are in possession of a signed
            medical release enabling you to discuss your patient’s medical
            history with us.
          </strong>
        </Typography>
        <Typography variant="body1" mt={2}>
          Your patient is in the application process for membership of
          Psychiatric Assistance Dogs Foundation. In many cases these dogs can
          provide life-changing assistance for their disabled recipients, but
          this is always on a case-by-case basis. Many applicants are not in the
          position to properly care for an assistance dog, or even to be
          assisted by such a dog. In some cases an assistance dog can exacerbate
          their daily struggles, and they are better suited with other
          treatments instead.
        </Typography>
        <Typography variant="body1" mt={2}>
          Please note that your patient will need to be able to work in
          conjunction with their dog trainer as they are owner training their
          dog with the assistance of PADs Foundation.
        </Typography>
        <Typography variant="body1" mt={2} mb={3}>
          Your response will help us determine if this is the right choice for
          your patient. Many applicants are excited about the possibilities of
          how a dog might be able to assist them in the future, however, many do
          not understand the amount of care an assistance dog requires. For
          instance monthly medical treatment for preventative care as well as a
          premium quality dog food which can cost the applicant £80.00 or more
          per month just for maintenance. The dog will also need to be groomed
          daily, receive meals on time, and be taken outside to toilet several
          times daily.
        </Typography>

        <FormLabel sx={{ marginTop: 3 }} id="concerns">
          Do you have any concerns with regard to you patients ability to care
          for a dog in this way?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="concerns"
              name="concerns"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="concerns"
          defaultValue={medicalDetails?.concerns || ''}
        />
        {watch('concerns') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.concernsInfo}
                id="concernsInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="concernsInfo"
            defaultValue={medicalDetails?.concernsInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="groom">
          Is your patient able to groom themselves regularly, such as daily
          showers and dental care?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="groom" name="groom">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="groom"
          defaultValue={medicalDetails?.groom || ''}
        />
        {watch('groom') === 'no' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.groomInfo}
                id="groomInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="groomInfo"
            defaultValue={medicalDetails?.groomInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="public">
          Does your patient become confused or &apos;lost&apos; in public?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="public" name="public">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="public"
          defaultValue={medicalDetails?.public || ''}
        />
        {watch('public') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.publicInfo}
                id="publicInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="publicInfo"
            defaultValue={medicalDetails?.publicInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="forget">
          Does your patient forget to take medication? If so, what would prevent
          them from remembering to feed or care for their dog?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="forget" name="forget">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="forget"
          defaultValue={medicalDetails?.forget || ''}
        />
        {watch('forget') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.forgetInfo}
                id="forgetInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="forgetInfo"
            defaultValue={medicalDetails?.forgetInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="mood">
          Does your patient experience severe changes in mood that may prevent
          them from properly caring for a dog in a public setting or at home?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="mood" name="mood">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="mood"
          defaultValue={medicalDetails?.mood || ''}
        />
        {watch('mood') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.moodInfo}
                id="moodInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="moodInfo"
            defaultValue={medicalDetails?.moodInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="violent">
          Has your patient ever been violent towards animals or people?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="violent" name="violent">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="violent"
          defaultValue={medicalDetails?.violent || ''}
        />
        {watch('violent') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.violentInfo}
                id="violentInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="violentInfo"
            defaultValue={medicalDetails?.violentInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="suicide">
          Has your patient attempted suicide? If so, please explain
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="suicide" name="suicide">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="suicide"
          defaultValue={medicalDetails?.suicide || ''}
        />
        {watch('suicide') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.suicideInfo}
                id="suicideInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="suicideInfo"
            defaultValue={medicalDetails?.suicideInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="treatmentPlan">
          Please describe your patients current treatment plan and your
          involvement in that plan
        </FormLabel>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={5}
              error={!!errors.treatmentPlan}
              name="treatmentPlan"
            />
          )}
          control={control}
          name="treatmentPlan"
          defaultValue={medicalDetails?.treatmentPlan || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="dogHelp">
          Do you believe that an assistance dog would assist your patient, and
          if so, how?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="dogHelp" name="dogHelp">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="dogHelp"
          defaultValue={medicalDetails?.dogHelp || ''}
        />
        {watch('dogHelp') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.dogHelpInfo}
                id="dogHelpInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="dogHelpInfo"
            defaultValue={medicalDetails?.dogHelpInfo || ''}
          />
        )}

        <FormLabel sx={{ marginTop: 3 }} id="dogSize">
          Does your patient suffer from Dissociations? If your patient suffers
          from Dissociative episodes they will need to have a dog that is
          large/strong enough to prevent them from crossing a road during a
          dissociation.
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="dogSize" name="dogSize">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="dogSize"
          defaultValue={medicalDetails?.dogSize || ''}
        />
        {watch('dogSize') === 'yes' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.dogSizeInfo}
                id="dogSizeInfo"
                label="Please provide details..."
              />
            )}
            control={control}
            name="dogSizeInfo"
            defaultValue={medicalDetails?.dogSizeInfo || ''}
          />
        )}
        <FormLabel sx={{ marginTop: 3 }} id="longDiagnosis">
          Has your patient had their psychiatric diagnosis for more than 12
          months?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="longDiagnosis"
              name="longDiagnosis"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="longDiagnosis"
          defaultValue={medicalDetails?.longDiagnosis || ''}
        />
        <FormLabel sx={{ marginTop: 3 }} id="overEighteen">
          Is your patient over 18 years old?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="overEighteen"
              name="overEighteen"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="overEighteen"
          defaultValue={medicalDetails?.overEighteen || ''}
        />

        <Typography variant="body1" mb={5}>
          <strong>We may need to contact you to verify this information</strong>
        </Typography>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticeName}
              id="medicalPracticeName"
              label="Practice/Healthcare center name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="medicalPracticeName"
          defaultValue={medicalDetails?.medicalPracticeName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.doctorName}
              id="doctorName"
              label="Doctor or Psychiatrist name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="doctorName"
          defaultValue={medicalDetails?.doctorName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticeAddress}
              id="medicalPracticeAddress"
              label="Address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="medicalPracticeAddress"
          defaultValue={medicalDetails?.medicalPracticeAddress || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticeCity}
              id="medicalPracticeCity"
              label="City"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="medicalPracticeCity"
          defaultValue={medicalDetails?.medicalPracticeCity || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticeCounty}
              id="medicalPracticeCounty"
              label="County"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="medicalPracticeCounty"
          defaultValue={medicalDetails?.medicalPracticeCounty || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticePostcode}
              id="medicalPracticePostcode"
              label="Post code"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="medicalPracticePostcode"
          defaultValue={medicalDetails?.medicalPracticePostcode || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticePhone}
              id="medicalPracticePhone"
              label="Healthcare center phone number"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="medicalPracticePhone"
          defaultValue={medicalDetails?.medicalPracticePhone || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.medicalPracticeEmail}
              id="medicalPracticeEmail"
              label="Healthcare Email address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="medicalPracticeEmail"
          defaultValue={medicalDetails?.medicalPracticeEmail || ''}
        />
      </FormGroup>

      <Button
        variant="contained"
        color="info"
        type="button"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
        onClick={() => goBack()}
      >
        Back
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Save and continue
      </Button>
      <span>Next: Dog Details</span>
    </>
  )
}

Medical.propTypes = {
  application: PropTypes.object,
  goBack: PropTypes.func,
}

export default Medical
