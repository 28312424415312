import React, { useEffect, useState } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { Alert, Container, Typography, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import DownloadIcon from '@mui/icons-material/Download'
import Snackbar from '@mui/material/Snackbar'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import { updateUser } from '../data/user'
import ApplicationProgress from '../components/ApplicationProgess'
import { getCurrentRenewal } from '../data/renewals'
import dayjs from 'dayjs'

const label = (
  <span>
    I have read the{' '}
    <a
      href="https://pads.foundation/privacy-policy/"
      rel="noreferrer"
      target="_blank"
      style={{ zIndex: 5 }}
    >
      Data Privacy Policy
    </a>
  </span>
)

const Home = () => {
  const { currentUser, db, fetchUser } = useAuth()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [consent, setConsent] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [renewal, setRenewal] = useState(null)

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    getRenewal(currentUser.uid)
  }, [])

  const getRenewal = async () => {
    const renewal = await getCurrentRenewal(db, currentUser.uid)
    setRenewal(renewal)
  }

  const downloadFile = (type) => {
    setDownloading(true)
    const storage = getStorage()
    let fileRef = ref(
      storage,
      'gs://pads-foundation.appspot.com/forms/PADS vet form.pdf',
    )

    if (type === 'medical') {
      fileRef = ref(
        storage,
        'gs://pads-foundation.appspot.com/forms/PADS psychiatrist form.pdf',
      )
    }

    if (type === 'annualVet') {
      fileRef = ref(
        storage,
        'gs://pads-foundation.appspot.com/forms/PADS Annual Registration Vet Form.pdf',
      )
    }

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = 'PADs psychiatrist form.pdf'
          if (type === 'vet') a.download = 'PADs veterinary form.pdf'
          if (type === 'annualVet') a.download = 'PADs annual veterinary form.pdf'
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const confirmConsent = async () => {
    setLoading(true)
    await updateUser(
      {
        approvalStatus: 'approved',
        dataConsent: consent,
        privacyPolicyRead: privacy,
        consentDate: new Date(),
      },
      currentUser.uid,
      db,
    )
    fetchUser()
    setLoading(false)
  }

  if (currentUser) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px' }}>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            {!!currentUser.hasApplication && (
              <span>In Training Application</span>
            )}
            {!currentUser.hasApplication && (
              <span>Hey, {currentUser.firstName}</span>
            )}
          </Typography>
          {currentUser.approvalStatus === 'approved' &&
            !currentUser.hasApplication && (
              <>
                <Typography mb={3} variant="h5">
                  Welcome to Psychiatric Assistance Dogs Foundation Membership
                  Application.
                </Typography>
                <Typography mb={3} variant="body1">
                  Please download and fill out the forms below. You will need
                  these completed before you can go any further. If you have any
                  problems please email{' '}
                  <a href="mailto:info@pads.foundation">info@pads.foundation</a>
                </Typography>
                <LoadingButton
                  loading={downloading}
                  variant="outlined"
                  onClick={() => downloadFile('medical')}
                  sx={{ textTransform: 'none', marginBottom: 2 }}
                  startIcon={<DownloadIcon />}
                >
                  Download Medical Form
                </LoadingButton>
                <br />
                <LoadingButton
                  loading={downloading}
                  variant="outlined"
                  onClick={() => downloadFile('vet')}
                  sx={{ textTransform: 'none', marginBottom: 2 }}
                  startIcon={<DownloadIcon />}
                >
                  Download Veterinary Form
                </LoadingButton>
                <Typography mb={2} variant="body1">
                  Once all the forms are complete with the relevant information
                  please click the button below to get started with the main
                  application. This will take you through the application
                  process in stages.
                </Typography>
                <Typography variant="body1">
                  If your application is successful you will have unlimited
                  access to our online Training Platform.
                </Typography>
                <LoadingButton
                  loading={loading}
                  type="button"
                  variant="contained"
                  target="_blank"
                  sx={{ mt: 3, mb: 6.1 }}
                  onClick={() => navigate('/application/create')}
                >
                  Start application
                </LoadingButton>
              </>
            )}
          {(!currentUser.approvalStatus ||
            currentUser.approvalStatus !== 'approved') && (
              <>
                <Typography mb={2} variant="body1">
                  Thank you for registering with us!
                </Typography>
                <Typography mb={2} variant="body1">
                  Before we can get started with your application we just need to
                  confirm a few things.
                </Typography>
                <Typography mb={2} variant="body1">
                  One of our volunteers should already have been in touch to go
                  through the application process with you and outline what is
                  involved. As we&apos;ll be handling sensitive information we
                  need your consent to proceed.
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    sx={{ textAlign: 'justify' }}
                    control={
                      <Checkbox
                        checked={privacy}
                        onChange={(e) => {
                          setPrivacy(e.target.checked)
                        }}
                      />
                    }
                    label={label}
                  />
                  <FormControlLabel
                    sx={{ textAlign: 'justify' }}
                    control={
                      <Checkbox
                        checked={consent}
                        onChange={(e) => {
                          setConsent(e.target.checked)
                        }}
                      />
                    }
                    label="I explicitly consent to Psychiatric Assistance Dogs
                Foundation (known as PADs Foundation or PADs) processing any
                sensitive personal data relating to me, including the medical,
                veterinary, psychiatrist and sponsor details as necessary to
                process and maintain my membership of PADs Foundation"
                  />
                </FormGroup>
                <br />
                <LoadingButton
                  loading={loading}
                  type="button"
                  onClick={confirmConsent}
                  variant="contained"
                  disabled={!privacy || !consent}
                  sx={{ mb: 12 }}
                >
                  Let&apos;s go!
                </LoadingButton>
              </>
            )}
          {currentUser.approvalStatus === 'approved' &&
            !!currentUser.hasApplication && <ApplicationProgress />}


          {currentUser.role === 'member' || currentUser.role === 'admin' && (
            <>
              <Typography mb={2} variant="h4">Full Application & Renewals</Typography>
              <Typography mb={4} variant="body1">
                Use this section once you have completed the Public Access Test to finalise your annual membership.<br />You&apos;ll use the same form each year for both your initial submission and future renewals.
              </Typography>
              <LoadingButton
                loading={downloading}
                variant="outlined"
                onClick={() => downloadFile('annualVet')}
                sx={{ textTransform: 'none', marginBottom: 2 }}
                startIcon={<DownloadIcon />}
              >
                Download Annual Veterinary Form
              </LoadingButton>
              <br />
              <Typography mt={2} variant="body1">
                Renewal status:
                {!!renewal?.renewalDueDate && (
                  <strong> Due on {dayjs(renewal.renewalDueDate.seconds * 1000).format('DD/MM/YYYY')}</strong>
                )}
                {renewal?.status === 'Awaiting submission' && (
                  <strong> Awaiting submission</strong>
                )}
                {renewal?.status === 'Awaiting approval' && (
                  <strong> Awaiting approval</strong>
                )}
                {!renewal?.renewalDueDate && renewal?.status !== 'Awaiting submission' && renewal?.status !== 'Awaiting approval' && (
                  <strong> Not due</strong>
                )}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => navigate('/application/renewals')}
                sx={{ marginTop: 5, marginBottom: 8, marginRight: 2 }}
              >
                Manage Renewals
              </Button>
            </>
          )}
        </Container>
      </section >
    )
  }

  return null
}

export default Home
