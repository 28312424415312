/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const LearningAndTheory = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="learning-theory" style={styles.title}>
      1. Canine Learning & Theory
    </Text>
    <Text style={styles.text}>
      We only advocate the use of force-free, reward based methods. Harsh
      punishment, including but not limited to; physical punishment, shouting,
      yanking, shocking and/or spraying are not recommended and should not be
      implemented when training your dog. This type of punishment induces fear
      and stress in your canine companion, can damage your bond and can lead to
      severe aggression. Please see our guidance, “Choosing a Trainer” for more
      information on how to choose an appropriately qualified trainer to meet
      your needs.
    </Text>
    <Text style={styles.underlineHeading}>Basic Learning Theory</Text>
    <Text style={styles.text}>
      Dogs learn through association. The two main ways association happens are:
    </Text>
    <Text style={styles.subtitleBold} id="classical-conditioning">
      Classical Conditioning
    </Text>
    <Text style={styles.text}>
      This type of learning transpires when a dog involuntarily pairs two
      stimuli with one another. With Classical Conditioning we condition a
      dog&apos;s innate reflexes to react to subtle signals. After lots of
      repetition and time, the dog associates the signal with the event.
    </Text>
    <Text style={styles.text}>
      Like many great scientific advances, classical conditioning was stumbled
      upon by accident. Ivan P. Pavlov was conducting physiological research on
      the digestion of dogs when he noticed that the dogs&apos; physical
      reactions to food subtly changed over time. At first, the dogs would only
      salivate when the food was placed in front of them. However, later they
      salivated slightly before their food arrived. Pavlov realised that they
      were salivating at the noises that were consistently present before the
      food arrived; for example, the sound of a food trolley approaching. To
      test his theory, Pavlov set up an experiment in which he rang a bell
      shortly before presenting food to the dogs. At first, the dogs elicited no
      response to the bells. However, eventually, the dogs began to salivate at
      the sound of the bell alone.
    </Text>
    <Text style={styles.text}>
      To understand classical conditioning, it is essential to be familiar with
      the following terms.
    </Text>
    <Text style={styles.text}>
      A <Text style={styles.textBold}>neutral stimulus</Text> is a stimulus
      which at first elicits no response. Pavlov introduced the ringing of the
      bell as a neutral stimulus.
    </Text>
    <Text style={styles.text}>
      An <Text style={styles.textBold}>unconditioned stimulus </Text>
      is a stimulus that leads to an automatic response. In Pavlov&apos;s
      experiment, the food was the unconditioned stimulus.
    </Text>
    <Text style={styles.text}>
      An <Text style={styles.textBold}>unconditioned response </Text>
      is an automatic response to a stimulus. The dogs salivating for food is
      the unconditioned response in Pavlov&apos;s experiment.
    </Text>
    <Text style={styles.text}>
      A <Text style={styles.textBold}>conditioned stimulus</Text> is a stimulus
      that can eventually trigger a conditioned response. In the described
      experiment, the conditioned stimulus was the ringing of the bell, and the
      conditioned response was salivation.
    </Text>
    <Text style={styles.text}>
      It is important to note that the neutral stimulus becomes the conditioned
      stimulus.
    </Text>
    <Text style={styles.text}>
      Here a neutral stimulus is paired with an unconditioned stimulus which
      naturally produces a behaviour. Once trained the neutral stimulus becomes
      a conditioned stimulus eliciting a conditioned response.
    </Text>
    <Image
      style={styles.image}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fclassical-conditioning.png?alt=media&token=da009ff9-6104-4352-b5ca-c4890a60c90b"
    />
    <Text style={styles.subtitleBold} id="operant-conditioning">
      Operant Conditioning
    </Text>
    <Text style={styles.text}>
      This type of learning occurs when a dog learns to associate a voluntary
      behaviour with a consequence. For example, when a dog given the signal to
      sit (stimulus) and does so, the dog sits (voluntary behaviour), he gets a
      treat (consequence). We like to refer to these as the ABC’s of dog
      training
    </Text>
    <Image
      style={styles.imageWithBorder}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2FFlowchart%20-%20Frame%202.jpg?alt=media"
    />
    <Text style={styles.text}>
      Please note that “consequence” does not refer to something undesirable to
      the dog in this instance. In reward-based training, the consequence will
      often be desirable.
    </Text>
    <Text style={styles.text}>
      Operant behaviours are those under our conscious control. Some may occur
      spontaneously and others purposely, but it is the consequences of these
      actions that then influence whether or not they occur again in the future.
      Our actions on the environment and the consequences of that action make up
      an important part of the learning process.
    </Text>
    <Text style={styles.text}>
      Operant conditioning is a type of learning in which organisms associate
      their own actions with consequences; behaviour is strengthened if followed
      by a reinforcer or diminished if followed by a punisher. Operant behaviour
      could have three consequences:
    </Text>
    <ListItem>Good (reinforcing) - Behaviour likely to occur again</ListItem>
    <ListItem>
      Bad (aversive) - Can create avoidance, aggression or disengagement
    </ListItem>
    <ListItem>
      Neutral (extinction) - Over time occurrence of behaviour is reduced or is
      extinguished
    </ListItem>
    <Text style={styles.text}>
      There are 4 methods of delivering consequences also known as the 4
      quadrants.
    </Text>
    <Text style={styles.textSuccess}>Positive Reinforcement</Text>
    <Text style={styles.textSuccess}>Negative Punishment</Text>
    <Text style={styles.textError}>Positive Punishment</Text>
    <Text style={styles.textError}>Negative Reinforcement</Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(1).jpg?alt=media"
    />
    <Text style={{ ...styles.textBold, color: 'red' }}>
      *Please note that we do NOT use any POSITIVE PUNISHMENT in the training of
      any animal! This is here for information purposes ONLY
    </Text>
    <Text style={styles.subtitleBold} id="reinforcement">
      What is Reinforcement?
    </Text>
    <Text style={styles.text}>
      In Operant Conditioning, “reinforcement” refers to anything that increases
      the likelihood that a response will occur again. Psychologist B.F. Skinner
      coined the term in 1937. There are two main types of reinforcement:
      Primary Reinforcement and Secondary Reinforcement.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Primary Reinforcement</Text>is anything an
      animal needs to survive;
    </Text>
    <ListItem>Food</ListItem>
    <ListItem>Water</ListItem>
    <ListItem>Shelter</ListItem>
    <Text style={styles.text}>
      We will already be providing suitable shelter with access to water. But we
      can use their food as a way to train and reinforce the dog’s behaviours.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Secondary Reinforcement</Text>is anything
      the animal has learnt to enjoy.
    </Text>
    <ListItem>Affection / Attention</ListItem>
    <ListItem>Balls / Toys</ListItem>
    <ListItem>Play</ListItem>
    <Text style={styles.text}>
      Dogs will all vary in their preferences, some dogs will enjoy playing
      fetch so much that you can utilise this on occasion as part of their
      reinforcement instead of always relying on food. It is recommended that
      the reinforcement you offer the dog during its training session is varied
      in type of reinforcement as well as quantity of reinforcement. Don’t get
      stuck always offering the dog the same small reward. This will soon become
      predictable for your dog and they may choose to do something else that is
      more interesting instead of what you are asking of them. Ensure that you
      sometimes offer a handful of treats or lots of affection or a thow of
      their favourite toy! This will help to keep the sessions stimulating and
      maintain the dog’s engagement with the session. During your walks and
      training sessions you will likely carry a bag with a variety of primary
      and secondary reinforcers. A pouch that is easily accessed for primary
      reinforcement is necessary as well as a bag containing their water, toys
      and of course poop bags! Your trainer will help you learn how to reward
      your dog’s correct behaviour.
    </Text>
    <Text style={styles.subtitleBold} id="counter-conditioning">
      Counter Conditioning:
    </Text>
    <Text style={styles.italics}>
      *Please ensure you work alongside your trainer should your dog be in need
      of any counter conditioning
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(38).jpg?alt=media"
    />
    <Text style={styles.text}>
      Counter conditioning is an effective therapy to alleviate anxieties or
      phobias by pairing the trigger stimulus with a response that is contrary
      to fear. Dogs with various emotional behaviour responses (aggression,
      fear, separation anxiety) are often overly and abnormally sensitive to a
      stimulus (person, dog, noise). This over sensitivity can cause reactivity
      where the dog reacts inappropriately with excessive fear or aggression
      toward the stimulus in question.
    </Text>
    <Text style={styles.text}>
      When confronted with aversive situations, dogs typically cope by engaging
      in activities that serve to reduce the danger:
    </Text>
    <ListItem>Escape from the aversive stimulus (flight)</ListItem>
    <ListItem>Displace the course of aversive stimulation (fight)</ListItem>
    <ListItem>Increase vigilance or searching behaviour (flirt)</ListItem>
    <ListItem>Wait for the situation to change (freeze)</ListItem>
    <ListItem>
      Tolerate or accept the situation (this happens with flooding where the
      animal shuts down)
    </ListItem>
    <Text style={styles.text}>
      In counter conditioning the trigger stimulus is paired with “good stuff”
      that the individual dog finds high value. This is building a new
      association. For example, the sight of the harness equals cheese – an
      unconditioned stimulus for the dog. With consistency this can change the
      dog&apos;s conditioned emotional response to the harness from negative to
      positive.
    </Text>
    <Text style={styles.text}>
      To be successful in dog training counter conditioning needs to be paired
      with desensitisation. For example, if a dog is worried about other dogs,
      taking them to a dog park and sitting in the middle of the park with them
      and some cheese is not the right conditions for new CER&apos;s to be
      built. Exposure at this level would be termed “flooding” and rarely
      produces a good result. Instead, a dog worried about other dogs could be
      taken to the side of a field where 1 or 2 dogs tend to be calmly walking
      on the other side. Pairing the sight of these dogs with cheese at a
      distance would ensure the dog is kept UNDER THRESHOLD so associative
      learning can occur. Gradually the distance between the anxious dog and the
      trigger stimuli can be lessened. This is desensitisation. Desensitisation
      is a way of lessening the sensitivity until the response is small in
      proportion to the initial response, or until there is no response at all.
      The key word with Desensitisation is GRADUALLY. Gradually and slowly
      exposing the dog to low and controlled levels of the stimulus that he is
      aggressive to or fearful of so that he can learn to control his reactivity
      and behaviour when faced with the stimulus
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2FFlowchart%20-%20Frame%203.jpg?alt=media"
    />
    <Text style={styles.text}>
      With counter conditioning there is a risk of building unhelpful
      associations further; success of counter conditioning is all down to
      timing. The trigger stimulus must be presented FIRST, and within two
      seconds the unconditioned stimulus must be presented.
    </Text>
    <Text style={styles.text}>
      For example: HARNESS, then CHEESE. If the unconditioned stimulus is
      presented before the trigger stimulus, the risk is building a negative
      association with cheese. For example: CHEESE, then HARNESS.
    </Text>
    <Text style={styles.text}>
      Cheese will predict the harness, a feared stimuli. The dog can therefore
      become worried about cheese!Furthermore, conditioning may not take place
      at all if the two stimuli, cheese and harness, are presented at exactly
      the same time. The ideal time between presentation of the conditioned and
      unconditioned stimuli is about 1⁄2 a second. Many people try to change a
      dog&apos;s emotional response by asking the dog to “do” something in the
      presence of the scary stimulus. This is operant conditioning, not
      classical conditioning. A dog&apos;s emotional response cannot be changed
      by training operant behaviours.
    </Text>
    <Text style={styles.subtitleBold} id="adverse-methods">
      Adverse Training methods
    </Text>
    <Text style={styles.text}>
      The training method of dominating your dog and being the pack leader are
      now largely looked upon as old fashioned training. Types of this training
      are the alpha roll which involves:
    </Text>
    <ListItem>Staring at the dog</ListItem>
    <ListItem>Scruffing the dog&apos;s neck</ListItem>
    <ListItem>Lifting them up by their lead</ListItem>
    <ListItem>Pulling on the lead and collar</ListItem>
    <ListItem>Or any other methods of force.</ListItem>
    <Text style={styles.text}>
      Punishment methods are a concern for the welfare of the animal. They are
      used to cause harm, shock and fear by using punishment and bullying for
      unwanted behaviour. The dog will remember being punished and will not
      repeat the behaviour. They are not doing this because they want to behave
      well but they remember the punishment given to them and do not want it
      repeated.
    </Text>
    <Text style={styles.text}>
      Such training aids include shock and prong collars, sprays, water bottles,
      rattles or bottles with stones in and air horns. All these methods are
      used to surprise and frighten the dog so that they focus on something else
      rather than the behaviour.
    </Text>
    <Text style={styles.text}>
      The dog will build up a negative association with the punishment and will
      not trust the handler. Because they are anxious and most probably fearful,
      they may redirect their frustration into aggression and make the original
      problem worse than before. They will show signs of stress such as lower
      body posture, lip licking and yawning, some dogs may develop aggressive
      behaviours towards their handler.
    </Text>
    <Text style={styles.subtitleBold} id="force-free">
      Force free training methods
    </Text>
    <Text style={styles.text}>
      A dog that is trained and handled using positive reinforcement methods
      learns quicker and is much more likely to repeat the behaviour that earned
      them a treat, a toy, praise or petting from their handler. They feel
      motivated and relaxed. There have been a number of studies demonstrating
      the efficacy of positive reinforcement compared to aversive types of
      training.
    </Text>
    <Text style={styles.text}>
      The other type of training is negative punishment. We may remove our
      attention from the dog for example if he is barking or jumping up.
      However, just using negative punishment alone can lead to stress,
      frustration and even an increase in unwanted behaviours. We must also use
      positive reinforcement, looking for opportunities to praise and reward the
      dog when they are quiet, not jumping up - using the examples we mentioned
      earlier. Speak to your trainer about training a “marker” or “ bridge” to
      aid in your training.
    </Text>
    <Text style={styles.text}>
      A “bridge/marker” is a tool that will aid your dog’s learning. You may
      have been using a bridge already and not realised it! A bridge or marker
      is used to mark the exact moment your dog has performed the correct
      behaviour and is used to build and shape more complex behaviours. A bridge
      can be audial, visual or tactile. The most common and useful one to train
      is an audial bridge. Most people will say “Good” or “Yes” to their dog
      wherever the dog does the correct behaviour. For example you ask your dog
      for “sit” behaviour, as soon as your dog sits you will say “Good” or
      “Yes”. This will communicate to your dog that it has done well and will
      receive some type of reinforcement. A bridge needs to be trained. Please
      make sure your choice of bridge is short, for example one word and not
      “Good boy/girl”. The longer the bridge the harder it is to pinpoint the
      exact behaviour you want. A bridge communicates to the dog that it has
      done well and also terminates the behaviour for them to return to you to
      get their reward.{' '}
    </Text>
    <Text style={styles.text}>
      Marker training (often called Clicker training) is a great method of
      training as the dog must think about what they have just done in order to
      hear the bridge/click and get a treat. The dog must at first be
      conditioned to the bridge/clicker by understanding that whenever they hear
      it they will be rewarded before going onto shaping a sequence of
      behaviours. Many assistance dog charities implement marker training and
      shaping.
    </Text>
    <Text style={styles.text}>
      Positive reinforcement training helps to develop and keep a bond of trust
      between the dog and the handler. It is a form of mental stimulation as the
      dog will need to think about how to keep the treats coming rather than
      having something taken away.
    </Text>
    <Text style={styles.subtitleBold} id="glossary">
      Chapter 1: Glossary
    </Text>
    <Text style={styles.text}>
      Conditioning: The process of learning through association
    </Text>
    <Text style={styles.text}>
      Stimulus: A thing or occurrence that induces a specific functional
      reaction in an organ or tissue
    </Text>
    <Text style={styles.text}>
      Unconditioned Stimulus: A stimulus that unconditionally, naturally, and
      automatically triggers a response.
    </Text>
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

LearningAndTheory.propTypes = {
  styles: PropTypes.object,
}

export default LearningAndTheory
