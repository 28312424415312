import React, { useState } from 'react'
import {
  Container,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Grid,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const ManageForms = () => {
  const [percent1, setPercent1] = useState(0)
  const [percent2, setPercent2] = useState(0)
  const [percent3, setPercent3] = useState(0)
  const storage = getStorage()
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const uploadToFirebase = (file, fileName, folder) => {
    const storageRef = ref(storage, `/${folder}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )

        switch (fileName) {
          case 'PADS vet form.pdf':
            setPercent1(currentPercent)
            break
          case 'PADS psychiatrist form.pdf':
            setPercent2(currentPercent)
            break
          default:
            setPercent3(currentPercent)
            break
        }
      },
      (err) => console.error(err),
      () => {
        setAlert({
          open: true,
          message: 'File uploaded successfully',
          type: 'success',
        })
      },
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px', mb: '378px' }}>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.type}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Typography mb={6} variant="h4">
          Manage Forms
        </Typography>
        <Stack spacing={2} width="350px">
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload Initial Vet Form
              <VisuallyHiddenInput
                type="file"
                onChange={(e) =>
                  uploadToFirebase(
                    e.target.files[0],
                    'PADS vet form.pdf',
                    'forms',
                  )
                }
              />
            </Button>
            <Grid width="100px">
              {percent1 > 0 && percent1 < 100 && (
                <CircularProgress
                  variant="determinate"
                  value={percent1}
                  sx={{ ml: 3 }}
                  size="30px"
                />
              )}
            </Grid>
          </Grid>
          <Grid sx={{ display: 'flex' }}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload Medical Form
              <VisuallyHiddenInput
                type="file"
                onChange={(e) =>
                  uploadToFirebase(
                    e.target.files[0],
                    'PADS psychiatrist form.pdf',
                    'forms',
                  )
                }
              />
            </Button>
            <Grid width="100px">
              {percent2 > 0 && percent2 < 100 && (
                <CircularProgress
                  variant="determinate"
                  value={percent2}
                  sx={{ ml: 3 }}
                  size="30px"
                />
              )}
            </Grid>
          </Grid>
          <Grid sx={{ display: 'flex' }}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload Annual Vet Form
              <VisuallyHiddenInput
                type="file"
                onChange={(e) =>
                  uploadToFirebase(
                    e.target.files[0],
                    'PADS Annual Registration Vet Form.pdf',
                    'forms',
                  )
                }
              />
            </Button>
            <Grid width="100px">
              {percent3 > 0 && percent3 < 100 && (
                <CircularProgress
                  variant="determinate"
                  value={percent3}
                  sx={{ ml: 3 }}
                  size="30px"
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </section>
  )
}

export default ManageForms
