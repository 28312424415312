/* eslint-disable react/forbid-component-props */
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { useAuth } from '../contexts/AuthContext'
import EditUser from './EditUser'

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { currentUser, logOut } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
        }}
      >
        <AppBar position="static" sx={{ height: '78px', paddingTop: '5px' }}>
          <Toolbar>
            <img
              width="50"
              height="50"
              src="https://pads.foundation/wp-content/uploads/2020/08/cropped-padsLogo150.png"
              className="custom-logo"
              alt="Psychiatric Assistance Dogs Foundation"
              style={{ marginRight: '15px' }}
            />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Psychiatric Assistance Dogs Foundation
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'profile-button',
              }}
            >
              <MenuItem onClick={() => navigate('/application')}>
                Dashboard
              </MenuItem>
              {['admin', 'super-admin'].includes(currentUser.role) && (
                <MenuItem onClick={() => navigate('/admin/dashboard')}>
                  Admin Dashboard
                </MenuItem>
              )}
              {['member', 'admin', 'super-admin'].includes(
                currentUser.role,
              ) && (
                <MenuItem onClick={() => navigate('/members')}>
                  Members Area
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setShowEditModal(true)
                  handleClose()
                }}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>

      <Box id="header" sx={{ display: { xs: 'none', md: 'block' } }}>
        <Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <EditUser
              callback={(success) => {
                setShowEditModal(false)
              }}
            />
          </DialogContent>
        </Dialog>
        <div className="container-fluid p-0">
          <div className="header-wrap">
            <div id="topbar">
              <div className="topbar_section">
                <div className="container" style={{ maxWidth: '100%' }}>
                  <Box className="row">
                    <div className="col-12">
                      <div className="row topbar-content">
                        <Box
                          className="col-10 topbar-email d-flex
                        justify-content-end pr-0 pt-2 pb-2"
                        >
                          <span>
                            <i className="" />
                            Registered Charity Number: 1173512{' '}
                          </span>
                        </Box>
                        <div
                          className="col-2 topbar-email pr-0 pt-2 pb-2
                        d-flex justify-content-end pr-3"
                        >
                          <Button
                            sx={{ padding: 0, textTransform: 'none' }}
                            id="profile-button"
                            aria-controls={open ? 'profile-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                          >
                            Logged in: {currentUser.firstName}{' '}
                            {currentUser.lastName} <ArrowDropDownIcon />
                          </Button>
                          <Menu
                            sx={{
                              display: { xs: 'none' },
                              padding: 0,
                              textTransform: 'none',
                            }}
                            id="profile-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'profile-button',
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setShowEditModal(true)
                                handleClose()
                              }}
                            >
                              Profile
                            </MenuItem>
                            <MenuItem onClick={logOut}>Logout</MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>

            <Box className="container nav_wrap">
              <div className="main-header-box">
                <div className="row bg-media">
                  <div className="col-3">
                    <div className="logo">
                      <a
                        href="https://pads.foundation/"
                        className="custom-logo-link"
                      >
                        <img
                          width="150"
                          height="150"
                          src="https://pads.foundation/wp-content/uploads/2020/08/cropped-padsLogo150.png"
                          className="custom-logo"
                          alt="Psychiatric Assistance Dogs Foundation"
                        />
                      </a>{' '}
                      <div className="logo-text" />
                    </div>
                  </div>
                  <div className="col-6 header-nav">
                    <div className="menubar m-0 mt-md-0">
                      <div className="container right_menu">
                        <div className="innermenubox ">
                          <div id="mySidenav" className="nav sidenav">
                            <nav
                              id="site-navigation"
                              className="main-navigation"
                            >
                              <div className="menu clearfix">
                                <ul
                                  id="menu-main-menu"
                                  className="clearfix mobile_nav
                                sf-js-enabled sf-arrows"
                                  style={{ touchAction: 'pan-y' }}
                                >
                                  <li id="menu-item-780" className="link-hover">
                                    <span
                                      className="link-hover"
                                      onClick={() => navigate('/application')}
                                      aria-current="page"
                                      style={{
                                        color: `${
                                          location.pathname === '/application'
                                            ? '#ff5721'
                                            : '#000'
                                        }`,
                                      }}
                                    >
                                      Dashboard
                                    </span>
                                  </li>
                                  {['member', 'admin', 'super-admin'].includes(
                                    currentUser.role,
                                  ) && (
                                    <li
                                      id="menu-item-780"
                                      className="link-hover"
                                    >
                                      <span
                                        className="link-hover"
                                        onClick={() => navigate('/members')}
                                        aria-current="page"
                                        style={{
                                          color: `${
                                            location.pathname.includes(
                                              'members',
                                            )
                                              ? '#ff5721'
                                              : '#000'
                                          }`,
                                        }}
                                      >
                                        Members Area
                                      </span>
                                    </li>
                                  )}
                                  {['admin', 'super-admin'].includes(
                                    currentUser.role,
                                  ) && (
                                    <li
                                      id="menu-item-780"
                                      className="link-hover"
                                    >
                                      <span
                                        className="link-hover"
                                        onClick={() =>
                                          navigate('/admin/dashboard')
                                        }
                                        aria-current="page"
                                        style={{
                                          color: `${
                                            location.pathname.includes('admin')
                                              ? '#ff5721'
                                              : '#000'
                                          }`,
                                        }}
                                      >
                                        Admin
                                      </span>
                                    </li>
                                  )}
                                  <li id="menu-item-785">
                                    <a href="https://pads.foundation/contact/">
                                      Contact
                                    </a>
                                  </li>
                                </ul>
                              </div>{' '}
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>{' '}
                  </div>
                  <Box
                    className="col-3"
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  >
                    <div className="row">
                      <div className="col-lg-4 col-6" />
                      <div className="col-lg-8 col-6">
                        <a
                          href="https://pads.foundation/donate-now/"
                          className="header_btn"
                        >
                          Donate Now{' '}
                        </a>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </Box>
          </div>{' '}
        </div>
      </Box>
    </>
  )
}

export default Header
